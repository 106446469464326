import React from 'react';
import { useTranslation } from 'react-i18next';

import './SplashPage.css';
import logo from '../../img/mvision-title.png';

const SplashPage = (props: { loadingText?: string | React.ReactNode }) => {

    const { t } = useTranslation();

    const loadingText = props.loadingText !== undefined ? props.loadingText : t('splash.loading');

    return (
        <div className="splash">
            <div className="logobox">
                <img src={logo} alt="MVision logo" />
            </div>
            <div className="loading-text-row">
                <span className="loading-text">{loadingText}</span>
            </div>
        </div>
    );
}

export default SplashPage;
