import { sleep } from "./util/sleep";
import { ResourceKey } from "i18next";

export class Route {
    public slug: string;
    public index: number;
    public langKey: ResourceKey;

    constructor(slug: string, index: number, title: ResourceKey) {
        this.slug = slug;
        this.index = index;
        this.langKey = title;
    }
}

const routes = {
    homePage: new Route('/', 0, 'sideNav.home'),
    contourCustomizationPage: new Route('/contour/customization', 1, 'sideNav.contour.modelCustomization'),
    contourAllStructuresPage: new Route('/contour/all-structures', 2,  'sideNav.contour.allStructures'),
    contourModelSelectionPage: new Route('/contour/selection', 3, 'sideNav.contour.modelSelection'),
    doseCustomizationPage: new Route('/dose/customization', 5, 'sideNav.dose.modelCustomization'),
    // doseModelSelectionPage: new Route('/dose/selection', 6, 'sideNav.dose.modelSelection'),
    daemonConfigPage: new Route('/daemon', 10, 'sideNav.daemonConfig'),
    textEditorPage: new Route('/text-editor', 11, 'sideNav.fileEditor'),
    licensePage: new Route('/license', 12, 'sideNav.license'),
    aboutPage: new Route('/about', 13, 'sideNav.about'),
};

export default routes;

/** Perform a redirect to a (valid) route slug */
const doRedirect = async (redirectToSlug: string) => {
    // for security reasons ONLY allow redirect to valid route slugs
    const matchingRoute = Object.values(routes).find(r => r.slug === redirectToSlug);
    if (matchingRoute) {
        const newUrl = `${window.location.origin}${matchingRoute.slug}`;
        console.log(`Navigating to ${newUrl}`);
        window.location.replace(newUrl);

        // prevent the rest of the code from running while we're redirecting,
        // this way we don't start useless API requests that'll just get cancelled
        // (and would throw harmless errors into console)
        await sleep(1000);
    }
}

export { doRedirect };
