import React from 'react';
import { useSelector } from 'react-redux';


import './../customization/customization-page.css';
import GlobalRoiCustomizationForm from './GlobalRoiCustomizationForm';
import { TableComposable, Th, Thead, Tr } from '@patternfly/react-table';
import { columnIds, columnLangKeys, columnTooltips } from '../customization/contouring/RoiTable';
import { EntityId } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { contouringSelectors } from '../../store/contouring/contouringSlice';

const GlobalRoiCustomizationCollectionForm = () => {

    const { t } = useTranslation();
    const globalRoiIds = useSelector(contouringSelectors.selectGlobalRoiIds);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    return (
        <div>
            <TableComposable variant='compact' isStickyHeader className="mv-sticky-fix">
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>{t(columnLangKeys[columnIds.name])}</Th>
                        <Th>{t(columnLangKeys[columnIds.color])}</Th>
                        <Th info={{ tooltip: columnTooltips[columnIds.operation](deploymentInfo?.operationsManualUrl) }}>{t(columnLangKeys[columnIds.operation])}</Th>
                        <Th>{t(columnLangKeys[columnIds.interpretedType])}</Th>
                        <Th>{t(columnLangKeys[columnIds.fmaCodeValue])}</Th>
                        <Th>{t(columnLangKeys[columnIds.fmaCodeMeaning])}</Th>
                        <Th>{t(columnLangKeys[columnIds.fmaCodeSchemeDesignator])}</Th>
                        <Th info={{ tooltip: columnTooltips[columnIds.includedModels]() }}>{t(columnLangKeys[columnIds.includedModels])}</Th>
                        <Th info={{ tooltip: columnTooltips[columnIds.excludedModels]() }}>{t(columnLangKeys[columnIds.excludedModels])}</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                {globalRoiIds.map((rId: EntityId, rowIndex: number) => {
                    return (
                        <GlobalRoiCustomizationForm
                            globalRoiId={rId as string}
                            globalRoiIdList={globalRoiIds as string[]}
                            key={rId}
                            rowIndex={rowIndex}
                        />
                    );
                })}
            </TableComposable>
        </div>
    );
}

export default GlobalRoiCustomizationCollectionForm;
