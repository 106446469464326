import React, { useCallback } from 'react';

import './input-label.css';

interface InputLabelProps {
    label?: string,
    className?: string,
    /** If defined, ref is passed to the first child object detected. TODO: implement a way to set which child to
     * 'ref' if that becomes necessary (see the refIndex const below). */
    useRef?: boolean,
}

const LABEL_CLASSNAME = 'mv-input-label-caption';
const INPUT_CLASSNAME = 'mv-input-label-element';
const BUTTON_CLASSNAME = 'mv-input-label-button';

const InputLabel = (props: React.PropsWithChildren<InputLabelProps>) => {
    const { label, className, children, useRef } = props;

    const ref = React.createRef<HTMLInputElement>();

    const handleOnClick = useCallback(() => {
        if (useRef) {
            ref && ref.current && ref.current.focus();
        }
    }, [ref, useRef]);

    if (!label) {
        return <>{children}</>;
    }

    const styles = `${className || ''} ${LABEL_CLASSNAME} ${useRef ? BUTTON_CLASSNAME : ''}`;

    const refIndex = 0;

    return (
        <span className="mv-input-label-container">
            <div className={styles} onClick={handleOnClick}>{label}</div>
            {React.Children.map(children, (child, index) => index === refIndex && React.isValidElement<{className: string | undefined, ref: React.RefObject<HTMLInputElement> | undefined}>(child) ?
                React.cloneElement(child,
                    {
                        className: `${child.props.className} ${INPUT_CLASSNAME}`,
                        ref: useRef ? ref : undefined,
                    })
                : child)}
        </span>
    );
}

export default InputLabel;
