import React from 'react';
import { PageSection, PageSectionVariants, EmptyState, Title, EmptyStateBody } from '@patternfly/react-core';
import { useSelector } from 'react-redux';

import './../customization/customization-page.css';
import CustomizationToolbar from '../customization/contouring/CustomizationToolbar';
import GlobalRoiCustomizationCollectionForm from './GlobalRoiCustomizationCollectionForm';
import FullRoiDetailsModal from '../customization/contouring/FullRoiDetailsModal';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { CustomizationObjectType } from '../../store/global-types/customization-types';

export const GLOBAL_TAB_ID = 'global';

const CustomizationPage = () => {

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const { t } = useTranslation();

    const customizationFetchError = useSelector((state: StoreState) => state.contouring.customizationFetchError);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    return (
        <>

            {appConfig && appConfig.showErrorsInUi && customizationFetchError !== null ? (
                <PageSection variant={PageSectionVariants.light}>
                    <EmptyState>
                        <Title headingLevel="h4" size="lg">An error occurred</Title>
                        <EmptyStateBody>
                            An error occurred when trying to load customization page: {customizationFetchError}
                        </EmptyStateBody>
                    </EmptyState>
                </PageSection>
            ) : (
                <>
                    <PageSection variant={PageSectionVariants.light}>
                        <CustomizationToolbar
                            currentView={CustomizationObjectType.GlobalRoi}
                            customizationOutputId={GLOBAL_TAB_ID}
                        />

                        <FullRoiDetailsModal />

                        <PageSection variant={PageSectionVariants.light}>
                            {currentTarget === undefined ? (
                                <EmptyState>
                                    <Title headingLevel="h4" size="lg">{t('selectConfigurationTarget.title')}</Title>
                                    <EmptyStateBody>{t('selectConfigurationTarget.message')}</EmptyStateBody>
                                </EmptyState>
                            ) : (
                                <GlobalRoiCustomizationCollectionForm />
                            )}
                        </PageSection>

                    </PageSection>
                </>
            )
            }

        </>
    );
}

export default CustomizationPage;
