import React, { useCallback, useState } from 'react';
import { Nav, Page, PageSidebar, NavItem, NavList, Modal, Button, Backdrop, Bullseye, NavGroup } from '@patternfly/react-core';
import { ExternalLinkAltIcon, ExclamationCircleIcon } from '@patternfly/react-icons'

import Masthead from './Masthead';
import routes, { Route } from './routes';
import { NavLink } from 'react-router-dom';

import './Layout.css';
import { useSelector } from 'react-redux';
import SplashPage from './pages/splash/SplashPage';
import NewVersionAlert from './components/new-version-alert';
import UserSettings from './pages/user-settings/UserSettings';
import { useTranslation } from 'react-i18next';
import HelpScreen from './pages/help/HelpScreen';
import { StoreState } from './store/store';
import { appConfigSelectors } from './store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from './store/configurationTarget/configurationTargetSlice';

const Layout = (props: React.PropsWithChildren<{}>) => {

    const { t } = useTranslation();

    const [activeItem, setActiveItem] = useState(-1);
    const [isUserSettingsVisible, setUserSettingsVisible] = useState(false);
    const [isHelpDialogVisible, setHelpDialogVisible] = useState(false);
    const isLayoutInsideFrame = useSelector(appConfigSelectors.selectIsLayoutInsideFrame);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    const onNavSelect = useCallback((result: any) => {
        setActiveItem(result.itemId);
    }, []);

    const handleOpenUserSettings = useCallback(() => {
        setUserSettingsVisible(true);
    }, []);

    const handleCloseUserSettings = useCallback(() => {
        setUserSettingsVisible(false);
    }, []);

    const handleOpenHelpDialog = useCallback(() => {
        setHelpDialogVisible(true);
    }, []);

    const handleCloseHelpDialog = useCallback(() => {
        setHelpDialogVisible(false);
    }, []);

    const renderNavItem = useCallback((route: Route, activeItem: Number) => (
        <NavItem key={route.index} isActive={activeItem === route.index}>
            <NavLink exact to={route.slug} activeClassName="pf-m-current">
                {t(route.langKey)}
            </NavLink>
        </NavItem>
    ), [t]);

    const isAppInitialized = useSelector((state: StoreState) => state.appStatus.isAppInitialized);
    const isTargetListFetched = useSelector((state: StoreState) => state.configurationTarget.isConfigurationTargetListFetched);
    const isFetchingData = useSelector(configurationTargetSelectors.selectIsFetchingConfigurationTargetData);
    const userInitError = useSelector((state: StoreState) => !!state.configurationTarget.configurationTargetListError || !!state.user.userSettingsError ?
        `${state.configurationTarget.configurationTargetListError || ''} ${state.user.userSettingsError || ''}` : null);
    const errorComponent = !!userInitError ? (<span className="splash-error"><span className="icon"><ExclamationCircleIcon /></span> {userInitError}</span>) : undefined;

    if (isLayoutInsideFrame === undefined || !isAppInitialized || !isTargetListFetched || deploymentInfo === undefined || appConfig === undefined || !!userInitError) {
        return <SplashPage loadingText={errorComponent} />;
    }

    // TODO: hide or minimize masthead when the app is inside a (cockpit) frame
    // isLayoutInsideFrame

    const pageNav = (
        <Nav onSelect={onNavSelect} aria-label="Nav">
            <NavGroup title={t('sideNav.contour')}>
                {renderNavItem(routes.contourCustomizationPage, activeItem)}
                {renderNavItem(routes.contourAllStructuresPage, activeItem)}
                {renderNavItem(routes.contourModelSelectionPage, activeItem)}
            </NavGroup>

            <NavGroup title={t('sideNav.dose')}>
                {renderNavItem(routes.doseCustomizationPage, activeItem)}
            </NavGroup>

            <NavGroup title={t('sideNav.general')}>
                {appConfig.allowTextEditor && renderNavItem(routes.textEditorPage, activeItem)}
                {deploymentInfo && !deploymentInfo.disableDaemonConfigPage && renderNavItem(routes.daemonConfigPage, activeItem)}
                {renderNavItem(routes.licensePage, activeItem)}
                {renderNavItem(routes.aboutPage, activeItem)}
                {appConfig.allowDashboard && deploymentInfo && deploymentInfo.dashboardUrl !== undefined && (
                    <NavItem key="dashboard-link" isActive={false}>
                        <a href={deploymentInfo.dashboardUrl} rel="noopener noreferrer" target="_blank">Dashboard <span className="external-link-icon"><ExternalLinkAltIcon /></span></a>
                    </NavItem>
                )}
            </NavGroup>
        </Nav>
    );

    const sidebar = (<PageSidebar nav={pageNav} />);
    const masthead = (<Masthead handleOpenUserSettings={handleOpenUserSettings} isUserSettingsOpen={isUserSettingsVisible} handleOpenHelpDialog={handleOpenHelpDialog} />);

    return (
        <Page header={masthead} sidebar={sidebar} className="main-page" isManagedSidebar>
            <NewVersionAlert />
            {isFetchingData && (
                <Backdrop>
                    <Bullseye>
                        <SplashPage loadingText={errorComponent ? errorComponent : t('splash.loadingConfigurationTarget')} />
                    </Bullseye>
                </Backdrop>
            )}

            {props.children}

            <Modal
                isOpen={isUserSettingsVisible}
                title={t('dialog.settings')}
                variant={'large'}
                onClose={handleCloseUserSettings}
                actions={[<Button key="close-user-settings" onClick={handleCloseUserSettings}>{t('common.close')}</Button>]}
            >
                <UserSettings />
            </Modal>

            <Modal
                isOpen={isHelpDialogVisible}
                title={t('dialog.help')}
                variant={'medium'}
                onClose={handleCloseHelpDialog}
                actions={[<Button key="close-help-dialog" onClick={handleCloseHelpDialog}>{t('common.close')}</Button>]}
            >
                <HelpScreen />
            </Modal>
        </Page>
    );
}

export default Layout;
