const loadExternalScript = (scriptName: string, scriptUrl: string, onLoadCallback?: () => {}) => {
    const existingScript = document.getElementById(scriptName);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.id = scriptName;
        document.body.appendChild(script);
        script.onload = () => {
            if (onLoadCallback) { onLoadCallback(); }
        };
    }
};

export default loadExternalScript;