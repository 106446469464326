import { z } from 'zod';
import { getNumber } from '../../util/math';

/** Target helper schema for the main daemon config schema */
const DaemonConfigTargetSchema = z.object({
    ae_title: z.string(),
    ip: z.string(),
    port: z.number(),
}).transform((input) => ({
    aeTitle: input.ae_title,
    ip: input.ip,
    port: input.port.toString(),
}));

/** Schema for daemon configuration objects. */
const DaemonConfigSchema = z.object({
    ae_title: z.string(),
    session_id: z.string(),
    listen_ip: z.string(),
    listen_port: z.number(),
    forward_images: z.boolean(),
    audit_log: z.boolean(),
    keep_mapping_h: z.number(),
    max_tries: z.number(),
    backend_poll_interval_s: z.number(),
    backend_config_interval_s: z.number(),
    targets: z.array(DaemonConfigTargetSchema),
    retain_attributes: z.array(z.string()),
    pass_through_attributes: z.array(z.string()),
})
    /** transform schema to javascript case instead of underscores */
    .transform((input) => ({
        aeTitle: input.ae_title,
        sessionId: input.session_id,
        listenIp: input.listen_ip,
        listenPort: input.listen_port.toString(),
        forwardImages: input.forward_images,
        auditLog: input.audit_log,
        keepMappingInH: input.keep_mapping_h.toString(),
        maxTries: input.max_tries.toString(),
        backendPollIntervalInS: input.backend_poll_interval_s.toString(),
        backendConfigIntervalInS: input.backend_config_interval_s.toString(),
        targets: input.targets,
        retainAttributes: input.retain_attributes,
        passThroughAttributes: input.pass_through_attributes,
    }));

/** Schema for collection of daemon configuration objects. */
const DaemonConfigCollectionSchema = z.array(DaemonConfigSchema);


/** A single daemon configuration retrieved from backend. A user or client app can have multiple daemon configs. */
export type DaemonConfig = z.infer<typeof DaemonConfigSchema>;

// Retained DICOM attributes that are used for model selection
export enum RetainStatus {
    NotRetained = 'NotRetained',
    Retained = 'Retained',
    RetainedInSome = 'RetainedInSome',
}

export interface RetainedDicomAttributes {
    [attribute: string]: RetainStatus;
}

/** Converts input JSON object to an array of Daemon Configuration objects, or throws an error if JSON is in invalid format. */
export const convertJsonObjectToDaemonConfigs = (jsonObject: any): DaemonConfig[] => {
    const parsedDaemonConfigs = DaemonConfigCollectionSchema.safeParse(jsonObject);

    if (parsedDaemonConfigs.success) {
        return parsedDaemonConfigs.data;
    } else {
        const errorMsg = `Received daemon configuration JSON has invalid properties: ${parsedDaemonConfigs.error}`;
        console.log(errorMsg);
        console.log(jsonObject);
        throw new Error(errorMsg);
    }
}

export const convertJsonObjectToDaemonConfig = (jsonObject: any): DaemonConfig => {    
    const parsedDaemonConfig = DaemonConfigSchema.safeParse(jsonObject);

    if (parsedDaemonConfig.success) {
        return parsedDaemonConfig.data;
    } else {
        const errorMsg = `Received daemon configuration JSON has invalid properties: ${parsedDaemonConfig.error}`;
        console.log(errorMsg);
        console.log(jsonObject);
        throw new Error(errorMsg);
    }
} 

/** Converts input daemon config view model into a matching JSON object. */
export const convertDaemonConfigToJson = (daemonConfig: DaemonConfig): any => {
    if (!isDaemonConfigValid(daemonConfig)) {
        throw new Error('Daemon configuration is not valid -- cannot convert to JSON or save');
    }

    const json = {
        "ae_title": daemonConfig.aeTitle,
        "session_id": daemonConfig.sessionId,
        "listen_ip": daemonConfig.listenIp,
        "listen_port": getNumber(daemonConfig.listenPort),
        "targets": daemonConfig.targets.map(t => ({
            "ae_title": t.aeTitle.trim(),
            "ip": t.ip.trim(),
            "port": getNumber(t.port),
        })),
        "forward_images": daemonConfig.forwardImages,
        "audit_log": daemonConfig.auditLog,
        "keep_mapping_h": getNumber(daemonConfig.keepMappingInH),
        "retain_attributes": daemonConfig.retainAttributes.filter(ra => !!ra && ra.trim() !== '').map(ra => ra.trim()),
        "pass_through_attributes": daemonConfig.passThroughAttributes.filter(pta => !!pta && pta.trim() !== '').map(pta => pta.trim()),
        "max_tries": getNumber(daemonConfig.maxTries),
        "backend_poll_interval_s": getNumber(daemonConfig.backendPollIntervalInS),
        "backend_config_interval_s": getNumber(daemonConfig.backendConfigIntervalInS),
    };

    return json;
}

export const isDaemonConfigValid = (daemonConfig: DaemonConfig): boolean => {
    // number values must convert to proper numbers
    if (isNaN(getNumber(daemonConfig.backendPollIntervalInS))) { return false; }
    if (isNaN(getNumber(daemonConfig.backendConfigIntervalInS))) { return false; }
    if (isNaN(getNumber(daemonConfig.keepMappingInH))) { return false; }
    if (isNaN(getNumber(daemonConfig.listenPort))) { return false; }
    if (isNaN(getNumber(daemonConfig.maxTries))) { return false; }
    if (daemonConfig.targets.some(t => isNaN(getNumber(t.port)))) { return false; }

    return true;
}

export const getDefaultTarget = () => {
    return {
        aeTitle: 'VMSFSD',
        ip: '0.0.0.0',
        port: '104',
    };
}
