import { isArray, isObject, isString, get, trim, isEmpty } from "lodash-es";
import hash from 'object-hash';

import { assertExpectedProps } from "../../util/expected-prop";
import { naturalSort } from "../../util/sort";
import { CodingScheme, GlobalContouringRoi, ContouringCustomizationEntities, ContouringCustomizationEntitiesForExport, ContouringCustomizationOutput, ContouringCustomizationOutputEntities, PhysicalProperty, PHYSICAL_PROPERTY_REL_ELEC_DENSITY, PHYSICAL_PROPERTY_REL_MASS_DENSITY, ContouringRoi, SUPPORTED_PHYSICAL_PROPERTIES } from "./contouring-types";
import { Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DicomRule, DicomAttributeRule, isAeTitleRule, isDicomRule, METADATA_FILENAME_ATTRIBUTE, ModelType } from "../global-types/customization-types";
import { generateNewId, createNewModelCustomizationBase, UI_ID_ATTRIBUTE } from "../global-types/customization-helpers";
import { _convertOutputMetadataViewModelToJson, convertCustomizationJsonObjectToViewModels, convertCustomizationViewModelsToJson, convertSingleCustomizationJsonEntryToViewModelObjects, CustomizationBaseExtractResult, extractOutputMetadata, ModelTriggersByCustomizationNames } from "../global-types/view-model-conversion-helpers";

export const createNewContouringCustomizationOutput = (metadataIds: string[], roiIds: string[], customizationBaseId: string, filename?: string, id?: string, isModified?: boolean): ContouringCustomizationOutput => ({
    id: id || generateNewId(),
    metadata: metadataIds,
    rois: roiIds,
    modelCustomizationBaseId: customizationBaseId,
    filename: filename || '',
    isModified: isModified || false,
});

export const createNewContouringRoi = (operation: string, name: string, color: [number, number, number],
    interpretedType: string, physicalPropertyAttribute: PhysicalProperty, physicalPropertyValue: string,
    codingScheme: CodingScheme, isIncluded: boolean, canBeDeleted: boolean, modelCustomizationOutputId?: string,
    id?: string, isModified?: boolean, scrollToView?: boolean): ContouringRoi => ({
        id: id || generateNewId(),
        operation: operation,
        name: name,
        color: color,
        interpretedType: interpretedType,
        physicalPropertyAttribute: physicalPropertyAttribute,
        physicalPropertyValue: physicalPropertyValue,
        ...codingScheme,
        isIncluded: isIncluded,
        isBuiltInRoi: canBeDeleted === false,
        isModified: isModified || false,
        customizationOutputId: modelCustomizationOutputId,
        scrollToView: scrollToView || false,
    });

const createNewCodingScheme = (codeValue: string, codeMeaning: string, codingSchemeDesignator: string, codingSchemeVersion: string,
    mappingResource: string, contextGroupVersion: string, contextIdentifier: string, contextUid: string, mappingResourceUid: string,
    mappingResourceName: string): CodingScheme => ({
        hasCodingScheme: true,
        codingSchemeCodeValue: codeValue,
        codingSchemeCodeMeaning: codeMeaning,
        codingSchemeDesignator: codingSchemeDesignator,
        codingSchemeVersion: codingSchemeVersion,
        codingSchemeMappingResource: mappingResource,
        codingSchemeContextGroupVersion: contextGroupVersion,
        codingSchemeContextIdentifier: contextIdentifier,
        codingSchemeContextUid: contextUid,
        codingSchemeMappingResourceUid: mappingResourceUid,
        codingSchemeMappingResourceName: mappingResourceName,
    });

/**
 * Generates a new GlobalRoiCustomization object.
 * @param coveredRois array of matching RoiCustomizations that this global roi covers
 * @param excludedRois array of RoiCustomizations that have the same operation as this global roi but do not otherwise match with this one
 * @param noCoveredRoisOp If false or undefined, set the input covered rois' globalRoiId property to match this new object. 
 * If true, don't set it. If you call this function from redux store you probably want to set this to true and do any prop changing to the
 * original roi objects manually.
 */
export const createGlobalRoiCustomization = (coveredRois: ContouringRoi[], excludedRois: ContouringRoi[], noCoveredRoisOp?: boolean): GlobalContouringRoi => {
    const globalRoi: GlobalContouringRoi = {
        id: generateNewId(),
        operation: coveredRois[0].operation,
        name: coveredRois[0].name,
        isIncluded: coveredRois[0].isIncluded,
        color: coveredRois[0].color,
        interpretedType: coveredRois[0].interpretedType,
        physicalPropertyAttribute: coveredRois[0].physicalPropertyAttribute,
        physicalPropertyValue: coveredRois[0].physicalPropertyValue,
        ...getCodingScheme(coveredRois[0]),
        isModified: false,
        isBuiltInRoi: coveredRois.some(r => r.isBuiltInRoi === true),
        coveredRois: coveredRois.map(r => r.id),
        excludedRois: excludedRois.map(r => r.id)
    };
    if (!noCoveredRoisOp) { coveredRois.forEach(cr => cr.globalRoiId = globalRoi.id); }
    return globalRoi;
};

/** Extracts a (new) coding scheme (FmaId) object out of a ROI or a Global ROI customization object. */
export const getCodingScheme = (roi: ContouringRoi | GlobalContouringRoi): CodingScheme => {
    return {
        hasCodingScheme: roi.hasCodingScheme,
        codingSchemeCodeValue: roi.codingSchemeCodeValue,
        codingSchemeCodeMeaning: roi.codingSchemeCodeMeaning,
        codingSchemeDesignator: roi.codingSchemeDesignator,
        codingSchemeVersion: roi.codingSchemeVersion,
        codingSchemeMappingResource: roi.codingSchemeMappingResource,
        codingSchemeContextGroupVersion: roi.codingSchemeContextGroupVersion,
        codingSchemeContextIdentifier: roi.codingSchemeContextIdentifier,
        codingSchemeContextUid: roi.codingSchemeContextUid,
        codingSchemeMappingResourceUid: roi.codingSchemeMappingResourceUid,
        codingSchemeMappingResourceName: roi.codingSchemeMappingResourceName,
    }
}

/**
 * Cleans a JSON coding scheme (FmaId) object by removing keys with empty or whitespace-only string values.
 * @param codingScheme The coding scheme (FmaId) JSON object to clean.
 * @returns The cleaned FmaId JSON object or null if all fields are empty or if the input is null.
 */
export const cleanCodingScheme = (codingScheme: any | null): any | null => {
    if (codingScheme === null) {
        return null;
    }

    const cleanedCodingScheme: any = {};
    for (const key in codingScheme) {
        if (codingScheme.hasOwnProperty(key)) {
            const value = codingScheme[key];
            if (typeof value === 'string' && trim(value) !== '') {
                cleanedCodingScheme[key] = value;
            }
        }
    }

    return isEmpty(cleanedCodingScheme) ? null : cleanedCodingScheme;
};

/** Returns a default (empty) coding scheme (FmaId) object. */
export const getDefaultCodingScheme = (): CodingScheme => ({ hasCodingScheme: false });

/** Convert contouring customization JSON from config API into flattened internal view model representation.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertContouringJsonObjectToViewModels = (jsonObject: any, noSorting: boolean = false): ContouringCustomizationEntities => {

    const results = convertCustomizationJsonObjectToViewModels(jsonObject, ModelType.Contouring, noSorting);

    // return converted results with following notes:
    // - ignore any fields related to other algorithms (although if there were anything like that present then 
    //   the earlier conversion function should have already thrown)
    return {
        models: results.models || [],
        customizationBases: results.customizationBases || [],
        contouringOutputs: results.contouringOutputs || [],
        outputMetadata: results.outputMetadata || [],
        contouringRois: results.contouringRois || [],
        aeTitleRules: results.aeTitleRules || [],
        dicomRules: results.dicomRules || [],
        dicomAttributeRules: results.dicomAttributeRules || [],
        contouringGlobalRois: results.contouringGlobalRois || [],
    }
}

/** Convert model customization DTO/JSON containing just ONE model customization from segmentation backend 
 * API into flattened internal view model representation. Returns matching view model objects but does NOT
 * generate matching global ROI objects.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertSingleModelJsonToContouringOutput = (jsonObject: any, noSorting: boolean = false): ContouringCustomizationOutputEntities => {
    if (!jsonObject || !isObject(jsonObject)) {
        throw new Error('Invalid JSON object provided for contouring customizations conversion.');
    }

    const modelCollection: Model[] = [];
    const customizationBaseCollection: CustomizationBase[] = [];
    const outputCollection: ContouringCustomizationOutput[] = [];
    const metadataCollection: OutputMetadataItem[] = [];
    const roiCustomizationCollection: ContouringRoi[] = [];
    const aeTitleRuleCollection: AeTitleRule[] = [];
    const dicomRuleCollection: DicomRule[] = [];
    const dicomAttributeRuleCollection: DicomAttributeRule[] = [];

    const assertMessage = 'Unexpected contouring model/structure customization format.';

    convertSingleCustomizationJsonEntryToViewModelObjects(
        jsonObject,

        modelCollection,
        customizationBaseCollection,
        metadataCollection,
        aeTitleRuleCollection,
        dicomRuleCollection,
        dicomAttributeRuleCollection,

        outputCollection,
        roiCustomizationCollection,

        [], [], [],

        assertMessage,
        ModelType.Contouring,
        noSorting
    );

    return {
        customizationOutputs: outputCollection,
        modelCustomizationsMetadata: metadataCollection,
        roiCustomizations: roiCustomizationCollection,
    }
}



/**
 * Helper function for converting a customization base and all its outputs and other child objects into contouring customization entities.
 * You shouldn't need to call this function manually.
 */
export const _convertContouringCustomizationOutputJsonToViewModel = (
    modelCustomizationBaseResult: CustomizationBaseExtractResult,
    modelId: string,
    contouringOutputCollection: ContouringCustomizationOutput[],
    outputMetadataCollection: OutputMetadataItem[],
    contouringRoiCollection: ContouringRoi[],
    modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames,
    assertMessage: string,
    noSorting: boolean,
): CustomizationBase => {
    const { customizationId, customizationName, customizationDescription, dtoOutputs } = modelCustomizationBaseResult;

    // each 'file' will become its own customization output object
    const outputs: ContouringCustomizationOutput[] = dtoOutputs.map((customizationOutputEntry: any) => {

        // assert that output entries look correct
        assertExpectedProps(customizationOutputEntry, [
            { propName: 'metadata', propType: 'array' },
            { propName: 'rois', propType: 'array' },
        ], assertMessage);

        // extract metadata
        const metadata = extractOutputMetadata(customizationOutputEntry, assertMessage);

        // extract rois & fma ids
        const rois: ContouringRoi[] = customizationOutputEntry['rois'].map((roiEntry: any) => {
            // assert that metadata entries look correct
            assertExpectedProps(roiEntry, [
                { propName: 'operation', propType: 'string' },
                { propName: 'name', propType: 'string' },
                { propName: 'color', propType: 'rgbArray' },
                { propName: 'interpreted_type', propType: 'string' },
                { propName: 'can_be_deleted', propType: 'boolean' },
                { propName: 'included', propType: 'boolean' },
            ], assertMessage);

            const codingSchemeEntry = roiEntry['fma_id'];
            const parsedCodingScheme = codingSchemeEntry ? createNewCodingScheme(
                codingSchemeEntry['code_value'],
                codingSchemeEntry['code_meaning'],
                codingSchemeEntry['coding_scheme_designator'],
                codingSchemeEntry['coding_scheme_version'],
                codingSchemeEntry['mapping_resource'],
                codingSchemeEntry['context_group_version'],
                codingSchemeEntry['context_identifier'],
                codingSchemeEntry['context_uid'],
                codingSchemeEntry['mapping_resource_uid'],
                codingSchemeEntry['mapping_resource_name']
            ) : { hasCodingScheme: false };

            let physicalPropertyAttribute = PhysicalProperty.NotSet;
            let physicalPropertyValue = '';
            const physicalPropertiesEntry = roiEntry['physical_properties'];
            if (physicalPropertiesEntry && isArray(physicalPropertiesEntry)) {
                const matchingEntry = physicalPropertiesEntry.find(e => isString(e['physical_property']) && SUPPORTED_PHYSICAL_PROPERTIES.includes(e['physical_property']));
                if (matchingEntry) {
                    physicalPropertyAttribute = matchingEntry['physical_property'] === PHYSICAL_PROPERTY_REL_ELEC_DENSITY ? PhysicalProperty.RelElecDensity : PhysicalProperty.RelMassDensity;
                    physicalPropertyValue = matchingEntry['value'].toString() || '';
                }
            }

            return createNewContouringRoi(roiEntry['operation'],
                roiEntry['name'],
                roiEntry['color'],
                roiEntry['interpreted_type'],
                physicalPropertyAttribute,
                physicalPropertyValue,
                parsedCodingScheme,
                roiEntry['included'],
                roiEntry['can_be_deleted']);
        });

        // collect ids. ensure roi id order within models
        const metadataIds = metadata.map(m => m.id);
        const roiIds = noSorting ? rois.map(r => r.id) : naturalSort(rois, 'name').map(r => r.id);
        const filename = metadata.find(m => m.attribute === METADATA_FILENAME_ATTRIBUTE)?.value;

        const output = createNewContouringCustomizationOutput(metadataIds, roiIds, customizationId, filename);

        // update parent ids to child objects
        metadata.forEach(m => m.modelCustomizationOutputId = output.id);
        rois.forEach(r => r.customizationOutputId = output.id);

        // add newly created objects into collections that will eventually be put into redux store
        outputMetadataCollection.push(...metadata);
        contouringRoiCollection.push(...rois);

        return output;
    });

    const outputIds = outputs.map(o => o.id);
    const matchingTriggers = modelTriggersByCustomizationNames[customizationName] || [];
    const aeTitleRules = matchingTriggers.filter(isAeTitleRule);
    const dicomRules = matchingTriggers.filter(isDicomRule);
    const aeTitleRuleIds = aeTitleRules.map(t => t.id);
    const dicomRuleIds = dicomRules.map(t => t.id);
    const customization = createNewModelCustomizationBase(customizationName, customizationDescription, outputIds, aeTitleRuleIds, dicomRuleIds, modelId, customizationId);

    // update matching customization IDs now that we have a customization object
    matchingTriggers.forEach(t => t.modelCustomizationBaseId = customization.id);

    // add newly created objects into collections that will eventually be put into redux store
    contouringOutputCollection.push(...outputs);

    return customization;
}


/** Convert contouring customization view models to JSON presentation..
 * @param contouringEntities The contouring customization view model entities to convert to JSON.
 * @param noUiId Internal IDs for the view models needed for UI functionality will not be included in the export if true, and will be included
 * if set to false. Defaults to false. Including internal IDs is needed when sending data to backend to be able to cross-reference received
 * errors to their matching UI view models, but these IDs should be omitted when this JSON will be user-facing (e.g. when exported directly
 * as a downloaded file) or when running certain unit tests (e.g. round-trip tests will break if extra UI ID fields are present).
 * @returns An array of model JSON objects, ready to be sent to backend.
 */
export const convertContouringViewModelsToJson = (contouringEntities: Partial<ContouringCustomizationEntitiesForExport>, noUiId: boolean = false): any => {

    const jsonModels = convertCustomizationViewModelsToJson(contouringEntities, ModelType.Contouring, noUiId);

    return jsonModels;
}


/**
 * Convert internal view model representation of contouring output objects and related children
 * into a model customization JSON hierarchy. You shouldn't need to call this manually.
 * @param jsonCustomization Result JSON will be embedded into this JSON.
 * @param output Contouring output view model to convert to JSON.
 * @param outputMetadata Collection of output metadata related to this customization set.
 * @param contouringRois Collection of ROI customizations related to this customization set.
 * @param errorMessage Error message to throw on failure.
 * @param noUiId Marks if internal UI IDs should be included in converted JSON.
 */
export const _convertContouringCustomizationOutputToJson = (
    jsonCustomization: any,
    output: ContouringCustomizationOutput,
    outputMetadata: OutputMetadataItem[],
    contouringRois: ContouringRoi[],
    errorMessage: string,
    noUiId: boolean = false): any => {

    if (output === undefined) { throw new Error(errorMessage); }
    const jsonOutput: any = {
        'metadata': [],
        'rois': [],
    };
    if (!noUiId) { jsonOutput[UI_ID_ATTRIBUTE] = output.id }

    for (const metadataId of output.metadata) {
        const metadata = outputMetadata.find(m => m.id === metadataId);
        if (metadata === undefined) { throw new Error(errorMessage); }
        _convertOutputMetadataViewModelToJson(jsonOutput, metadata, noUiId);
    }

    for (const roiId of output.rois) {
        const roi = contouringRois.find(r => r.id === roiId);
        if (roi === undefined) { throw new Error(errorMessage); }

        const codingSchemeOutput = roi.hasCodingScheme ? {
            'code_value': roi.codingSchemeCodeValue?.trim(),
            'code_meaning': roi.codingSchemeCodeMeaning?.trim(),
            'coding_scheme_designator': roi.codingSchemeDesignator?.trim(),
            'coding_scheme_version': roi.codingSchemeVersion?.trim(),
            'mapping_resource': roi.codingSchemeMappingResource?.trim(),
            'context_group_version': roi.codingSchemeContextGroupVersion?.trim(),
            'context_identifier': roi.codingSchemeContextIdentifier?.trim(),
            'context_uid': roi.codingSchemeContextUid?.trim(),
            'mapping_resource_uid': roi.codingSchemeMappingResourceUid?.trim(),
            'mapping_resource_name': roi.codingSchemeMappingResourceName?.trim(),
        } : null;

        const cleanedCodingSchemeOutput = cleanCodingScheme(codingSchemeOutput);

        const physicalProperties = [];
        if (roi.physicalPropertyAttribute !== PhysicalProperty.NotSet && roi.physicalPropertyValue) {
            const physicalProperty = {
                'physical_property': roi.physicalPropertyAttribute === PhysicalProperty.RelElecDensity ? PHYSICAL_PROPERTY_REL_ELEC_DENSITY : PHYSICAL_PROPERTY_REL_MASS_DENSITY,
                'value': parseFloat(roi.physicalPropertyValue.trim()),
            };
            if (!isNaN(physicalProperty.value)) {
                physicalProperties.push(physicalProperty);
            }
        }

        const jsonRoi: any = {
            'included': roi.isIncluded,
            'name': roi.name.trim(),
            'color': roi.color,
            'interpreted_type': roi.interpretedType,
            'can_be_deleted': !roi.isBuiltInRoi,
            'operation': roi.operation.trim(),
            'fma_id': cleanedCodingSchemeOutput,
            'physical_properties': physicalProperties,
        };
        if (!noUiId) { jsonRoi[UI_ID_ATTRIBUTE] = roi.id }
        jsonOutput['rois'].push(jsonRoi);
    }

    jsonCustomization['files'].push(jsonOutput);
}

export const generateRoiCustomizationHash = (roi: ContouringRoi | GlobalContouringRoi): string => {
    // separate fma id value props from other keys
    const codingSchemeValueKeys = ['codingSchemeCodeValue', 'codingSchemeCodeMeaning', 'codingSchemeDesignator', 'codingSchemeVersion', 'codingSchemeMappingResource',
        'codingSchemeContextGroupVersion', 'codingSchemeContextIdentifier', 'codingSchemeContextUid', 'codingSchemeMappingResourceUid', 'codingSchemeMappingResourceName'];

    // only these keys are used for checking similarity
    const supportedKeys = ['operation', 'name', 'color', 'interpretedType', 'hasCodingScheme', 'physicalPropertyAttribute', 'physicalPropertyValue', ...codingSchemeValueKeys];

    // ignore all keys that return 'true' in the excludeKeys function below, i.e. exclude
    // 1. anything that's not in supportedKeys,
    // 2. codingSchemeValueKeys if the actual matching values are undefined (this improves optional property support between roi and globalroi objects)
    return hash(roi, { excludeKeys: (key) => !supportedKeys.includes(key) || (codingSchemeValueKeys.includes(key) && get(roi, key, undefined)) === undefined });
}

/**
 * Returns true if given roi customization object essentially matches given global roi customization object, such that
 * the global roi customization covers this roi customization.
 * @param globalRoiUpdates An optional update object for global roi customization object can be given. Any values in this object
 * are used instead of the matching props in globalRoi. This is useful for testing matching during data updates where final updates
 * haven't been applied to the globalRoi object yet.
 */
export const roiCustomizationMatchesGlobalRoi = (roi: ContouringRoi, globalRoi: GlobalContouringRoi, globalRoiUpdates?: Partial<GlobalContouringRoi>): boolean => {
    if (globalRoiUpdates === undefined) {
        return generateRoiCustomizationHash(roi) === generateRoiCustomizationHash(globalRoi);
    } else {
        return generateRoiCustomizationHash(roi) === generateRoiCustomizationHash(Object.assign({}, globalRoi, globalRoiUpdates));
    }
}

export const duplicateRoiCustomization = (roi: ContouringRoi, outputId?: string): ContouringRoi => {
    return {
        id: generateNewId(),
        operation: roi.operation,
        name: roi.name,
        color: roi.color,
        interpretedType: roi.interpretedType,
        physicalPropertyAttribute: roi.physicalPropertyAttribute,
        physicalPropertyValue: roi.physicalPropertyValue,
        ...getCodingScheme(roi),
        isIncluded: roi.isIncluded,
        isBuiltInRoi: roi.isBuiltInRoi,
        isModified: true,
        customizationOutputId: outputId,
        scrollToView: false,
        globalRoiId: roi.globalRoiId,
    };
}

/**
 * Extracts the common coding scheme attributes from the given ROI customization.
 * Note: Only the common coding scheme attributes are returned.
 * @param roiCustomization The ROI customization object.
 * @returns The common coding scheme attributes, with some fields potentially undefined.
 */
export const extractSharedCodingSchemeAttributes = (roiCustomization: ContouringRoi | GlobalContouringRoi): Partial<CodingScheme> => {
    let extracted: Partial<CodingScheme> = {
        hasCodingScheme: roiCustomization.hasCodingScheme,
        codingSchemeDesignator: roiCustomization.codingSchemeDesignator,
        codingSchemeVersion: roiCustomization.codingSchemeVersion,
        codingSchemeMappingResource: roiCustomization.codingSchemeMappingResource,
        codingSchemeContextGroupVersion: roiCustomization.codingSchemeContextGroupVersion,
        codingSchemeContextIdentifier: roiCustomization.codingSchemeContextIdentifier,
        codingSchemeContextUid: roiCustomization.codingSchemeContextUid,
        codingSchemeMappingResourceUid: roiCustomization.codingSchemeMappingResourceUid,
        codingSchemeMappingResourceName: roiCustomization.codingSchemeMappingResourceName,
    };

    return extracted;
};



export const readModelCustomizationJsonFile = (json: any): Promise<ContouringCustomizationEntities | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            try {
                const jsonObject = JSON.parse(reader.result as string);

                const modelCustomizationImport = convertContouringJsonObjectToViewModels(jsonObject);
                resolve(modelCustomizationImport);
            } catch (e) {
                reject(e);
            }
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsText(json);
    });
};
