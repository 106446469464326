import { FormGroup, Level, LevelItem, Button, TextInput, Dropdown, KebabToggle, DropdownItem, DropdownPosition, Modal, ModalVariant } from '@patternfly/react-core';
import React, { useCallback, useState } from 'react';
import { ArrowRightIcon, ExclamationCircleIcon } from '@patternfly/react-icons';

import { useDispatch, useSelector } from 'react-redux';
import ModelSelectionRuleWrapper from './SelectionRuleWrapper';
import DebouncedTextInput from '../../components/debounced-text-input';
import { ModelSelectionUndoState } from './SelectionRulePage';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../store/store';
import { aeTitleRuleActionUpdated, aeTitleRuleRemoved, contouringSelectors } from '../../store/contouring/contouringSlice';
import { CustomizationObjectType, ModelSelectionScope } from '../../store/global-types/customization-types';


interface ModelSelectionAeTitleRuleProps {
    aeTitleRuleId: string,
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    disableUndo?: boolean,
}

const generateAeTitle = (action: string): string => `MV${action.toUpperCase()}`;

const ModelSelectionAeTitleRule = (props: ModelSelectionAeTitleRuleProps) => {
    const { aeTitleRuleId, runningNumber, setUndoState, disableUndo } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isActionListOpen, setActionListOpenState] = useState(false);
    const [isRemoveAeTitleRuleModalOpen, setRemoveAeTitleRuleModalOpenState] = useState(false);

    const aeTitleRule = useSelector((state: StoreState) => contouringSelectors.selectAeTitleRuleById(state, aeTitleRuleId));
    const validationError = useSelector((state: StoreState) => contouringSelectors.selectCustomizationValidationError(state, aeTitleRuleId));

    const dispatchAeTitleActionChange = useCallback((action: string) => {
        dispatch(aeTitleRuleActionUpdated({ id: aeTitleRuleId, action }));
    }, [dispatch, aeTitleRuleId]);

    const handleRemoveAeTitleRuleButtonClick = useCallback(() => {
        setActionListOpenState(false);
        setRemoveAeTitleRuleModalOpenState(true);
    }, []);

    const handleRemoveAeTitleRuleConfirmation = useCallback(() => {
        setRemoveAeTitleRuleModalOpenState(false);
        dispatch(aeTitleRuleRemoved(aeTitleRuleId));
    }, [dispatch, aeTitleRuleId]);

    const handleUndo = useCallback(() => {
        setUndoState({ isModalOpen: true, scope: ModelSelectionScope.AeTitleRule, id: aeTitleRuleId });
        setActionListOpenState(false)
    }, [aeTitleRuleId, setUndoState]);

    // don't render anything if we don't have a valid object
    if (aeTitleRule === undefined) {
        return null;
    }

    const fieldId = aeTitleRule.id;
    const formValidationState = aeTitleRule.isValid ? 'default' : 'error';

    const dropdownItems = [<DropdownItem
        key="removeAeTitleRule" isDisabled={!aeTitleRule.isEditable}
        title={aeTitleRule.isEditable ? undefined : t('selectionPage.defaultNamesCannotBeRemoved')}
        onClick={handleRemoveAeTitleRuleButtonClick}>{t('selectionPage.removeAeTitleRule')}</DropdownItem>];
    if (!disableUndo) {
        dropdownItems.unshift(<DropdownItem key="undoAeTitleRuleChanges" isDisabled={!aeTitleRule.isModified || aeTitleRule.isNew}
            onClick={handleUndo}>{t('selectionPage.undoAeTitleRuleChanges')}</DropdownItem>);
    }

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.AeTitleRule;

    // render either an ae title field or a dicom attribute field
    return (
        <ModelSelectionRuleWrapper
            key={aeTitleRule.id}
            title={t('selectionPage.aeTitleRuleNumber', { number: runningNumber })}
            isModified={aeTitleRule.isModified}
            className="ae-title-rule"
            hasValidationError={hasValidationError}
            validationError={validationError}>

            <div className="rule-item">
                <Level className="ae-title-row">
                    <LevelItem className="ae-title-action-column">
                        <FormGroup
                            label={t('selectionPage.actionName')}
                            fieldId={`action-name-field-for-${fieldId}`}
                            title={`The action name that can be used to access this model. AE Title is generated from this field.${aeTitleRule.isEditable ? '' : ' Default action names/AE Titles cannot be modified, but you can add new ones.'}`}
                            validated={formValidationState}
                            helperTextInvalid={aeTitleRule.validationMessage}
                            helperTextInvalidIcon={<ExclamationCircleIcon />}
                        >
                            <DebouncedTextInput
                                fieldId={`action-name-field-for-${fieldId}`}
                                onChange={dispatchAeTitleActionChange}
                                className="ae-title"
                                defaultValue={aeTitleRule.action}
                                isDisabled={!aeTitleRule.isEditable}
                                validated={formValidationState}
                            />
                        </FormGroup>
                    </LevelItem>
                    <LevelItem className="ae-title-arrow">
                        <ArrowRightIcon />
                    </LevelItem>
                    <LevelItem className="ae-title-aetitle-column">
                        <FormGroup
                            label={t('selectionPage.generatedAeTitle')}
                            fieldId={`ae-title-field-for-${fieldId}`}
                            title="AE Title is generated from the action name. AE Titles cannot be changed directly -- adjust the action name instead."
                        >
                            <TextInput
                                className="ae-title"
                                type="text"
                                id={`ae-title-field-for-${fieldId}`}
                                value={generateAeTitle(aeTitleRule.action)}
                                isDisabled
                            />
                        </FormGroup>
                    </LevelItem>
                    <LevelItem>
                        <Dropdown
                            toggle={<KebabToggle onToggle={() => setActionListOpenState(!isActionListOpen)} />}
                            isPlain
                            position={DropdownPosition.right}
                            dropdownItems={dropdownItems}
                            isOpen={isActionListOpen}
                        />
                    </LevelItem>
                </Level>
            </div>
            <Modal
                variant={ModalVariant.small}
                title={t('selectionPage.confirmRemoveAeTitleRule')}
                isOpen={isRemoveAeTitleRuleModalOpen}
                onClose={() => setRemoveAeTitleRuleModalOpenState(false)}
                actions={[
                    <Button key="confirmRemoval" variant="danger" onClick={handleRemoveAeTitleRuleConfirmation}>{t('selectionPage.removeAeTitleRule')}</Button>,
                    <Button key="cancel" variant="link" onClick={() => setRemoveAeTitleRuleModalOpenState(false)}>{t('common.cancel')}</Button>
                ]}
            >
                Do you want to remove this entire AE Title rule ("{generateAeTitle(aeTitleRule.action)}")? Note that your changes won't be saved until you click on "Save".
            </Modal>
        </ModelSelectionRuleWrapper>
    );
}

export default ModelSelectionAeTitleRule;
