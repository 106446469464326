const configApiRoot = '/config';

const apiUrls = {
    // RESTful urls
    contouringConfig: configApiRoot + '/contouring',
    doseConfig: configApiRoot + '/dose',
    daemonsConfig: configApiRoot + '/daemons',
    daemonConfig: configApiRoot + '/daemon',
    resetDaemonConfig: configApiRoot + '/reset-daemon',
    labelingConfig: configApiRoot + '/labeling',

    // other urls
    getClientListUrl: configApiRoot + '/get-user-list',
    getListActiveModelsUrl: configApiRoot + '/list-active-models',

    backendVersionUrl: '/build-info',

    getLicenseUrl: configApiRoot + '/get-licenses',
    // NOTE: saveLicenseUrl is only used in local mode
    saveLicenseUrl: configApiRoot + '/save-license',
    getAccessRightsUrl: '/access-rights',
        
    // see user-settings.tsx
    //  getAllUserSettingsUrl:  '/settings', 
    //  generateSaveSettingUrl:  (setting: string): > `${getAllUserSettingsUrl}/${setting}`, 

    // functions for creating dynamic URLs
    makeGetDefaultConfigForModelUrl: (modelName: string) => `${apiUrls.contouringConfig}/${modelName}`,
}

export default apiUrls;

/** API userId field for 'default' configuration */
export const DEFAULT_USER_ID = 'default';
