import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { DaemonConfig } from './daemon-config';
import { DaemonValidationError } from '../global-types/store-errors';
import { MVisionAppClient } from '../configurationTarget/mvision-client-list';
import { get, isEqual } from 'lodash-es';
import { BackendValidationError, isBackendValidationError } from '../../util/errors';
import { convertDaemonValidationErrorsToStoreObjects } from './daemonReducerHelpers';

// entity adapter for daemon validation errors
const daemonValidationErrorAdapter = createEntityAdapter<DaemonValidationError>();

// selectors for daemon validation errors
export const daemonValidationErrorAdapterSelectors = daemonValidationErrorAdapter.getSelectors((state: DaemonSliceState) => state.daemonValidationErrors);


export type DaemonSliceState = {
    /** Daemon configs for current user/app. Zero, one, or multiple configs. */
    daemonConfigs: DaemonConfig[] | null,

    /** Error when fetching daemon configs, if any. */
    daemonConfigsError: string | null,

    /** True if we're currently saving a daemon config, false otherwise. */
    isDaemonConfigSaveInProgress: boolean,

    /** True if we're currently resetting daemon config, false otherwise. */
    isDaemonConfigResetInProgress: boolean,

    /** True if we're currently deleting daemon config, false otherwise. */
    isDaemonConfigDeleteInProgress: boolean,

    /** Daemon config save error, if any. */
    daemonConfigSaveError: string | null,

    /** Collection of daemon validation errors. */
    daemonValidationErrors: EntityState<DaemonValidationError, string>;
};

export const initialState: DaemonSliceState = {
    daemonConfigs: null,
    daemonConfigsError: null,
    isDaemonConfigSaveInProgress: false,
    isDaemonConfigResetInProgress: false,
    isDaemonConfigDeleteInProgress: false,
    daemonConfigSaveError: null,
    daemonValidationErrors: daemonValidationErrorAdapter.getInitialState(),
};

/** Daemon configuration-related redux store slice.  */
const daemonSlice = createSlice({
    name: 'daemon',
    initialState,
    reducers: {
        /**
         * Stores multiple daemon configurations into store.
         */
        daemonConfigsSet(state, action: PayloadAction<{ daemonConfigs: DaemonConfig[] | null, error?: string }>) {
            const { daemonConfigs, error } = action.payload;

            if (daemonConfigs !== null) {
                state.daemonConfigs = daemonConfigs;
            }

            // clear any errors
            daemonValidationErrorAdapter.removeAll(state.daemonValidationErrors);

            state.daemonConfigsError = error !== undefined ? error : null;
        },

        /**
         * Stores a single daemon configuration into store.
         * @param action.daemonConfig The daemon config being placed into store.
         * @param action.updateOnly Only allows updating of existing daemon configs in store if set to true, otherwise
         * allows adding new daemon configs into store if an existing entry is not found. Defaults to false.
         * @param action.error An optional error message.
         */
        daemonConfigSet(state, action: PayloadAction<{ daemonConfig: DaemonConfig | null, updateOnly?: boolean, error?: string }>) {
            const { daemonConfig, error } = action.payload;

            // update only defaults to 'false'
            const updateOnly = action.payload.updateOnly || false;

            if (daemonConfig !== null && state.daemonConfigs) {
                // put fetched daemon config in state, replacing a matching entry if found
                const index = state.daemonConfigs.findIndex(d => d.sessionId === daemonConfig.sessionId);
                if (index >= 0) {
                    state.daemonConfigs[index] = daemonConfig;
                } else if (!updateOnly) {
                    state.daemonConfigs.push(daemonConfig);
                }
            }

            // clear any errors
            daemonValidationErrorAdapter.removeAll(state.daemonValidationErrors);

            state.daemonConfigsError = error !== undefined ? error : null;
        },

        /**
         * Removes given daemon config from redux store. Does NOT remove or reset the actual
         * configuration or interact with it in any way.
         * @param action daemonSessionId of the daemon config to remove.
         */
        daemonConfigRemove(state, action: PayloadAction<string>) {
            const daemonSessionId = action.payload;

            if (state.daemonConfigs) {
                // remove matching daemon client ID if found
                const index = state.daemonConfigs.findIndex(d => d.sessionId === daemonSessionId);
                if (index >= 0) {
                    state.daemonConfigs.splice(index, 1);
                }
            }
        },

        /**
         * Signals that a daemon config save operation is starting. Payload is used in sagas.
         */
        daemonConfigSaved(state, action: PayloadAction<{ appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig }>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Signals that a daemon config reset operation is starting. Payload is used in sagas.
         */
        daemonConfigReset(state, action: PayloadAction<{ appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig }>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Signals that a daemon config delete operation is starting. Payload is used in sagas.
         */
        daemonConfigDeleted(state, action: PayloadAction<{ appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig }>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Marks daemon configuration save operation as having started.
         * @param action.appClient The configuration target where the daemon config is saved.
         * @param action.daemonConfig The daemon config that is being saved.
         */
        saveDaemonConfigStarted(state, action: PayloadAction<{ appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig }>) {
            // TODO: consider using input arguments to specify which daemon config is being saved -- currently they are unused and you can only
            // save one daemon config at a time (which is probably fine for almost any use case)
            state.isDaemonConfigSaveInProgress = true;
        },

        /**
         * Finalizes a daemon configuration save operation, whether it was successful or not.
         * @param action.appClient The configuration target where the daemon config was saved to.
         * @param action.daemonConfig The daemon config that was saved.
         * @param action.saveWasSuccessful True if save succeeded, false otherwise.
         * @param action.errorMessage Optional error message.
         * @param action.error Optional backend validation error object.
         */
        saveDaemonConfigFinished(
            state,
            action: PayloadAction<{
                appClient: MVisionAppClient | undefined,
                daemonConfig: DaemonConfig,
                saveWasSuccessful: boolean,
                errorMessage?: string,
                error?: BackendValidationError
            }>) {
            const { appClient, daemonConfig, saveWasSuccessful, errorMessage, error } = action.payload;

            state.isDaemonConfigSaveInProgress = false;
            state.daemonConfigSaveError = null;

            // clear any errors
            daemonValidationErrorAdapter.removeAll(state.daemonValidationErrors);

            if (!saveWasSuccessful) {
                console.error(error);
                state.daemonConfigSaveError = errorMessage ? errorMessage : 'Saving daemon configuration failed.';

                // collect save errors
                try {
                    if (error) {
                        const daemonValidationErrors = convertDaemonValidationErrorsToStoreObjects(daemonConfig.sessionId, error);
                        if (daemonValidationErrors.length > 0) {
                            // apply new validation errors and override whatever error message we received from sagas
                            daemonValidationErrorAdapter.addMany(state.daemonValidationErrors, daemonValidationErrors);
                            state.daemonConfigSaveError = 'Could not save daemon configuration because there are invalid entries. Please fix any validation errors and try again.';
                        }
                    }
                }
                catch (ex) {
                    const message: string | undefined = get(ex, 'message', undefined);
                    state.daemonConfigSaveError = `An error occurred when trying to parse validation error message${message ? `: ${message}` : '.'}`
                }
            }
        },

        /** Marks a daemon config reset operation as having started. Payload is used in sagas. */
        resetDaemonConfigStarted(state, action: PayloadAction<{ appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig }>) {
            state.isDaemonConfigResetInProgress = true;
        },

        /** 
         * Marks a daemon config reset operation as having finished, whether successful or not.
         * @param action.appClient The configuration target where the daemon config was reset in.
         * @param action.daemonConfig The daemon config that was reset.
         * @param action.saveWasSuccessful True if reset succeeded, false otherwise.
         * @param action.errorMessage Optional error message.
         * @param action.error Optional backend validation error object.
         * 
         * TODO: params are not currently being used.
         * */
        resetDaemonConfigFinished(
            state,
            action: PayloadAction<{
                appClient: MVisionAppClient | undefined,
                daemonConfig: DaemonConfig,
                saveWasSuccessful: boolean,
                errorMessage?: string,
                error?: BackendValidationError
            }>) {
            // clear any errors
            daemonValidationErrorAdapter.removeAll(state.daemonValidationErrors);

            state.isDaemonConfigResetInProgress = false;
        },

        /** Marks a daemon config delete operation as having started. Payload is used in sagas. */
        deleteDaemonConfigStarted(state, action: PayloadAction<{ appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig }>) {
            state.isDaemonConfigDeleteInProgress = true;
        },

        /** 
         * Marks a daemon config delete operation as having finished, whether successful or not.
         * @param action.appClient The configuration target where the daemon config was deleted in.
         * @param action.daemonConfig The daemon config that was deleted.
         * @param action.saveWasSuccessful True if delete succeeded, false otherwise.
         * @param action.errorMessage Optional error message.
         * @param action.error Optional backend validation error object.
         * 
         * TODO: params are not currently being used.
         * */
        deleteDaemonConfigFinished(
            state,
            action: PayloadAction<{
                appClient: MVisionAppClient | undefined,
                daemonConfig: DaemonConfig,
                saveWasSuccessful: boolean,
                errorMessage?: string,
                error?: BackendValidationError
            }>) {
            // clear any errors
            daemonValidationErrorAdapter.removeAll(state.daemonValidationErrors);

            state.isDaemonConfigDeleteInProgress = false;
        },

        /**
         * Signals that daemon configs should be fetched from backend.
         * @param action The configuration target from which the daemon configs are fetched.
         */
        daemonConfigsFetched(state, action: PayloadAction<MVisionAppClient | undefined>) {
            // this is an empty action and is only used for signalling in sagas
        },
    },

    selectors: {
        selectDaemonValidationError: (state, id: string) => daemonValidationErrorAdapterSelectors.selectById(state, id),
        selectDaemonValidationErrorEntities: (state) => daemonValidationErrorAdapterSelectors.selectEntities(state),
    }

});

export const {
    daemonConfigsSet,
    daemonConfigSet,
    daemonConfigRemove,
    daemonConfigSaved,
    daemonConfigReset,
    daemonConfigDeleted,
    saveDaemonConfigStarted,
    saveDaemonConfigFinished,
    resetDaemonConfigStarted,
    resetDaemonConfigFinished,
    deleteDaemonConfigStarted,
    deleteDaemonConfigFinished,
    daemonConfigsFetched,
} = daemonSlice.actions;

// uncomment if these are needed locally
// const localSelectors = daemonSlice.getSelectors();

export const { getInitialState, selectors: daemonSliceSelectors } = daemonSlice;

export default daemonSlice.reducer;
