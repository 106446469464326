import React from 'react';
import { PageSection, PageSectionVariants, EmptyState, Title, EmptyStateBody } from '@patternfly/react-core';

import { useSelector } from 'react-redux';

import '../customization-page.css';
import CustomizationForm from './CustomizationForm';
import CustomizationToolbar from './CustomizationToolbar';
import FullRoiDetailsModal from './FullRoiDetailsModal';
import { useTranslation } from 'react-i18next';
import { contouringSelectors } from '../../../store/contouring/contouringSlice';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';


interface ContourCustomizationPageProps {
    /** ID of the item we're currently focusing on (on what we last clicked on) */
    currentTreeViewFocus: string,
}


const ContourCustomizationPage = (props: ContourCustomizationPageProps) => {

    const { currentTreeViewFocus } = props;

    const { t } = useTranslation();

    const baseIds = useSelector(contouringSelectors.selectCustomizationBaseIds);
    const outputIds = useSelector(contouringSelectors.selectOutputIds);
    const customizationFetchError = useSelector((state: StoreState) => state.contouring.customizationFetchError);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);


    // detect what type of view we're currently presenting
    let currentViewType = CustomizationObjectType.None;
    const isAnOutputCurrentlySelected = outputIds.includes(currentTreeViewFocus);
    if (isAnOutputCurrentlySelected) {
        currentViewType = CustomizationObjectType.CustomizationOutput;
    } else if (baseIds.includes(currentTreeViewFocus)) {
        currentViewType = CustomizationObjectType.CustomizationBase;
    }

    return (
        <>
            {appConfig && appConfig.showErrorsInUi && customizationFetchError !== null ? (
                <PageSection variant={PageSectionVariants.light}>
                    <EmptyState>
                        <Title headingLevel="h4" size="lg">{t('error.generic')}</Title>
                        <EmptyStateBody>{t('error.customizationFetchFailed', { customizationFetchError: customizationFetchError })}</EmptyStateBody>
                    </EmptyState>
                </PageSection>
            ) : (
                <>
                    <CustomizationToolbar
                        currentView={currentViewType}
                        customizationOutputId={currentTreeViewFocus}
                    />
                    <FullRoiDetailsModal />
                    <PageSection variant={PageSectionVariants.light}>
                        {isAnOutputCurrentlySelected ? (
                            <CustomizationForm
                                customizationOutputId={currentTreeViewFocus}
                            // modelValidation={activeValidation!} 
                            />
                        ) : (
                            <EmptyState>
                                <EmptyStateBody>{t('customizationPage.selectCustomization.message')}</EmptyStateBody>
                            </EmptyState>
                        )
                        }
                    </PageSection>
                </>
            )}
        </>
    );
}

export default ContourCustomizationPage;
