import i18next from "i18next";

/** Types of form validation errors. The specified strings are purely for making javascript keys more handy
 * to view in debugger -- they should not be displayed in UI. Use getFormValidationErrorMessage below
 * to get the user-facing validation string.
 */
export enum FormValidationErrorType {
    /** This form validation error has no specific type. This should NOT be used for user-facing cases. */
    Unspecified = 'Unspecified',

    // ROI-specific errors

    /** Roi name is empty (or just whitespace). */
    RoiNameIsEmpty = 'RoiNameIsEmpty',

    /** Duplicate ROI names are not allowed within a customization output. */
    DuplicateRoiNamesInOutput = 'DuplicateRoiNamesInOutput',


    // DICOM rule and DICOM attribute rule-specific errors

    /** DICOM rule has duplicates of the same attribute. */
    DicomAttributesMustNotRepeat = 'DicomAttributesMustNotRepeat',

    /** DICOM attribute rule name is empty. */
    DicomAttributeNameMustNotBeEmpty = 'DicomAttributeNameMustNotBeEmpty',

    /** DICOM rule has no attributes. */
    DicomRuleMustHaveAtLeastOneAttribute = 'DicomRuleMustHaveAtLeastOneAttribute',
}

/** Gets a localized version of a form validation error message. */
export const getFormValidationErrorMessage = (errorType: FormValidationErrorType) => {
    switch (errorType) {
        case FormValidationErrorType.RoiNameIsEmpty:
            return i18next.t('translation:error.formValidation.roiNameIsEmpty');
        case FormValidationErrorType.DuplicateRoiNamesInOutput:
            return i18next.t('translation:error.formValidation.duplicateRoiNamesInOutput');
        case FormValidationErrorType.DicomAttributesMustNotRepeat:
            return i18next.t('translation:error.formValidation.dicomAttributesMustNotRepeat');
        case FormValidationErrorType.DicomAttributeNameMustNotBeEmpty:
            return i18next.t('translation:error.formValidation.dicomAttributeNameMustNotBeEmpty');
        case FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute:
            return i18next.t('translation:error.formValidation.dicomRuleMustHaveAtLeastOneAttribute');
        default:
            throw new Error('Unsupported form validation error');
    }
}