import { Button, ButtonProps } from "@patternfly/react-core";
import React from "react";

import './tilt-button.css';

interface TiltButtonProps {
    /** Adjusts the CSS transform origin property of the tilt. Defaults to 'center' which doesn't always work nicely with SVG icons etc. */
    tiltOrigin?: string;
    /** If true, forces the button to display as tilted. If false or unset, the default cursor hover behaviour is used. */
    forceTilt?: boolean;
}

const DEFAULT_TRANSFORM_ORIGIN = 'center'

/** A button that tilts slightly when hovered over to hint that it can be clicked. */
const TiltButton = (props: React.PropsWithChildren<ButtonProps & TiltButtonProps>) => {
    const { children, tiltOrigin, forceTilt, ...buttonProps } = props;
    return (<Button variant="plain" {...buttonProps} ><div className={`tilt-button-contents ${props.forceTilt ? 'is-tilted' : ''}`} style={{ 'transformOrigin': tiltOrigin || DEFAULT_TRANSFORM_ORIGIN }}>{children}</div></Button>);
}

export default TiltButton;
