import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextContent, Text, DataList, TextVariants, Divider, Tooltip, TextInputGroup, TextInput, Button, Select, SelectOption, SelectOptionObject, Alert, Switch, Flex } from '@patternfly/react-core';
import { useSelector, useDispatch } from 'react-redux';
import { LockIcon, InfoCircleIcon } from '@patternfly/react-icons'

import RoiCustomizationForm from './RoiCustomizationForm';
import ModelMetadataItemForm from './ModelMetadataItemForm';
import SmallAccordionButton from '../SmallAccordionButton';
import '../customization-page.css';
import { usePreviousImmediate } from 'rooks';
import SelectionRules from '../../selection/CustomizationRules';
import SectionLabel from '../SectionLabel';
import { isSomeEnum } from '../../../util/enum';
import { TableComposable, Tbody, Th, Thead, Tr } from '@patternfly/react-table';
import { columnIds, columnLangKeys, columnTooltips } from './RoiTable';
import { Trans, useTranslation } from 'react-i18next';
import iconEdit from '../../../img/edit.png';
import { notEmpty } from '../../../util/filter';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';
import { allRoisInCustomizationOutputToggled, contouringSelectors, modelCustomizationDescriptionUpdated, modelVisibilityUpdated } from '../../../store/contouring/contouringSlice';
import { makeSelectIsAnyMetadataForOutputModified, makeSelectIsAnyRoiForOutputModified, makeSelectIsAnyTriggerForBaseModified, makeSelectRoiCustomizationsForOutput } from '../../../store/contouring/contouringSelectors';
import { CustomizationObjectType, ModelVisibility } from '../../../store/global-types/customization-types';

interface CustomizationFormProps {
    customizationOutputId: string,
}

const CustomizationForm = (props: CustomizationFormProps) => {
    const { customizationOutputId } = props;
    const dispatch = useDispatch();

    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useTranslation();
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const customizationOutput = useSelector((state: StoreState) => contouringSelectors.selectOutputById(state, customizationOutputId));
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? contouringSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const segmentationModel = useSelector((state: StoreState) => customizationBase ? contouringSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const validationEntities = useSelector(contouringSelectors.selectCustomizationValidationErrorEntities);

    const selectRoiCustomizationsForOutput = useMemo(makeSelectRoiCustomizationsForOutput, []);
    const roiCustomizations = useSelector((state: StoreState) => selectRoiCustomizationsForOutput(state, customizationOutputId));

    const selectIsAnyRoiForOutputModified = useMemo(makeSelectIsAnyRoiForOutputModified, []);
    const isAnyRoiModified = useSelector((state: StoreState) => selectIsAnyRoiForOutputModified(state, customizationOutputId));

    const selectIsAnyMetadataForOutputModified = useMemo(makeSelectIsAnyMetadataForOutputModified, []);
    const isAnyMetadataModified = useSelector((state: StoreState) => selectIsAnyMetadataForOutputModified(state, customizationOutputId));

    const selectIsAnyTriggerRuleForBaseModified = useMemo(makeSelectIsAnyTriggerForBaseModified, []);
    const isAnyTriggerRuleModified = useSelector((state: StoreState) => customizationOutput ? selectIsAnyTriggerRuleForBaseModified(state, customizationOutput.modelCustomizationBaseId) : false);

    const [isMetadataSectionExpanded, setMetadataSectionExpanded] = useState(false);
    const [isTriggersSectionExpanded, setTriggersSectionExpanded] = useState(false);
    const [editedDescription, setEditedDescription] = useState('');
    const [isRoisSectionExpanded, setRoisSectionExpanded] = useState(true);
    const [areAllRoisIncluded, setAreAllRoisIncluded] = useState(true);
    const [isModelVisibilitySelectOpen, setIsModelVisibilitySelectOpen] = useState(false);

    const handleToggleMetadataExpanded = useCallback(() => {
        setMetadataSectionExpanded(!isMetadataSectionExpanded);
    }, [isMetadataSectionExpanded]);

    const handleToggleTriggersExpanded = useCallback(() => {
        setTriggersSectionExpanded(!isTriggersSectionExpanded);
    }, [isTriggersSectionExpanded]);

    const handleToggleRoiCustomizationExpanded = useCallback(() => {
        setRoisSectionExpanded(!isRoisSectionExpanded);
    }, [isRoisSectionExpanded]);

    const handleToggleModelVisibilitySelectOpen = useCallback(() => {
        setIsModelVisibilitySelectOpen(!isModelVisibilitySelectOpen);
    }, [isModelVisibilitySelectOpen]);

    const handleSetModelVisibility = useCallback((_: unknown, value: ModelVisibility | SelectOptionObject,) => {
        if (segmentationModel && isSomeEnum(ModelVisibility)(value)) {
            dispatch(modelVisibilityUpdated({ modelId: segmentationModel.id, visibility: value }));
        }
        setIsModelVisibilitySelectOpen(false);
    }, [dispatch, segmentationModel]);

    const previousCustomization = usePreviousImmediate(customizationOutput);

    const handleSelectAllRois = useCallback((includeAllRois: boolean) => {
        if (customizationOutput) {
            dispatch(allRoisInCustomizationOutputToggled({ customizationOutputId: customizationOutput.id, isIncluded: includeAllRois }));
            setAreAllRoisIncluded(includeAllRois);
        }
    }, [dispatch, customizationOutput]);


    useEffect(() => {
        if (customizationOutput && customizationBase && previousCustomization && !isMetadataSectionExpanded && customizationOutput.id === previousCustomization.id && customizationOutput.metadata.length !== previousCustomization.metadata.length) {
            // make sure metadata section is expanded if metadata is added or removed
            setMetadataSectionExpanded(true);
        }
        if (customizationOutput && customizationBase && customizationOutput.id === customizationOutputId) {
            setEditedDescription(customizationBase.description);
        }
        // update the state of the "all rois included" switch
        if (customizationOutput && customizationOutput.rois.length > 0) {
            setAreAllRoisIncluded(roiCustomizations.every(r => r.isIncluded));
        }
    }, [customizationOutput, previousCustomization, isMetadataSectionExpanded, customizationBase, customizationOutputId, roiCustomizations]);

    const includedCount = useMemo(() => roiCustomizations.filter(r => r.isIncluded).length, [roiCustomizations]);
    const totalStructureCount = roiCustomizations.length;

    // sanity check that we have retrieved valid objects from redux store
    // (this check has to be done after all hooks calls)
    if (customizationOutput === undefined || customizationBase === undefined || segmentationModel === undefined) {
        return null;
    }

    const customizationName = customizationBase.customizationName;


    const onDescriptionChanged = (text: string) => {
        if (text === undefined) return;
        dispatch(modelCustomizationDescriptionUpdated({ customizationBaseId: customizationBase.id, description: text }));
    };

    const onInputRef = () => {
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    }

    const triggersHaveValidationErrors = customizationBase.aeTitleRules.some(tId => validationEntities[tId] !== undefined && validationEntities[tId]!.type === CustomizationObjectType.AeTitleRule)
        || customizationBase.dicomRules.some(tId => validationEntities[tId] !== undefined && validationEntities[tId]!.type === CustomizationObjectType.DicomRule);
    const metadataHasValidationErrors = customizationOutput.metadata.some(mId => validationEntities[mId] !== undefined && validationEntities[mId]!.type === CustomizationObjectType.Metadata);
    const roisHaveValidationErrors = customizationOutput.rois.some(rId => validationEntities[rId] !== undefined && validationEntities[rId]!.type === CustomizationObjectType.Roi);
    const customizationValidationErrors = Object.values(validationEntities).filter(notEmpty)
        .filter(v => (v.type === CustomizationObjectType.CustomizationBase && v.id === customizationBase.id) || (v.type === CustomizationObjectType.CustomizationOutput && v.id === customizationOutputId));

    const triggersSectionLabel = <SectionLabel
        label={t('customizationPage.triggersSection.title')}
        isModified={isAnyTriggerRuleModified}
        isValid={!triggersHaveValidationErrors}
        itemCount={customizationBase.aeTitleRules.length + customizationBase.dicomRules.length} />
    const metadataSectionLabel = <SectionLabel
        label={t('customizationPage.metadataSection.title')}
        isModified={isAnyMetadataModified}
        isValid={!metadataHasValidationErrors}
        itemCount={customizationOutput.metadata.length} />
    const roisSectionLabel = <SectionLabel
        label={t('customizationPage.roisSection.title')}
        isModified={isAnyRoiModified}
        isValid={!roisHaveValidationErrors}
        itemCount={customizationOutput.rois.length} />

    return (
        <div className="model-customization-form">

            <div className="model-inclusion-bar">
                <TextContent>
                    <Text component="h2">
                        <span className="customization-name">
                            {!segmentationModel.isAvailable && (<span className="model-customization-no-license icon" title={t('customizationPage.noLicense.tooltip')}><LockIcon /></span>)}
                            <span className={`human-readable-label ${!segmentationModel.isAvailable ? 'model-customization-no-license' : ''}`}>{segmentationModel.label}</span>
                            <span className="label-parenthesis">(</span>
                            <span className="model-label">{segmentationModel.modelName}</span>
                            <span className="model-customization-separator">.</span>
                            <span className="customization-label">{customizationName}</span>
                            <span className="label-parenthesis">)</span>
                        </span>
                        <span className="model-id-summary">
                            <span>{t('customizationPage.targetFile')}: <span className="model-id-name">{customizationOutput.filename}</span></span>
                            <span>{t('customizationPage.modelVisibility')}:
                                <span className="model-visibility">
                                    <Select
                                        onToggle={handleToggleModelVisibilitySelectOpen}
                                        onSelect={handleSetModelVisibility}
                                        selections={segmentationModel.visibility}
                                        isOpen={isModelVisibilitySelectOpen}>
                                        {[
                                            <SelectOption key={0} value={ModelVisibility.Default}>{t('modelVisibility.default')}</SelectOption>,
                                            <SelectOption key={1} value={ModelVisibility.AlwaysShown}>
                                                {t('modelVisibility.alwaysShow')}
                                                <Tooltip position={'right'} flipBehavior={['left']} content={t('modelVisibility.alwaysShow.description')}><InfoCircleIcon className="visibility-selector-icon" /></Tooltip>
                                            </SelectOption>,
                                            <SelectOption key={2} value={ModelVisibility.AlwaysHidden}>
                                                {t('modelVisibility.alwaysHide')}
                                                <Tooltip position={'right'} flipBehavior={['left']} content={t('modelVisibility.alwaysHide.description')}><InfoCircleIcon className="visibility-selector-icon" /></Tooltip>
                                            </SelectOption>
                                        ]}
                                    </Select>
                                </span>
                            </span>
                        </span>
                        <div className="model-description">
                            {segmentationModel.description && (
                                <div className='span-flex'>
                                    <span style={{ fontWeight: 700 }}>
                                        <span style={{ fontWeight: 400 }}>{segmentationModel.description}</span>
                                    </span>
                                </div>
                            )}
                            {customizationBase.id && (
                                <div className='span-flex'>
                                    <span style={{ fontWeight: 700 }}>
                                        <TextInputGroup>
                                            <TextInput id="customization-description" onChange={onDescriptionChanged} value={editedDescription} placeholder={t('customizationPage.modelDescription.noDescription')} ref={inputRef} />
                                            <Tooltip content={t('customizationPage.modelDescription.edit')} position="top">
                                                <Button variant="plain" aria-label={t('customizationPage.modelDescription.edit')} onClick={onInputRef}>
                                                    <img src={iconEdit} alt="icon" width={16} />
                                                </Button>
                                            </Tooltip>
                                        </TextInputGroup>
                                    </span>
                                </div>
                            )}
                        </div>
                    </Text>

                    {!segmentationModel.isAvailable && (
                        <Text component="p" className=""><b>{t('customizationPage.noLicense.message')}</b></Text>
                    )}

                    <Text component="p"><Trans i18nKey="customizationPage.includedCounts">
                        Currently structure sets generated with this customization will include <b>{{ includedCount }}</b> out of <b>{{ totalStructureCount }}</b> available structures.
                    </Trans></Text>
                </TextContent>

                {customizationValidationErrors.length > 0 && (
                    <div className="toolbar-error">
                        <Alert variant="danger" isInline title={t('customizationPage.validationErrors')}>
                            {customizationValidationErrors.map((v, i) => (<p key={i}>{v.message}</p>))}
                        </Alert>
                    </div>
                )}

                <Divider />
            </div>

            <div className="model-config-metadata">
                <TextContent>
                    <Text component="h3">
                        <SmallAccordionButton
                            isActive={isTriggersSectionExpanded}
                            label={triggersSectionLabel}
                            onClick={handleToggleTriggersExpanded}
                            className="mv-model-list-section" />
                    </Text>
                </TextContent>
                <div className={isTriggersSectionExpanded ? '' : 'is-collapsed'}>
                    <DataList aria-label={`Model selection configuration`} isCompact>
                        <SelectionRules
                            customizationBaseId={customizationBase.id}
                            disableUndo={true}
                            setUndoState={() => { }}
                            heading={`model-selection-for-${customizationBase.id}`}
                            hideCustomizationName
                            showMultipleOutputsWarning={customizationBase.outputs.length > 1}
                        />
                    </DataList>
                </div>
                {!isTriggersSectionExpanded && (<div className="section-hint"><Text component={TextVariants.small}>{t('customizationPage.triggersSection.message')}</Text></div>)}
            </div>

            <div className="model-config-metadata">
                <TextContent>
                    <Text component="h3">
                        <SmallAccordionButton
                            isActive={isMetadataSectionExpanded}
                            label={metadataSectionLabel}
                            onClick={handleToggleMetadataExpanded}
                            className="mv-model-list-section" />
                    </Text>
                </TextContent>
                <div className={isMetadataSectionExpanded ? '' : 'is-collapsed'}>
                    <DataList isCompact aria-label={`Metadata config form for customization ${customizationName}`}>
                        {customizationOutput.metadata.map(metadataId => <ModelMetadataItemForm metadataId={metadataId} key={metadataId} />)}
                    </DataList>
                </div>
                {!isMetadataSectionExpanded && (<div className="section-hint"><Text component={TextVariants.small}>{t('customizationPage.metadataSection.message')}</Text></div>)}
            </div>

            <div>
                <TextContent>
                    <Text component="h3">
                        <SmallAccordionButton
                            isActive={isRoisSectionExpanded}
                            label={roisSectionLabel}
                            onClick={handleToggleRoiCustomizationExpanded}
                            className="mv-model-list-section" />
                    </Text>
                </TextContent>
                <div className={isRoisSectionExpanded ? '' : 'is-collapsed'}>
                    <TableComposable variant='compact' isStickyHeader className="mv-sticky-fix">
                        <Thead>
                            <Tr className='customization-form-table'>
                                <Th></Th>
                                <Th>{t(columnLangKeys[columnIds.name])}</Th>
                                <Th info={{ tooltip: columnTooltips[columnIds.isIncluded]() }}>
                                    <Flex type='col' className='enabled-col-flex'>{t(columnLangKeys[columnIds.isIncluded])}
                                        <Switch
                                            id={'select-all-rois-switch'}
                                            isChecked={areAllRoisIncluded}
                                            aria-label='Select/Deselect all ROIs'
                                            onChange={() => handleSelectAllRois(!areAllRoisIncluded)}
                                        />
                                    </Flex>
                                </Th>
                                <Th>{t(columnLangKeys[columnIds.color])}</Th>
                                <Th info={{ tooltip: columnTooltips[columnIds.operation](deploymentInfo?.operationsManualUrl) }}>{t(columnLangKeys[columnIds.operation])}</Th>
                                <Th>{t(columnLangKeys[columnIds.interpretedType])}</Th>
                                <Th>{t(columnLangKeys[columnIds.fmaCodeValue])}</Th>
                                <Th>{t(columnLangKeys[columnIds.fmaCodeMeaning])}</Th>
                                <Th>{t(columnLangKeys[columnIds.fmaCodeSchemeDesignator])}</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {customizationOutput.rois.map(rId => <RoiCustomizationForm roiId={rId} key={rId} roiIdList={customizationOutput.rois} />)}
                        </Tbody>
                    </TableComposable>
                </div>
                {!isRoisSectionExpanded && (<div className="section-hint"><Text component={TextVariants.small}>{t('customizationPage.roisSection.message')}</Text></div>)}
            </div>
        </div>
    );
}

export default CustomizationForm;
