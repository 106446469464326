import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../store";
import { doseSelectors } from "./doseSlice";
import { CustomizationObjectType, CustomizationOutput } from "../global-types/customization-types";
import { isDoseCustomizationOutput } from "./dose-types";
import { BackendValidationErrorViewModel } from "../global-types/store-errors";
import { generateCustomizationTreeViewItems } from "../global-types/customizationSelectors";

/** Generate a tree view hierarchy of models and some of their child objects. This is used by the customization page side navigation.
 * This data isn't ready-to-go for Patternfly's TreeView component but the separate convertTreeViewDataIntoItemHierarchy helper function
 * in customization-helpers.tsx is used to generate the appropriate data from what this function produces.
 */
export const selectDoseTreeViewItems = createSelector([
    (state: StoreState) => doseSelectors.selectModelsInAlphabeticalOrder(state),
    (state: StoreState) => doseSelectors.selectCustomizationBasesInAlphabeticalOrder(state),
    (state: StoreState) => doseSelectors.selectOutputEntities(state),
    (state: StoreState) => doseSelectors.selectOutputMetadataEntities(state),
    (state: StoreState) => doseSelectors.selectCustomizationValidationErrorEntities(state),
],
    (models, customizationBases, outputEntities, metadataEntities, validationEntities) => {

        // create an inline validation function that will be supplied to tree view generator function
        const areOutputChildrenValid = (output: CustomizationOutput, validationEntities: Record<string, BackendValidationErrorViewModel>): boolean => {
            if (!isDoseCustomizationOutput(output)) {
                throw new Error('Invalid output type');
            }
            return output.rois.every(rId => !validationEntities[rId] || validationEntities[rId]!.type !== CustomizationObjectType.Roi);
        }

        const results = generateCustomizationTreeViewItems(models, customizationBases, outputEntities, metadataEntities, validationEntities, areOutputChildrenValid);
        return results;
    });
