import { cloneDeep } from "lodash-es";

// TypeScript needs a little help here to strongly type properties of incoming type T
// from https://stackoverflow.com/a/58646032
type SortableProps<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];

/** Generic natural sorting function, ignores case and includes numbers in a human-sensible
 * manner. Creates a deep copy of the given array. Allows sorting by arbitrary property
 * in the objects in the array.
 * 
 * Usage example:
 * const itemList: ItemList[] = getItems();
 * const sorted = naturalSort(itemList, 'itemName');
 * 
 * @param array Array to sort.
 * @param propName The string prop to sort the array by.
 */
export function naturalSort<T>(array: Array<T>, propName: SortableProps<T>): T[] {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const sorted = cloneDeep(array).sort((a, b) => collator.compare(a[propName] as unknown as string, b[propName] as unknown as string));
    return sorted;
}

/** Generic natural sorting function, ignores case and includes numbers in a human-sensible
 * manner. Sorting is done in place -- does NOT create a new copy of the array. */
export function naturalSortInPlace<T>(array: Array<T>, propName: SortableProps<T>): T[] {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return array.sort((a, b) => collator.compare(a[propName] as unknown as string, b[propName] as unknown as string));
}
