import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSection, PageSectionVariants, TextContent, Text, Split, SplitItem, Tabs, Tab, TabTitleText, Spinner, Alert } from '@patternfly/react-core';
import UnlockIcon from '@patternfly/react-icons/dist/js/icons/unlock-icon';

import { TextEditorFile } from '../../store/textEditor/text-editor-file';
import TextEditorForm from './TextEditorForm';

import './text-editor-page.css';
import { StoreState } from '../../store/store';
import { textEditorFilesFetched } from '../../store/textEditor/textEditorSlice';


const getTab = (i: number, t: TextEditorFile): React.ReactNode => {
    const title = t.filePath;
    const className = 'nav-sub-page-title';
    return (<Tab eventKey={i} title={<TabTitleText className={className} title={t.description}>{title} {t.isModified && (<UnlockIcon />)}</TabTitleText>} key={t.filePath} />);
}

const TextEditorPage = () => {

    // this will crash if we're not in a cockpit environment. however, it should not be possible
    // to navigate to the text editor page unless in such an environment so this is not an issue

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(textEditorFilesFetched());
    }, [dispatch]);

    const files = useSelector((state: StoreState) => state.textEditor.textEditorFiles);
    const isLoading = useSelector((state: StoreState) => state.textEditor.isLoadingTextEditorFilePaths);
    const [activeModelTab, setActiveModelTab] = useState(0);

    return (
        <>
            <PageSection variant={PageSectionVariants.default}>
                <TextContent>
                    <Text component="h1">File editor</Text>
                    <Text component="p">You can view and manually modify certain system configuration files from here.</Text>
                </TextContent>

                <Alert className="warning-padding" variant="warning" isInline title="Warning: it is possible to render the server inoperable by modifying the system files within this file editor. Caution is advised." />
            </PageSection>

            <PageSection variant={PageSectionVariants.light}>
                <Split >
                    <SplitItem>
                        <div className="page-nav text-editor-nav">
                            <div className="page-nav-title"><TextContent><Text component="h3">Files</Text></TextContent></div>
                            {isLoading && files.length === 0 ? (<Spinner size="lg" className="nav-spinner" />) : (
                                <Tabs isVertical onSelect={(_, tabIndex) => setActiveModelTab(tabIndex as number)} activeKey={activeModelTab}>
                                    {files.map((f, i) => getTab(i, f))}
                                </Tabs>)}
                        </div>
                    </SplitItem>
                    <SplitItem isFilled>
                        {(files.length >= activeModelTab + 1) &&
                            (<PageSection variant={PageSectionVariants.light}>
                                <TextEditorForm file={files[activeModelTab]} />
                            </PageSection>)
                        }
                    </SplitItem>
                </Split>
            </PageSection>

        </>
    );
}

export default TextEditorPage;
