import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LicenseStatus } from './license-status';
import { MVisionAppClient } from '../configurationTarget/mvision-client-list';

export type LicenseSliceState = {
    /** License status for current user/app. One or multiple licenses. */
    licenseStatus: LicenseStatus[] | null,

    /** Error when fetching licenses, if any. */
    licenseStatusError: string | null,

    /** True if license is currently being updated, false otherwise. */
    isUpdatingLicense: boolean,

    /** An error message if something went wrong during license update, or null. */
    licenseUpdateError: string | null,

    successfulLicenseUpdatesCount: number,
};

export const initialState: LicenseSliceState = {
    licenseStatus: null,
    licenseStatusError: null,
    isUpdatingLicense: false,
    licenseUpdateError: null,
    successfulLicenseUpdatesCount: 0,
};

/** License-related redux store slice.  */
const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {
        /**
         * Sets license status objects into store.
         */
        licenseStatusSet(state, action: PayloadAction<{ licenseStatus: LicenseStatus[] | null, error?: string }>) {
            const { licenseStatus, error } = action.payload;

            if (licenseStatus !== null) {
                state.licenseStatus = licenseStatus;
            }

            state.licenseStatusError = error !== undefined ? error : null;
        },

        /**
         * Marks license update process as having started. Note that the payload props are
         * only used in sagas, not in the reducer, but are still required.
         * @param action.newLicense The new license string to use in the update.
         * @param action.appClient The configuration target for which the license is being updated.
         */
        updateLicenseStarted(state, action: PayloadAction<{ newLicense: string, appClient: MVisionAppClient | undefined }>) {
            state.isUpdatingLicense = true;
            state.licenseUpdateError = null;
        },

        /**
         * Marks license update process as having finished.
         * @param action.success True if license update succeeded, false otherwise.
         * @param action.error Error message if license update did not succeed, undefined otherwise.
         */
        updateLicenseFinished(state, action: PayloadAction<{ success: boolean, error: string | undefined }>) {
            const { success, error } = action.payload;

            state.isUpdatingLicense = false;
            state.licenseUpdateError = error !== undefined ? error : null;

            if (success) {
                state.successfulLicenseUpdatesCount++;
            }
        },

        /**
         * Starts fetching available license(s) for supplied configuration target.
         * @param action The configuration target for which the license(s) are being retrieved for.
         */
        licenseStatusFetched(state, action: PayloadAction<MVisionAppClient | undefined>) {
            // this is an empty action and is only used for signalling in sagas

        },

    }

});

export const {
    licenseStatusSet,
    updateLicenseStarted,
    updateLicenseFinished,
    licenseStatusFetched,
} = licenseSlice.actions;

// uncomment if these are needed locally
// const localSelectors = licenseSlice.getSelectors();

export const { getInitialState, /*selectors: licenseSliceSelectors*/ } = licenseSlice;

export default licenseSlice.reducer;
