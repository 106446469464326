import { SystemFileQuery, TextEditorFile, createNewTextEditorFile } from '../store/textEditor/text-editor-file';
import { getSystemFileQueries } from '../app-config';


/**
 * API for accessing text editor files through Cockpit.
 * 1. Text editor is only supported in a local cockpit environment.
 *     -> accessing this class will crash if cockpit is not loaded into environment
 * 2. Text editor only supports modifying linux text files.
 */
export default class TextEditorFilesApi {

    private static async runCockpitProcess(script: string, attempt?: number): Promise<any> {
        // see https://cockpit-project.org/guide/latest/cockpit-spawn.html
        const process = (window as any).cockpit.script(script, [], { "err": "message", "superuser": "try" })
            .then((data: any, message: any) => {
                return data;
            })
            .catch((err: any, data: any) => {
                console.log(`Tried to run script, catched exception instead:`);
                console.log(err);
                console.log(data);
                return null;
            });
        if (process === null) {
            throw new Error('Could not load Cockpit Process client.');
        }

        return process;
    }

    private static async readFile(filePath: string): Promise<any> {
        const file = (window as any).cockpit.file(filePath, { superuser: "try" });
        return await file.read();
    }

    private static async writeToFile(filePath: string, newContents: string): Promise<any> {
        const file = (window as any).cockpit.file(filePath, { superuser: "try" });
        return await file.replace(newContents);
    }


    public static async searchFiles() {

        let files: TextEditorFile[] = [];

        for (const query of getSystemFileQueries()) {
            const result = await TextEditorFilesApi.searchForFileAsync(query);
            const filePaths = result.split('\n').filter(s => s);
            files = files.concat(filePaths.map(fp => createNewTextEditorFile(fp, query.allowWrite, query.description)));

        }

        return files;
    }

    private static async searchForFileAsync(query: SystemFileQuery): Promise<string> {

        // cockpit will run scripts in bourne shell, so run an inline script in bash instead
        // so we can take advantage of brace expansions in the file queries
        // also ignore ls errors from directories that don't exist
        const script = `#!/bin/sh
        bash -c 'function find_files() {
            echo "$(ls -d -1 ${query.queryPattern} 2> /dev/null)";
        };
        find_files'`;

        const result = await TextEditorFilesApi.runCockpitProcess(script);
        return result;
    }

    public static async loadFile(filePath: string): Promise<string> {
        const contents: string = await TextEditorFilesApi.readFile(filePath);
        return contents;
    }

    public static async saveFile(filePath: string, newFileContents: string) {
        await TextEditorFilesApi.writeToFile(filePath, newFileContents);
    }
}
