import { all, call, put, select, take, takeEvery } from "typed-redux-saga";
import ConfigurationClient from "../../web-apis/configuration-client";
import { MVisionAppClient } from "./mvision-client-list";
import { daemonPrefix } from "../../environments";
import { get, isString } from "lodash-es";
import { configurationTargetListFetched, configurationTargetListSet, configurationTargetSelectors, currentConfigurationTargetSet, fetchConfigurationTargetDataFinished, fetchConfigurationTargetDataStarted } from "./configurationTargetSlice";
import { fetchContouringCustomizationsSaga } from "../contouring/contouringSagas";
import { fetchDaemonConfigsSaga } from "../daemon/daemonSagas";
import { fetchDoseCustomizationsSaga } from "../dose/doseSagas";

export function* fetchConfigurationTargetListSaga() {

    const client = new ConfigurationClient();
    let configurationTargetList: MVisionAppClient[] | undefined | null = null;
    let error = null;


    try {
        configurationTargetList = yield* call(async () => client.fetchClientListAsync());
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error === null) {
        yield* put(configurationTargetListSet({ configurationTargetList }));

        // immediately set current app client in certain conditions
        // (re-select client list here in case we do any post-processing
        // for the list in the reducer in future)
        configurationTargetList = yield* select(configurationTargetSelectors.selectConfigurationTargetList);
        if (configurationTargetList !== undefined) {
            if (configurationTargetList.length === 1) {
                // if we only have one item available, auto-select it
                yield* put(currentConfigurationTargetSet(configurationTargetList[0].userId));
            } else {
                // if there's exactly one daemon client available, auto-select it
                const daemons = configurationTargetList.filter(c => c.userName.startsWith(daemonPrefix));
                if (daemons.length === 1) {
                    yield* put(currentConfigurationTargetSet(daemons[0].userId));
                }
            }
        }
    } else {
        // error case
        console.error('Was not able to fetch client list');
        // TODO: this format might be cockpit-specific? In that case it must be fixed everywhere in this file.
        const errorMessage = isString(error) ? error : `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        yield* put(configurationTargetListSet({ configurationTargetList: null, errorMessage: `An error occurred when trying to retrieve client list: ${errorMessage}` }));
    }
}

function* watchFetchConfigurationTargetListSaga() {
    yield* takeEvery(configurationTargetListFetched, fetchConfigurationTargetListSaga);
}

/** Watch when current configuration target is changed and re-fetch any data related to previous target as well. */
function* watchCurrentConfigurationTargetListSet() {
    while (true) {
        yield* take(currentConfigurationTargetSet);
        const currentTarget = yield* select(configurationTargetSelectors.selectCurrent);

        // block UI while fetching new values
        yield* put(fetchConfigurationTargetDataStarted());

        // queue re-fetches (currently just model and daemon config, licenses are gotten through the page component instead of here)
        yield* all([
            call(fetchContouringCustomizationsSaga, currentTarget),
            call(fetchDoseCustomizationsSaga, currentTarget),
            call(fetchDaemonConfigsSaga, currentTarget)
        ]);

        // re-fetching is done
        yield* put(fetchConfigurationTargetDataFinished());
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForConfigurationTargetSagas() {
    return [
        watchFetchConfigurationTargetListSaga(),
        watchCurrentConfigurationTargetListSet(),
    ];
}