import { Form, FormGroup, TextArea, TextContent, Text, Toolbar, Button, Modal, ModalVariant, ToolbarContent, ToolbarItem, Spinner, Alert } from '@patternfly/react-core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@patternfly/react-icons/dist/js/icons/lock-icon';
import UnlockIcon from '@patternfly/react-icons/dist/js/icons/unlock-icon';
import SyncIcon from '@patternfly/react-icons/dist/js/icons/sync-alt-icon';
import SaveIcon from '@patternfly/react-icons/dist/js/icons/save-icon';
import UndoIcon from '@patternfly/react-icons/dist/js/icons/undo-alt-icon';

import { TextEditorFile } from '../../store/textEditor/text-editor-file';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { fetchTextEditorFileContentsStarted, saveTextEditorFileContentsStarted, textEditorFileChangesReverted, textEditorFileClosedForEditing, textEditorFileModified, textEditorFileOpenedForEditing } from '../../store/textEditor/textEditorSlice';

interface TextEditorFormProps {
    file: TextEditorFile,
}

enum UndoAction {
    Undo,
    Reload,
    Close,
}

const getUndoTexts = (undoAction: UndoAction): { text: string, title: string, confirmText: string, cancelText: string } => {
    switch (undoAction) {
        case UndoAction.Reload:
            return {
                text: 'Are you sure you wish to reload this file?',
                title: 'Confirm file reload',
                confirmText: 'Reload file',
                cancelText: 'Cancel'
            };
        case UndoAction.Close:
            return {
                text: 'Are you sure you wish to close this file?',
                title: 'Confirm closing of file',
                confirmText: 'Close file',
                cancelText: 'Keep file open'
            };
        case UndoAction.Undo:
        default:
            return {
                text: 'Are you sure you wish to undo changes to this file?',
                title: 'Confirm file undo',
                confirmText: 'Undo changes',
                cancelText: 'Keep changes'
            };
    }
}

const TextEditorForm = (props: TextEditorFormProps) => {
    const { file } = props;

    const dispatch = useDispatch();
    const [isUndoConfirmationModalOpen, setUndoConfirmationModalOpenState] = useState(false);
    const [currentUndoAction, setUndoAction] = useState(UndoAction.Undo);
    const [showTypingBlockedWarning, setShowTypingBlockedWarning] = useState(false);
    const [isHoveringLockButton, setHoverOnLockButton] = useState(false);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    const handleTextChange = (value: string) => {
        // the current implementation is that the file is already flagged as 'modified' when
        // it's opened for editing
        if (file.allowWrite && file.isModified) {
            dispatch(textEditorFileModified({ filePath: file.filePath, newContents: value }));
        } else {
            // toggle warning css style off and then back on to reset the animation
            setShowTypingBlockedWarning(false);
            setTimeout(() => setShowTypingBlockedWarning(true), 1);
        }
    };

    const handleToggleEditChange = () => {
        if (!file.isModified && file.allowWrite) { dispatch(textEditorFileOpenedForEditing(file.filePath)); }
        if (file.isModified) {
            setUndoAction(UndoAction.Close);
            setUndoConfirmationModalOpenState(true);
        }
    };

    const handleReloadFileClick = () => {
        if (!file.isModified) {
            dispatch(fetchTextEditorFileContentsStarted(file));
        } else {
            setUndoAction(UndoAction.Reload);
            setUndoConfirmationModalOpenState(true);
        }
    };

    const handleShowUndoModal = () => {
        setUndoAction(UndoAction.Undo);
        setUndoConfirmationModalOpenState(true);
    }

    const handleUndoModalConfirmation = () => {
        if (currentUndoAction === UndoAction.Undo) {
            // do a normal UNDO
            dispatch(textEditorFileChangesReverted(file.filePath));
        } else if (currentUndoAction === UndoAction.Reload) {
            // do a UNDO, then RELOAD
            dispatch(textEditorFileChangesReverted(file.filePath));
            dispatch(textEditorFileClosedForEditing(file.filePath));
            dispatch(fetchTextEditorFileContentsStarted(file));
        } else if (currentUndoAction === UndoAction.Close) {
            // do a UNDO, then CLOSE FILE
            dispatch(textEditorFileChangesReverted(file.filePath));
            dispatch(textEditorFileClosedForEditing(file.filePath));
        }

        setUndoConfirmationModalOpenState(false);
    };

    const handleSave = () => {
        // currently UI is not disabled during save
        dispatch(saveTextEditorFileContentsStarted({ filePath: file.filePath, newContents: file.fileContents }));
    };

    return (
        <div>
            <TextContent>
                <Text component="h2">File: {file.filePath}</Text>
                {file.description && (<Text component="p">Description: {file.description}</Text>)}
            </TextContent>

            {appConfig && appConfig.showErrorsInUi && file.errorSavingFile !== null && (
                <Alert variant="danger" isInline title="An error occurred when trying to save the file">
                    <p>An error occurred when trying to save the file: {file.errorSavingFile}</p>
                    <p>Make sure that you allowed reuse of your password for privileged tasks during login or that your user account has an appropriate user role.</p>
                </Alert>
            )}

            <Toolbar className="text-editor-toolbar">
                <ToolbarContent alignment={{ default: 'alignLeft' }}>
                    <ToolbarItem>
                        <Button variant="secondary" onClick={handleReloadFileClick}>{file.isLoadingFile ? (<Spinner size="md" />) : (<SyncIcon />)} Reload file</Button>
                    </ToolbarItem>
                    <ToolbarItem className={`unlock-editing-button-container ${showTypingBlockedWarning ? 'notification-flash' : ''}`}>
                        <span>
                            <Button
                                variant={file.isModified ? 'tertiary' : 'secondary'}
                                isDisabled={!file.allowWrite}
                                onClick={handleToggleEditChange}
                                onMouseEnter={() => setHoverOnLockButton(true)}
                                onMouseLeave={() => setHoverOnLockButton(false)}
                                title={!file.allowWrite ? undefined : file.isModified ? 'Close file from edits' : 'Open file for editing'}
                            >
                                {(!file.allowWrite || (file.isModified && isHoveringLockButton) || (!file.isModified && !isHoveringLockButton)) ? (<LockIcon />) : (<UnlockIcon />)}
                            </Button>
                            <span> {file.allowWrite ? file.isModified ? 'File is open for editing' : 'File is closed from editing' : 'File cannot be opened for editing'}</span>
                        </span>
                    </ToolbarItem>
                    {file.isModified && (
                        <>
                            <ToolbarItem>
                                <Button
                                    variant="primary"
                                    isDisabled={!file.isModified}
                                    className="save-changes-button"
                                    onClick={handleSave}><SaveIcon /> Save changes</Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                <Button
                                    variant="tertiary"
                                    isDisabled={!file.isModified}
                                    onClick={handleShowUndoModal}><UndoIcon /> Undo changes</Button>
                            </ToolbarItem>
                        </>
                    )}
                </ToolbarContent>
            </Toolbar>

            <Modal
                isOpen={isUndoConfirmationModalOpen}
                onClose={() => setUndoConfirmationModalOpenState(false)}
                variant={ModalVariant.small}
                title={getUndoTexts(currentUndoAction).title}
                actions={[
                    <Button key="confirmUndo" variant="danger" onClick={handleUndoModalConfirmation}>{getUndoTexts(currentUndoAction).confirmText}</Button>,
                    <Button key="cancelUndo" variant="secondary" onClick={() => setUndoConfirmationModalOpenState(false)}>{getUndoTexts(currentUndoAction).cancelText}</Button>
                ]}
            >{getUndoTexts(currentUndoAction).text} All changes to this file that have not been saved will be lost.
            </Modal>

            <Form>
                <FormGroup fieldId={`text-editor-for-${file.filePath}`}>
                    <TextArea
                        id={`text-area-for-${file.filePath}`}
                        value={file.fileContents}
                        resizeOrientation='vertical'
                        rows={20}
                        className={`text-editor-area ${file.isModified ? 'editing' : ''}`}
                        spellCheck="false"
                        onChange={(v) => handleTextChange(v)}
                    />
                </FormGroup>
            </Form>

        </div>
    )
}

export default TextEditorForm;
