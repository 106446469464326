import { isArray } from "lodash-es"

export type AccessRights = {
    isInitialized: boolean;
    applauncher: string[];
    configui: string[];
    dashboard: string[];
    guide: string[];
    verify: string[];
    web_ui: string[];
};

export const ACCESS_RIGHTS_VALID = 'access';

function isValidAccessRightsJSON(json: any): json is AccessRights {
    // Validate JSON structure according to AccessRights type using lodash
    return (
        isArray(json.applauncher) &&
        isArray(json.configui) &&
        isArray(json.dashboard) &&
        isArray(json.guide) &&
        isArray(json.verify) &&
        isArray(json.web_ui)
    );
}

export function parseAccessRights(accessRightsJSON: any): AccessRights {
    if (!isValidAccessRightsJSON(accessRightsJSON)) {
        throw new Error('Invalid access rights JSON format');
    }

    const parsedAccessRights = accessRightsJSON as AccessRights;
    parsedAccessRights.isInitialized = true;
    return parsedAccessRights;
}

/** Default user access rights deny access to everything and is marked as not having been initialized. */
export function getInitialUserAccessRights(): AccessRights {
    return {
        isInitialized: false,
        applauncher: [],
        configui: [],
        dashboard: [],
        guide: [],
        verify: [],
        web_ui: [],
    };
}

export function getBypassedAccessRights(): AccessRights {
    return {
        isInitialized: true,
        applauncher: [],
        configui: [ACCESS_RIGHTS_VALID],
        dashboard: [],
        guide: [],
        verify: [],
        web_ui: [],
    };
}

export function isInitialAccessRights(accessRights: AccessRights): boolean {
    return !accessRights.isInitialized;
}

