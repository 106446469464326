import { call, put, take } from "typed-redux-saga";
import { WebConfigurationClient } from "../../web-apis/web-configuration";
import { appDeploymentInfoSet, helpDocumentsSet } from "./appConfigSlice";
import { appInitializationFailed } from "../appStatus/appStatusSlice";
import { HelpDocument } from "../../i18n";

export function* getAppDeploymentInfo() {
    try {
        const appDeploymentInfo = yield* call(WebConfigurationClient.getDeploymentConfigInfo);
        yield* put(appDeploymentInfoSet(appDeploymentInfo));
    }
    catch (error) {
        console.log('An error occurred when trying to retrieve app config:')
        console.error(error);
        yield* put(appInitializationFailed((error as Error).toString()));
    }
}

export function* getHelpDocuments() {
    let helpDocuments: HelpDocument[];
    try {
        helpDocuments = yield* call(WebConfigurationClient.getHelpDocuments);
    }
    catch (error) {
        // just don't show any help documents if something went wrong
        helpDocuments = [];
    }

    yield* put(helpDocumentsSet(helpDocuments));
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForAppDeploymentInfoSagas() {
    return [
        // currently nothing!
    ];
}
