import { call, put, select, takeEvery } from "typed-redux-saga";
import ConfigurationClient from "../../web-apis/configuration-client";
import { AccessRights, getBypassedAccessRights, getInitialUserAccessRights } from "./access-rights";
import { appConfigSelectors } from "../appConfig/appConfigSlice";
import { DeploymentMode, DisabledFeature, isFeatureDisabled } from "../appConfig/appDeploymentInfoTypes";
import { accessRightsSet, userSettingsFetched, userSettingsSet } from "./userSlice";
import UserSettings from "./user-settings";
import { get } from "lodash-es";

/** A one-time call for fetching user's access rights.
 * TODO: we might want to call this every now and then to invalidate long sessions as needed.
 * However this should be planned properly so we handle e.g. network glitches in a reasonable
 * fashion.
 */
export function* fetchUserAccessRightsSaga() {
    const client = new ConfigurationClient();
    let accessRights: AccessRights | null = null;
    let error = null;

    try {
        // Call the API to fetch access rights
        // bypass this if we're in local mode or the feature has been disabled in deployment config
        const config = yield* select(appConfigSelectors.selectAppDeploymentInfo);
        if (!config) { throw new Error('No deployment config file found'); }
        if (config.deploymentMode === DeploymentMode.Local || isFeatureDisabled(config, DisabledFeature.AccessRights)) {
            accessRights = getBypassedAccessRights();
        } else {
            accessRights = yield* call(() => client.fetchAndParseAccessRights());
        }
    } catch (err) {
        // Handle any errors during API call
        console.error(err);
        error = err;
        yield* put(accessRightsSet({ accessRights: getInitialUserAccessRights(), errorMessage: 'An error occurred when trying to retrieve access rights' }));
    }

    if (!error) {
        // If no error occurred during API call
        yield* put(accessRightsSet({ accessRights: accessRights }));
    }
}

function* fetchUserSettingsSaga() {
    const client = new ConfigurationClient();
    let userSettings: UserSettings | null = null;
    let error = null;

    try {
        userSettings = yield* call(async () => client.fetchUserSettingsAsync());
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error === null && userSettings !== null) {
        yield* put(userSettingsSet({ userSettings }));
    } else {
        // error case
        console.error('Was not able to fetch user settings');
        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        yield* put(userSettingsSet({ userSettings: null, errorMessage }));
    }
}


function* watchFetchUserSettingsSaga() {
    yield* takeEvery(userSettingsFetched, fetchUserSettingsSaga);
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForUserSagas() {
    return [
        // TODO: re-enable this watch once we actually use user settings
        // watchFetchUserSettingsSaga(),  
    ];
}