import React from 'react';
import { Button, ButtonProps, Modal, ModalVariant, Stack, TextArea } from '@patternfly/react-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appConfigSelectors } from '../store/appConfig/appConfigSlice';

interface RoiOperationModalProps {
    isOpen: boolean,
    onConfirm: (text: string) => void,
    onClose: () => void,
    variant?: ModalVariant,
    confirmLabel?: string,
    cancelLabel?: string,
    confirmVariant?: ButtonProps['variant'];
    operation?: string,
}

const RoiOperationModal = (props: React.PropsWithChildren<RoiOperationModalProps>) => {
    const { isOpen, onClose, onConfirm, confirmVariant, operation } = props;
    const { t } = useTranslation();

    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const [text, setText] = React.useState<string>(operation || '');

    const onConfirmClick = () => {
        onConfirm(text);
        onClose();
    }

    const actions = [
        <Button key="confirm" variant={confirmVariant || 'primary'} onClick={onConfirmClick}>{t('common.ok')}</Button>,

        <Button key="close" variant="secondary" onClick={onClose}>{t('common.cancel')}</Button>,
    ]

    if (deploymentInfo && deploymentInfo.operationsManualUrl) {
        actions.push(<a
            key="help"
            href={deploymentInfo.operationsManualUrl}
            target="_blank"
            rel="noopener noreferrer" // This is a security best practice
            className="pf-c-button"
        >
            {t('customizationPage.roiOperations.helpWithAdvancedOperations')}
        </a>);    }


    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            variant={ModalVariant.medium}
            title={t('common.advancedOperations')}
            actions={actions}
            showClose={false}
        >   
            <Stack hasGutter>
            </Stack>
                <TextArea width="auto" rows={4} className="advanced-operations-textarea" onChange={setText} defaultValue={text} resizeOrientation='vertical'/>
        </Modal>
    );
}

export default RoiOperationModal;
