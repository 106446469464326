import { AuthError } from "@azure/msal-browser";
import { has, isArray, isString, isError, get } from "lodash-es";

/// ******
/// This file contains custom Error (exception) classes and related helpers.
/// ******


export const isInstanceOfError = (exception: any): exception is Error => {
    return exception instanceof Error ? true : false;
}

export const isAuthError = (exception: any): exception is AuthError => {
    return typeof exception == 'object'
        && exception !== null
        && 'name' in exception && exception.name === 'AuthError'
        && 'errorMessage' in exception && typeof exception.errorMessage === 'string'
        && 'errorCode' in exception && typeof exception.errorCode === 'string';
}

/**
 * A serializable exception returned from backend related to validation of configuration objects.
 */
export type BackendValidationError = {

    /** Details of validation errors returned from backend. */
    readonly validationErrors: BackendValidationErrorDetail[];

    /** Original JSON representation of this validation error as received from backend. */
    readonly json: string;

    /** Error message */
    message: string | undefined;

}

/** Type guard for BackendValidationError */
export function isBackendValidationError(obj: any): obj is BackendValidationError {
    return has(obj, 'json') && isString(obj['json'])
        && (obj['message'] === undefined || isString(obj['message']))
        && isArray(obj['validationErrors'])
        && obj['validationErrors'].every(v => isBackendValidationErrorDetail(v));
}

/** Details related to a validation error received from backend. */
export type BackendValidationErrorDetail = {
    loc: (string | number)[];
    msg: string;
    type: string;
    ctx: any;
};

export const LOC_VALUE_BODY = 'body';

/** Type guard for BackendValidationErrorDetail */
export function isBackendValidationErrorDetail(obj: any): obj is BackendValidationErrorDetail {
    const castObj = obj as BackendValidationErrorDetail;
    return isArray(castObj.loc) && isString(castObj.msg) && isString(castObj.type);// && castObj.ctx !== undefined;
}

// TODO: does this need to be async?
/**
 * Converts backend validation error DTO into one or more UI location-specific error objects
 * that help pinpointing the exact location in UI of the context of the errors.
 * @param responseData the validation error JSON DTO from backend
 * @param originalJson the contouring customization JSON data object as a string that was sent to backend
 * @param message an optional error message that is attached to errors
 * @returns a BackendValidationError if input data matches expected DTO format or a generic 
 * Error otherwise
 */
export async function convertResponseToError(responseData: any, originalJson: string, message?: string): Promise<BackendValidationError | Error> {
    if (has(responseData, 'detail') && isArray(responseData.detail)) {
        const errors: BackendValidationErrorDetail[] = (responseData.detail.map((d: any) => isBackendValidationErrorDetail(d) ? d as BackendValidationErrorDetail : undefined))
            .filter((e: BackendValidationErrorDetail | undefined) => e !== undefined);
        if (errors.length > 0) {
            // return a BackendValidationError
            return {
                message: message,
                validationErrors: errors,
                json: originalJson,
            };
        }
    }
    return new Error(message);
}

/**
 * Try to extract an validation error message from a JSON DTO coming from backend,
 * or return a generic error message if parsing the DTO failed.
 */
export function getErrorMessageFromBackendValidationErrorJson(json: any): string {
    console.error(json);
    const errorMsg: string | undefined = isString(json.detail) ? json.detail : get(json, 'detail.toString()', undefined);
    return !!errorMsg ? `An error was received from backend: ${errorMsg}` : `An error was received from backend.`;
}
