
/**
 * Downloads content to a file on the client's machine.
 * @param content - The content to be written to the file.
 * @param fileName - The name to be given to the downloaded file.
 * @param fileType - The type of the file (e.g., 'text/plain', 'application/json').
 */
export const downloadFileToDisk = (jsonContent: string, fileName: string, fileType: string) => {
    // Create a temporary link element
    const element = document.createElement('a');

    // Create a Blob containing the content (converted to JSON) and set its type
    const file = new Blob([jsonContent], { type: fileType });

    // Set up the link element to download the file
    element.href = URL.createObjectURL(file);
    element.download = fileName;

    // Append the link to the document body
    document.body.appendChild(element);

    // Trigger a click on the link to start the download
    element.click();

    // Remove the link from the document body
    document.body.removeChild(element);
};
