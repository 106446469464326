import { isString, isNumber } from "lodash-es";
import { ensureFilenameHasDcmExtension } from "../../pages/customization/file-operations";
import { BackendValidationError, LOC_VALUE_BODY } from "../../util/errors";
import { naturalSort } from "../../util/sort";
import { FormValidationErrorType } from "../global-types/form-errors";
import { generateNewFormValidationError, ContouringFormValidationError, duplicateFormValidationError, BackendValidationErrorViewModel } from "../global-types/store-errors";
import { aeTitleRuleAdapter, ContouringSliceState, customizationBaseAdapter, customizationOutputAdapter, dicomAttributeRuleAdapter, dicomRuleAdapter, formValidationErrorAdapter, globalRoiCustomizationAdapter, modelAdapter, modelCustomizationsMetadataAdapter, roiCustomizationAdapter } from "./contouringSlice";
import { roiCustomizationMatchesGlobalRoi, createGlobalRoiCustomization, duplicateRoiCustomization, createNewContouringCustomizationOutput } from "./contouring-helpers";
import { generateNewId, duplicateModelCustomizationMetadataItem, UI_ID_ATTRIBUTE } from "../global-types/customization-helpers";
import { ContouringCustomizationOutput, ContouringRoi, GlobalContouringRoi, isContouringCustomizationOutput, isContouringRoi } from "./contouring-types";
import { Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DicomRule, DicomAttributeRule, isModel, isCustomizationBase, isOutputMetadata, isAeTitleRule, isDicomRule, isDicomAttributeRule, VALIDATION_MESSAGE_AE_TITLES_MUST_BE_UNIQUE, METADATA_FILENAME_ATTRIBUTE, CustomizationObjectType } from "../global-types/customization-types";
import { Update } from "@reduxjs/toolkit";


///////////////
//
// contouringConfig reducer helper functions
//
// This file contains helper functions for the contouringConfig reducer. These functions are not meant to be used outside of the slice file
// (apart from unit tests).
//
//////////////


/** A combination type of contouring-related customization types.
 * NOTE: GlobalRoiCustomization is intentionally excluded as it's not really a part of this hierarchy.
 */
type AnyCustomizationType = Model | CustomizationBase | ContouringCustomizationOutput | OutputMetadataItem | ContouringRoi |
    AeTitleRule | DicomRule | DicomAttributeRule;

export type DuplicatedIdMap = { sourceId: string, targetId: string };


/**
 * A helper function for updating a field in a contouring ROI customization object.
 *  
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param roiId Internal (entity adapter) ID of the ROI to update.
 * @param isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
 * @param changes The field(s) to update.
 * @param noGlobalRoiRecalculate If true don't recalculate global ROIs (which would most likely be affected by changes to any normal or global ROIs). Defaults to false/undefined
 * (in which case global ROIs ARE re-calculated). The only reasons to not re-calculate global ROIs would be either updates to only fields that are not used in global ROI
 * groupings (e.g. isIncluded) or if calling this function is part of a mass operation and global ROIs are calculated for the entire batch afterwards. Otherwise global
 * ROIs SHOULD always be recalculated or they will go out of sync!
 */
export const updateRoiCustomizationField = (state: ContouringSliceState, roiId: string, isGlobalRoi: boolean, changes: Partial<ContouringRoi> | Partial<GlobalContouringRoi>, noGlobalRoiRecalculate?: boolean) => {
    const roiIds = isGlobalRoi ? state.globalRoiCustomizations.entities[roiId]?.coveredRois : [roiId];

    if (roiIds) {
        // update 1 or more matching rois -- then mark matching models as modified, and also update the global customization entry if applicable
        // TODO: consider calculating here automatically if isModified is needed instead of having to pass it from all the callers
        roiCustomizationAdapter.updateMany(state.roiCustomizations, roiIds.map(roiId => ({ id: roiId, changes: changes })));
        if (!noGlobalRoiRecalculate) {
            if (isGlobalRoi) {
                // if we were modifying a global roi, let's also update that entry to match
                calculateGlobalRoiChangesForGlobalRoi(state, roiId, changes);
            } else {
                // if we're modifying a normal roi, we need to check if this modification removes or adds it from/to a global roi
                calculateGlobalRoiChangesForRegularRoi(state, roiId);
            }
        }
        setObjectAndAncestorsAsModified(state, roiIds, CustomizationObjectType.Roi);
    }
}

/** Checks if a just-modified roi customization belongs to a globalRoi, and removes it from it if they are no longer identical,
 * or vice versa. Note that GlobalRoi majority groupings are NOT re-calculated (e.g. if removing this roi from a global roi would
 * make some other group of roi customizations the majority group after this change, the global roi will nevertheless not changed)
 * as that would be confusing during use.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 */
export const calculateGlobalRoiChangesForRegularRoi = (state: ContouringSliceState, roiId: string) => {
    const roi = state.roiCustomizations.entities[roiId];

    if (roi !== undefined) {
        let isNewGlobalRoiNeeded = false;
        const globalRoiId = roi.globalRoiId;

        if (globalRoiId !== undefined) {
            // check if this roi needs to be removed from matching global roi
            const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
            if (globalRoi && !roiCustomizationMatchesGlobalRoi(roi, globalRoi)) {
                // we may need to create a new global roi after other operations if operations no longer match
                isNewGlobalRoiNeeded = globalRoi.operation !== roi.operation;

                const coveredRois = globalRoi.coveredRois.filter(rId => rId !== roiId);
                const excludedRois = isNewGlobalRoiNeeded ? globalRoi.excludedRois.filter(r => r !== roiId) :
                    globalRoi.excludedRois.includes(roiId) ? globalRoi.excludedRois : globalRoi.excludedRois.concat(roiId);
                if (!isNewGlobalRoiNeeded) {
                    // update previous global roi out of the roi object, unless we need potentially a completely new global roi
                    // in which case this operation is done later
                    roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { globalRoiId: undefined } });
                }

                // either update or remove the previous global roi
                if (coveredRois.length === 0 && excludedRois.length === 0) {
                    globalRoiCustomizationAdapter.removeOne(state.globalRoiCustomizations, globalRoiId);
                } else {
                    globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                        id: globalRoiId,
                        changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, coveredRois, excludedRois) }
                    });
                }
            }
        } else {
            // a new global roi is potentially needed (or the roi can be added to an existing one)
            isNewGlobalRoiNeeded = true;
        }

        if (isNewGlobalRoiNeeded) {
            // check if this roi could be added to an existing global roi
            const globalRoiWithSameOperation = Object.values(state.globalRoiCustomizations.entities).find(g => g?.operation === roi.operation);
            if (globalRoiWithSameOperation !== undefined) {
                // check that global roi and roi are equal
                const matchesGlobalRoi = roiCustomizationMatchesGlobalRoi(roi, globalRoiWithSameOperation);
                const coveredRois = matchesGlobalRoi ?
                    globalRoiWithSameOperation.coveredRois.includes(roiId) ? globalRoiWithSameOperation.coveredRois : globalRoiWithSameOperation.coveredRois.concat(roiId) :
                    globalRoiWithSameOperation.coveredRois.filter(rId => rId !== roiId);
                const excludedRois = !matchesGlobalRoi ?
                    globalRoiWithSameOperation.excludedRois.includes(roiId) ? globalRoiWithSameOperation.excludedRois : globalRoiWithSameOperation.excludedRois.concat(roiId) :
                    globalRoiWithSameOperation.excludedRois.filter(rId => rId !== roiId);
                roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { globalRoiId: matchesGlobalRoi ? globalRoiWithSameOperation.id : undefined } });
                globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                    id: globalRoiWithSameOperation.id,
                    changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoiWithSameOperation, coveredRois, excludedRois) }
                })
            } else {
                // no existing global roi -- create a new one
                const newGlobalRoi = createGlobalRoiCustomization([roi], [], true);
                roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { globalRoiId: newGlobalRoi.id } });
                globalRoiCustomizationAdapter.addOne(state.globalRoiCustomizations, newGlobalRoi);
            }
        }
    }
}

/** Checks if just performed updates to a global ROI would cause any changes in ROIs (e.g. if previously excluded ROIs would now be part
 * of this global ROI).
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
) */
export const calculateGlobalRoiChangesForGlobalRoi = (state: ContouringSliceState, globalRoiId: string, changes: Partial<GlobalContouringRoi>) => {
    const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
    if (globalRoi !== undefined) {
        // apply same updates as were applied for regular rois
        const globalRoiChanges = { ...changes };

        // check if any excluded regular rois now match global roi
        const changedRoiIds: string[] = [];
        for (const roiId of globalRoi.excludedRois) {
            const roi = state.roiCustomizations.entities[roiId];
            if (roi && roiCustomizationMatchesGlobalRoi(roi, globalRoi, changes) && !globalRoi.coveredRois.includes(roiId)) {
                changedRoiIds.push(roiId);
            }
        }

        if (changedRoiIds.length > 0) {
            const coveredChanges = globalRoi.coveredRois.concat(changedRoiIds);
            const excludedChanges = globalRoi.excludedRois.filter(rId => !changedRoiIds.includes(rId));
            const sortedChanges = getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, coveredChanges, excludedChanges);
            globalRoiChanges['coveredRois'] = sortedChanges.coveredRois;
            globalRoiChanges['excludedRois'] = sortedChanges.excludedRois;
            roiCustomizationAdapter.updateMany(state.roiCustomizations, changedRoiIds.map(rId => ({ id: rId, changes: { globalRoiId: globalRoiId } })));
        }


        globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, { id: globalRoiId, changes: { isModified: true, ...globalRoiChanges } });
    }
}

/**
 * Sorts ROIs within a Global ROI in alphabetical order.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param globalRoi The Global ROI for which the sorting is performed. This object is not mutated within this function, any additions or replacements to the props
 * must be done outside this function with the return data.
 * @param coveredRoiIds List of ROI IDs that the Global ROI covers.
 * @param excludedRoiIds List of ROI IDs that the Global ROI does not cover (but still belong under the same Global ROI).
 * @returns an object with coveredRois and excludedRois props sorted in alphabetical order.
 */
export const getGlobalRoiItemsInAlphabeticalOrder = (state: ContouringSliceState, globalRoi: GlobalContouringRoi, coveredRoiIds: string[], excludedRoiIds: string[])
    : { coveredRois: string[], excludedRois: string[] } => {
    const coveredRois = coveredRoiIds.map(cId => state.roiCustomizations.entities[cId]);
    if (coveredRois.some(c => c.customizationOutputId === undefined)) { throw new Error(`Global roi ${globalRoi.id} covered ROIs list contains one or more undefined rois`); }
    const excludedRois = excludedRoiIds.map(cId => state.roiCustomizations.entities[cId]);
    if (excludedRois.some(c => c.customizationOutputId === undefined)) { throw new Error(`Global roi ${globalRoi.id} excludedRois ROIs list contains one or more undefined rois`); }

    const coveredRoisWithOutputs = coveredRois.map(c => ({ roiId: c.id, output: state.customizationOutputs.entities[c.customizationOutputId!] }));
    if (coveredRoisWithOutputs.some(c => c.output === undefined)) { throw new Error(`No output found for a covered roi in ${globalRoi.id}`); }
    const coveredRoisWithOutputsAndBases = (coveredRoisWithOutputs).map(c => ({ roiId: c.roiId, output: c.output!, base: state.customizationBases.entities[c.output!.modelCustomizationBaseId] }));
    if (coveredRoisWithOutputsAndBases.some(c => c.base === undefined)) { throw new Error(`No base found for a covered roi in ${globalRoi.id}`); }
    const coveredRoisWithOutputsAndBasesAndModels = (coveredRoisWithOutputsAndBases).map(c => ({ roiId: c.roiId, output: c.output!, base: c.base!, model: state.models.entities[c.base!.modelId] }));
    if (coveredRoisWithOutputsAndBasesAndModels.some(c => c.model === undefined)) { throw new Error(`No model found for a covered roi in ${globalRoi.id}`); }
    const toBeSortedCoveredRois = coveredRoisWithOutputsAndBasesAndModels.map(c => ({ roiId: c.roiId, label: `${c.model!.modelName}.${c.base.customizationName}.${c.output.filename}` }));

    const excludedRoisWithOutputs = excludedRois.map(c => ({ roiId: c.id, output: state.customizationOutputs.entities[c.customizationOutputId!] }));
    if (excludedRoisWithOutputs.some(c => c.output === undefined)) { throw new Error(`No output found for an excluded roi in ${globalRoi.id}`); }
    const excludedRoisWithOutputsAndBases = (excludedRoisWithOutputs).map(c => ({ roiId: c.roiId, output: c.output!, base: state.customizationBases.entities[c.output!.modelCustomizationBaseId] }));
    if (excludedRoisWithOutputsAndBases.some(c => c.base === undefined)) { throw new Error(`No base found for an excluded roi in ${globalRoi.id}`); }
    const excludedRoisWithOutputsAndBasesAndModels = (excludedRoisWithOutputsAndBases).map(c => ({ roiId: c.roiId, output: c.output!, base: c.base!, model: state.models.entities[c.base!.modelId] }));
    if (excludedRoisWithOutputsAndBasesAndModels.some(c => c.model === undefined)) { throw new Error(`No model found for an excluded roi in ${globalRoi.id}`); }
    const toBeSortedExcludedRois = excludedRoisWithOutputsAndBasesAndModels.map(c => ({ roiId: c.roiId, label: `${c.model!.modelName}.${c.base.customizationName}.${c.output.filename}` }));

    const sortedCoveredRoiIds = naturalSort(toBeSortedCoveredRois, 'label').map(c => c.roiId);
    const sortedExcludedRoiIds = naturalSort(toBeSortedExcludedRois, 'label').map(c => c.roiId);

    return { coveredRois: sortedCoveredRoiIds, excludedRois: sortedExcludedRoiIds };
}

/**
 * A helper function for asserting that given object is not null or undefined and for printing
 * a nice error message if not so.
 * @param obj The object that is being asserted for being not null and not undefined.
 * @param typeName Name of the type of the object being asserted. This is only used in the assertion error message.
 * @param id Optional ID of the object being asserted. This is only used in the assertion error message.
 */
export function doAssert<T>(obj: T, typeName: string, id?: string): asserts obj is NonNullable<T> {
    if (obj === undefined || obj === null) { throw new Error(`Could not find a valid ${typeName} object${id ? ` with given ID '${id}'` : ''}`); }
};

/** 
 * Find and returns the immediate parent object for a segmentation model customization object if one is found from the redux store.
 * Throws otherwise. 
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param obj The object for which a parent is attempted to be found.
 * */
export const getParent = (state: ContouringSliceState, obj: AnyCustomizationType): AnyCustomizationType => {
    if (isModel(obj)) {
        throw new Error('Segmentation models thenselves have no parent objects!');
    }

    if (isCustomizationBase(obj)) {
        const model = Object.values(state.models.entities).find(m => m?.customizations.includes(obj.id));
        doAssert(model, 'model');
        return model;
    }

    if (isContouringCustomizationOutput(obj)) {
        const base = Object.values(state.customizationBases.entities).find(c => c?.outputs.includes(obj.id));
        doAssert(base, 'customization base');
        return base;
    }

    if (isOutputMetadata(obj)) {
        const output = obj.modelCustomizationOutputId ?
            state.customizationOutputs.entities[obj.modelCustomizationOutputId] :
            Object.values(state.customizationOutputs.entities).find(o => o?.metadata.includes(obj.id));
        doAssert(output, 'customization output');
        return output;
    }

    if (isContouringRoi(obj)) {
        const output = obj.customizationOutputId ?
            state.customizationOutputs.entities[obj.customizationOutputId] :
            Object.values(state.customizationOutputs.entities).find(o => o?.metadata.includes(obj.id));
        doAssert(output, 'customization output');
        return output;
    }

    if (isAeTitleRule(obj)) {
        const base = obj.modelCustomizationBaseId ?
            state.customizationBases.entities[obj.modelCustomizationBaseId] :
            Object.values(state.customizationBases.entities).find(c => c?.aeTitleRules.includes(obj.id));
        doAssert(base, 'customization base');
        return base;
    }

    if (isDicomRule(obj)) {
        const base = obj.modelCustomizationBaseId ?
            state.customizationBases.entities[obj.modelCustomizationBaseId] :
            Object.values(state.customizationBases.entities).find(c => c?.dicomRules.includes(obj.id));
        doAssert(base, 'customization base');
        return base;
    }

    if (isDicomAttributeRule(obj)) {
        const dicomRule = state.dicomRules.entities[obj.parentDicomRuleId];
        doAssert(dicomRule, 'DICOM rule');
        return dicomRule;
    }

    throw new Error('Unsupported type');
};

/**
 * Marks the given customization view model object and all its parent objects as 'modified'.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param objectIds List of object IDs for which upwards ancestor travelsal will be performed. These objects and all their ancestors will
 * be marked as having been modified. All entities specified in this argument must be of the same type.
 * @param objectType The type of the objects specified in the objectIds field as this is not easily deduced otherwise. All entities
 * specified in objectIds must be of the same type.
 */
export const setObjectAndAncestorsAsModified = (state: ContouringSliceState, objectIds: string[], objectType: CustomizationObjectType) => {

    // these IDs will all be marked as modified in one go
    const targets: { [parentType in CustomizationObjectType]: string[] } = {
        [CustomizationObjectType.None]: [],
        [CustomizationObjectType.Model]: [],
        [CustomizationObjectType.CustomizationBase]: [],
        [CustomizationObjectType.CustomizationOutput]: [],
        [CustomizationObjectType.Metadata]: [],
        [CustomizationObjectType.Roi]: [],
        [CustomizationObjectType.Target]: [],
        [CustomizationObjectType.GlobalRoi]: [],
        [CustomizationObjectType.AeTitleRule]: [],
        [CustomizationObjectType.DicomRule]: [],
        [CustomizationObjectType.DicomAttributeRule]: [],
        [CustomizationObjectType.TriggerRule]: [],
        [CustomizationObjectType.CodingScheme]: [],
        [CustomizationObjectType.PhysicalProperties]: [],
    };

    // walk through the entire hierarchy in reverse order & set everything touched as modified

    // set initial child objects
    targets[objectType] = objectIds;

    for (const id of targets[CustomizationObjectType.DicomAttributeRule]) {
        const dicomAttributeRule = state.dicomAttributeRules.entities[id];
        doAssert(dicomAttributeRule, 'DICOM attribute rule', id);
        targets[CustomizationObjectType.DicomRule].push(getParent(state, dicomAttributeRule).id);
    }

    for (const id of targets[CustomizationObjectType.DicomRule]) {
        const dicomRule = state.dicomRules.entities[id];
        doAssert(dicomRule, 'DICOM rule', id);
        targets[CustomizationObjectType.CustomizationBase].push(getParent(state, dicomRule).id);
    }

    for (const id of targets[CustomizationObjectType.AeTitleRule]) {
        const aeTitleRule = state.aeTitleRules.entities[id];
        doAssert(aeTitleRule, 'AE title rule rule', id);
        targets[CustomizationObjectType.CustomizationBase].push(getParent(state, aeTitleRule).id);
    }

    for (const id of targets[CustomizationObjectType.GlobalRoi]) {
        const globalRoi = state.globalRoiCustomizations.entities[id];
        doAssert(globalRoi, 'global ROI customization', id);
        // global roi special case: add all covered regular roi items
        targets[CustomizationObjectType.Roi].push(...globalRoi.coveredRois);
    }

    for (const id of targets[CustomizationObjectType.Roi]) {
        const roi = state.roiCustomizations.entities[id];
        doAssert(roi, 'ROI customization', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, roi).id);
    }

    for (const id of targets[CustomizationObjectType.Metadata]) {
        const metadata = state.modelCustomizationsMetadata.entities[id];
        doAssert(metadata, 'customization metadata', id);
        targets[CustomizationObjectType.CustomizationOutput].push(getParent(state, metadata).id);
    }

    for (const id of targets[CustomizationObjectType.CustomizationOutput]) {
        const output = state.customizationOutputs.entities[id];
        doAssert(output, 'customization output', id);
        targets[CustomizationObjectType.CustomizationBase].push(getParent(state, output).id);
    }

    for (const id of targets[CustomizationObjectType.CustomizationBase]) {
        const customization = state.customizationBases.entities[id];
        doAssert(customization, 'customization base', id);
        targets[CustomizationObjectType.Model].push(getParent(state, customization).id);
    }


    // apply updates

    if (targets[CustomizationObjectType.Model].length > 0) {
        modelAdapter.updateMany(state.models, targets[CustomizationObjectType.Model].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationBase].length > 0) {
        customizationBaseAdapter.updateMany(state.customizationBases, targets[CustomizationObjectType.CustomizationBase].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.CustomizationOutput].length > 0) {
        customizationOutputAdapter.updateMany(state.customizationOutputs, targets[CustomizationObjectType.CustomizationOutput].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Metadata].length > 0) {
        modelCustomizationsMetadataAdapter.updateMany(state.modelCustomizationsMetadata, targets[CustomizationObjectType.Metadata].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.Roi].length > 0) {
        roiCustomizationAdapter.updateMany(state.roiCustomizations, targets[CustomizationObjectType.Roi].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.GlobalRoi].length > 0) {
        globalRoiCustomizationAdapter.updateMany(state.globalRoiCustomizations, targets[CustomizationObjectType.GlobalRoi].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.AeTitleRule].length > 0) {
        aeTitleRuleAdapter.updateMany(state.aeTitleRules, targets[CustomizationObjectType.AeTitleRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomRule].length > 0) {
        dicomRuleAdapter.updateMany(state.dicomRules, targets[CustomizationObjectType.DicomRule].map(id => ({ id, changes: { isModified: true } })));
    }

    if (targets[CustomizationObjectType.DicomAttributeRule].length > 0) {
        dicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, targets[CustomizationObjectType.DicomAttributeRule].map(id => ({ id, changes: { isModified: true } })));
    }
}

/**
 * Guarantees that covered and excluded ROIs in a Global ROI are in alphabetical order.
 */
export const ensureGlobalRoiItemsAreInAlphabeticalOrder = (state: ContouringSliceState, globalRoiId: string) => {
    const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
    if (globalRoi === undefined) {
        throw new Error(`Could not retrieve global roi ${globalRoiId}`);
    }

    const changes = getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, globalRoi.coveredRois, globalRoi.excludedRois);

    globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, { id: globalRoiId, changes: { ...changes } });
}

export type RemovedOutputEntities = {
    globalRoiChanges: Update<GlobalContouringRoi, string>[];
    roiIdsToBeRemoved: string[];
    metadataIdsToBeRemoved: string[];
};

/** Collects which store entities need to be removed and updated when a customization output is removed. */
export const collectCustomizationOutputEntitiesFromStoreForRemoval = (state: ContouringSliceState, output: ContouringCustomizationOutput | undefined, customizationBaseId: string): RemovedOutputEntities => {

    const globalRoiChanges: Update<GlobalContouringRoi, string>[] = [];
    const roiIdsToBeRemoved: string[] = [];
    const metadataIdsToBeRemoved: string[] = [];

    // update global rois before the actual rois are removed
    if (output === undefined) {
        throw new Error(`An invalid customization output was retrieved for customization base ${customizationBaseId}`);
    }

    metadataIdsToBeRemoved.push(...output.metadata);

    for (const roiId of output.rois) {
        const roi = state.roiCustomizations.entities[roiId];
        if (roi === undefined) {
            throw new Error(`Could not retrieve roi ${roiId} for output ${output.id} in customization base ${customizationBaseId}`);
        }

        roiIdsToBeRemoved.push(roiId);

        const globalRoi = roi.globalRoiId ?
            state.globalRoiCustomizations.entities[roi.globalRoiId] :
            Object.values(state.globalRoiCustomizations.entities).find(r => r?.excludedRois.includes(roiId));
        if (globalRoi) {
            globalRoiChanges.push({
                id: globalRoi.id, changes: {
                    excludedRois: globalRoi.excludedRois.filter(r => r !== roiId),
                    coveredRois: globalRoi.coveredRois.filter(r => r !== roiId),
                }
            });
        }
    }

    return {
        globalRoiChanges,
        roiIdsToBeRemoved,
        metadataIdsToBeRemoved
    };
};



/**
 * Validates that the whole contouring customization configuration has no AE Title
 * rules with repeated action values -- i.e. there should be no multiple identical
 * AE Title rules in the configuration (even if they call different models).
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param action The action value to validate for uniqueness.
 */
export const validateAeTitleRuleActionUniqueness = (state: ContouringSliceState, action: string) => {
    const aeTitleRulesWithSameAction = Object.values(state.aeTitleRules.entities).filter(ae => ae?.action === action);
    // values in this array should actually never be undefined but EntityState collections are being uncooperative here
    if (aeTitleRulesWithSameAction.length > 1) {
        aeTitleRulesWithSameAction.forEach(ae => {
            if (ae !== undefined) {
                ae.isValid = false;
                ae.validationMessage = VALIDATION_MESSAGE_AE_TITLES_MUST_BE_UNIQUE;
            }
        });
    } else if (aeTitleRulesWithSameAction[0] !== undefined) {
        aeTitleRulesWithSameAction[0].isValid = true;
        aeTitleRulesWithSameAction[0].validationMessage = undefined;
    }
}

/** 
 * Perform full validation on DICOM rule and related DICOM attribute rules. Set validation states to the store objects accordingly.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 */
export const validateDicomRuleAndAttributes = (state: ContouringSliceState, dicomRuleId: string): boolean => {

    const dicomRule = state.dicomRules.entities[dicomRuleId];

    if (!dicomRule) { return true; }

    // collect dicom attributes for this rule
    const dicomAttributeRules = dicomRule.dicomAttributes.map(aId => state.dicomAttributeRules.entities[aId]);

    // do the validation and collect passed and failed entity IDs:

    // contains dicom rule ids
    const hasAtLeastOneAttributeResults = validateDicomRuleHasAtLeastOneAttribute(dicomRule);

    // these two contain dicom ATTRIBUTE ids
    const attributeNameNotEmptyAttributeRules = validateDicomAttributeNamesAreNotEmpty(dicomAttributeRules);
    const attributeNameNotUniqueAttributeRules = validateAllDicomAttributeNamesHaveNoDuplicates(dicomAttributeRules);
    const allFailedDicomAttributeRules = attributeNameNotEmptyAttributeRules.failedIds.concat(attributeNameNotUniqueAttributeRules.failedIds);

    const isValidOverall = (hasAtLeastOneAttributeResults.failedIds.length + allFailedDicomAttributeRules.length) === 0;

    // collect existing validation errors for delta
    const existingErrors = Object.values(state.formValidationErrors.entities)
        .filter(e => [
            FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute,
            FormValidationErrorType.DicomAttributeNameMustNotBeEmpty,
            FormValidationErrorType.DicomAttributesMustNotRepeat
        ].includes(e.errorType));

    // handle dicom rule validation error (if any)
    // NOTE: this bit needs to be rewritten once there are more than one validation rules for dicom rules themselves!
    const dicomRulePassed = hasAtLeastOneAttributeResults.failedIds.length === 0;
    const existingDicomRuleError = existingErrors.find(e => e.errorType === FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute && e.itemId === dicomRuleId);
    if (dicomRulePassed && existingDicomRuleError !== undefined) {
        // remove existing error
        formValidationErrorAdapter.removeOne(state.formValidationErrors, existingDicomRuleError.id);
    } else if (!dicomRulePassed && !existingDicomRuleError) {
        // add a new error
        formValidationErrorAdapter.addOne(
            state.formValidationErrors,
            generateNewFormValidationError(FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute, dicomRuleId, CustomizationObjectType.DicomRule));
    }

    // handle dicom attribute errors
    const existingDicomAttributeErrors = existingErrors
        .filter(e => e.errorType !== FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute
            && dicomRule.dicomAttributes.includes(e.itemId));
    for (const dicomAttributeId of dicomRule.dicomAttributes) {
        // we need to collect old validation errors that we need to remove
        const existingErrorIdsToRemove: string[] = [];
        const existingErrorsForThisAttribute = existingDicomAttributeErrors.filter(e => e.itemId === dicomAttributeId);
        const existingEmptyNameErrors = existingErrorsForThisAttribute.filter(e => e.errorType === FormValidationErrorType.DicomAttributeNameMustNotBeEmpty);
        const existingDuplicateNamesErrors = existingErrorsForThisAttribute.filter(e => e.errorType === FormValidationErrorType.DicomAttributesMustNotRepeat);

        // empty name error overrides duplicate name error
        if (attributeNameNotEmptyAttributeRules.failedIds.includes(dicomAttributeId)) {
            existingErrorIdsToRemove.push(...existingDuplicateNamesErrors.map(e => e.id));
            if (existingEmptyNameErrors.length === 0) {
                formValidationErrorAdapter.addOne(state.formValidationErrors, generateNewFormValidationError(
                    FormValidationErrorType.DicomAttributeNameMustNotBeEmpty, dicomAttributeId, CustomizationObjectType.DicomAttributeRule
                ));
            }
        } else if (attributeNameNotUniqueAttributeRules.failedIds.includes(dicomAttributeId)) {
            existingErrorIdsToRemove.push(...existingEmptyNameErrors.map(e => e.id));
            if (existingDuplicateNamesErrors.length === 0) {
                formValidationErrorAdapter.addOne(state.formValidationErrors, generateNewFormValidationError(
                    FormValidationErrorType.DicomAttributesMustNotRepeat, dicomAttributeId, CustomizationObjectType.DicomAttributeRule
                ));
            }
        } else {
            // this attribute passed validation
            existingErrorIdsToRemove.push(...(existingEmptyNameErrors.map(e => e.id).concat(existingDuplicateNamesErrors.map(e => e.id))));
        }

        // remove any existing validation errors tagged as such
        if (existingErrorIdsToRemove.length > 0) {
            formValidationErrorAdapter.removeMany(state.formValidationErrors, existingErrorIdsToRemove);
        }
    }

    // update isValid props
    dicomRuleAdapter.updateOne(state.dicomRules, { id: dicomRuleId, changes: { isValid: isValidOverall } });
    dicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, dicomRule.dicomAttributes.map(id => ({ id: id, changes: { isValid: !allFailedDicomAttributeRules.includes(id) } })));

    return isValidOverall;
}

export type DicomAttributeValidationResults = { passedIds: string[]; failedIds: string[]; };

/** Checks that given dicom rule has at least one attribute. Returns a list of item ids which fail and which pass the validation. */
const validateDicomRuleHasAtLeastOneAttribute = (dicomRule: DicomRule): DicomAttributeValidationResults => {
    const validationResults: DicomAttributeValidationResults = { passedIds: [], failedIds: [] };

    const dicomRuleId = dicomRule.id;

    const dicomAttributeRuleIds = dicomRule.dicomAttributes;
    if (dicomAttributeRuleIds.length < 1) {
        validationResults.failedIds.push(dicomRuleId);
    } else {
        validationResults.passedIds.push(dicomRuleId);
    }

    return validationResults;
}

/** Checks that given dicom attribute's name is not empty. Returns a list of item ids which fail and which pass the validation. */
const validateDicomAttributeNamesAreNotEmpty = (dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults => {
    const validationResults: DicomAttributeValidationResults = { passedIds: [], failedIds: [] };

    // this must be done for every dicom attribute rule in the dicom rule in one go,
    // not only just the one we just edited. otherwise we will have cases where other
    // validation rules will erroneously supercede this validation case when a dicom
    // attribute is being removed
    for (const attributeRule of dicomAttributeRules) {
        if (attributeRule.attribute !== undefined && attributeRule.attribute.trim() === '') {
            validationResults.failedIds.push(attributeRule.id);
        } else {
            validationResults.passedIds.push(attributeRule.id);
        }
    }

    return validationResults;
}

/** Validates that the dicom attribute specified in a dicom attribute rule is unique within its whole dicom rule
 * (i.e. no duplicates). Returns a list of item ids which fail and which pass the validation.
 */
const validateAllDicomAttributeNamesHaveNoDuplicates = (dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults => {
    const validationResults: DicomAttributeValidationResults = { passedIds: [], failedIds: [] };

    // first, collect all dicom attribute names and store their IDs
    const duplicateNames: { [attributeName: string]: string[] } = {};
    for (const attributeRule of dicomAttributeRules) {
        duplicateNames[attributeRule.attribute] = duplicateNames[attributeRule.attribute] || [];
        duplicateNames[attributeRule.attribute].push(attributeRule.id);
    }

    // then, invalidate all rules that share attribute names, and validate everything else
    for (const attributeName of Object.keys(duplicateNames)) {
        if (duplicateNames[attributeName].length > 1) {
            // set all dicom attribute IDs as invalid
            validationResults.failedIds.push(...duplicateNames[attributeName]);
        } else {
            // set as valid
            validationResults.passedIds.push(duplicateNames[attributeName][0]);
        }
    }

    return validationResults;
}

/** Returns all store IDs for FormValidationError objects matching given itemId (e.g. roiId) */
export const getFormValidationErrorIdsForItem = (state: ContouringSliceState, itemId: string): string[] => {
    const validationErrors = Object.values(state.formValidationErrors.entities).filter(e => e?.itemId === itemId);
    return validationErrors.map(e => e.id);
}

/** Returns all store IDs for FormValidationError objects matching given itemIds (e.g. roiId) */
export const getFormValidationErrorIdsForItems = (state: ContouringSliceState, itemIds: string[]): string[] => {
    const validationErrors = Object.values(state.formValidationErrors.entities).filter(e => itemIds.includes(e.itemId));
    return validationErrors.map(e => e.id);
}

/** Duplicate FormValidationErrors for given itemIds */
export const duplicateFormValidationErrors = (state: ContouringSliceState, duplicatedIds: DuplicatedIdMap[]): ContouringFormValidationError[] => {

    const sourceIds = duplicatedIds.map(d => d.sourceId);
    const errorsToDuplicate = Object.values(state.formValidationErrors.entities).filter(e => sourceIds.includes(e.itemId));

    const duplicatedErrors: ContouringFormValidationError[] = [];
    duplicatedErrors.push(...errorsToDuplicate.map(e => duplicateFormValidationError(e, duplicatedIds.find(d => d.sourceId === e.itemId)!.targetId)));

    return duplicatedErrors;
}

export type FormValidationErrorCalculationResults = { validationErrorIdsToRemove: string[], validationErrorsToAdd: ContouringFormValidationError[] };

/** Calculates form validation errors for given customization output.
 * TODO: once we have more types of validation errors consider splitting
 * this into smaller functions, and consider whether all of them need to be
 * checked here.
 */
const calculateFormValidationErrorsForCustomizationOutput = (state: ContouringSliceState, outputId: string): FormValidationErrorCalculationResults => {
    const errorIdsToRemove: string[] = [];
    const errorsToAdd: ContouringFormValidationError[] = [];

    const output = state.customizationOutputs.entities[outputId];
    if (!output) { throw new Error(`Customization output (${outputId}) is undefined`); }
    const rois = output.rois.map(rId => state.roiCustomizations.entities[rId]);

    const existingErrors = Object.values(state.formValidationErrors.entities).filter(e => output.rois.includes(e?.itemId));
    const existingEmptyNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.RoiNameIsEmpty);
    const existingDuplicateNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.DuplicateRoiNamesInOutput);

    // collect existing duplicate name errors nearby so we can use them when doing a delta within this output
    // map potential INCLUDED duplicate roiIds (string array value) under the potential duplicate name (string key)
    // this is needed for the duplicate name check resolution later
    const duplicateNames: { [name: string]: string[] } = {};
    for (const roi of rois) {
        if (roi.isIncluded) {
            duplicateNames[roi.name] = duplicateNames[roi.name] || [];
            duplicateNames[roi.name].push(roi.id);
        }
    }

    // check all the validation errors for ROIs in one loop
    for (const roi of rois) {

        let dontAddNewValidationErrors = false;


        // check for empty names rois (RoiNameIsEmpty)
        const matchingExistingEmptyNameErrors = existingEmptyNameErrors.filter(e => e.itemId === roi.id);
        const hasMatchingEmptyNameErrors = matchingExistingEmptyNameErrors.length > 0;
        const isNameEmpty = !roi.name.trim();

        // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
        if (isNameEmpty && !hasMatchingEmptyNameErrors && !dontAddNewValidationErrors) {
            // create a new error
            errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.RoiNameIsEmpty, roi.id, CustomizationObjectType.Roi));
        } else if (dontAddNewValidationErrors || (!isNameEmpty && hasMatchingEmptyNameErrors)) {
            // remove existing errors
            errorIdsToRemove.push(...matchingExistingEmptyNameErrors.map(m => m.id));
        }

        // if this name was empty then don't add any new validation errors (but we still need to check if any can be removed)
        if (isNameEmpty) {
            dontAddNewValidationErrors = true;
        }


        // check for duplicate names within (included) rois in the same output (DuplicateRoiNamesInOutput)
        // now loop through the ROIs again and do a delta against existing errors, creating new errors and marking existing ones to be removed as needed
        const matchingExistingDuplicateNameErrors = existingDuplicateNameErrors.filter(e => e.itemId === roi.id);
        const hasMatchingDuplicateNameErrors = matchingExistingDuplicateNameErrors.length > 0;
        const isNameDuplicated = duplicateNames[roi.name] && duplicateNames[roi.name].length > 1;

        // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
        if (isNameDuplicated && !hasMatchingDuplicateNameErrors && !dontAddNewValidationErrors) {
            // create a new error
            errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.DuplicateRoiNamesInOutput, roi.id, CustomizationObjectType.Roi));
        } else if (dontAddNewValidationErrors || (!isNameDuplicated && hasMatchingDuplicateNameErrors)) {
            // remove existing errors
            errorIdsToRemove.push(...matchingExistingDuplicateNameErrors.map(m => m.id));
        }
    }

    return {
        validationErrorIdsToRemove: errorIdsToRemove,
        validationErrorsToAdd: errorsToAdd,
    }
}

/** Perform form validation for given customization output and apply results to state. */
export const performFormValidationForOutput = (state: ContouringSliceState, outputId: string) => {
    const results = calculateFormValidationErrorsForCustomizationOutput(state, outputId);

    // add new errors
    if (results.validationErrorsToAdd.length > 0) {
        formValidationErrorAdapter.addMany(state.formValidationErrors, results.validationErrorsToAdd);
    }

    // remove outdated errors
    if (results.validationErrorIdsToRemove.length > 0) {
        formValidationErrorAdapter.removeMany(state.formValidationErrors, results.validationErrorIdsToRemove);
    }
}

/** Converts raw(-ish) backend validation error objects into matching view model objects that can be used in UI
 * to pinpoint the exact location of the validation error.
 */
export const convertBackendValidationErrorToViewModels = (state: ContouringSliceState, error: BackendValidationError): BackendValidationErrorViewModel[] => {
    const { json } = error;

    if (json === undefined) {
        throw new Error('Could not retrieve proper error messages as given JSON is undefined');
    }

    const jsonData = JSON.parse(json);
    const storeErrors: BackendValidationErrorViewModel[] = [];

    error.validationErrors.forEach(e => {
        let cursor = jsonData;
        let cursorPath = '';
        let targetType = CustomizationObjectType.None;
        let target: { id: string, type: CustomizationObjectType } | undefined = undefined;
        for (const locValue of e.loc) {
            if (locValue === LOC_VALUE_BODY) {
                cursor = jsonData;
                cursorPath = 'body';
            } else if (targetType === CustomizationObjectType.CodingScheme) {
                // special case handling for fma id entries
                break;
            }
            else {
                cursor = cursor[locValue];
                cursorPath += `/${locValue}`;
                if (cursor === undefined) {
                    if (target && target.type === CustomizationObjectType.AeTitleRule && locValue === 'action') {
                        // special case for ae titles where we might have two 'action' nodes
                        // sequentially -- in this case just let the code fall through
                    } else {
                        console.error(target)
                        throw new Error(`Could not find JSON node matching error message loc: ${cursorPath}`);
                    }
                }
            }

            // if locValue is a string, we need to figure out what the next type of object will be
            if (isString(locValue)) {
                targetType = getTargetType(locValue);
            }

            // if locValue is a number, we need to figure out which exact object of target type we're dealing with
            else if (isNumber(locValue)) {
                target = getTargetObject(state, targetType, cursor, target, cursorPath);
            }
        }

        if (target) {

            // replace dicom attributes with their parent dicom rules for now
            if (target.type === CustomizationObjectType.DicomAttributeRule) {
                const dicomAttribute = state.dicomAttributeRules.entities[target.id];
                if (dicomAttribute) {
                    target.id = dicomAttribute.parentDicomRuleId;
                    target.type = CustomizationObjectType.DicomRule;
                }
            }

            const field = e.loc && e.loc.length > 0 ? e.loc[e.loc.length - 1] as string : undefined;

            const validationError = {
                id: target.id,
                type: target.type,
                message: e.msg,
                detail: e.type,
                field: field,
                ctx: JSON.stringify(e.ctx),
            }

            storeErrors.push(validationError);

            if (target.type === CustomizationObjectType.Roi) {
                // create a copy for global roi if there's a matching one (and one hasn't been made already)
                const globalRoi = Object.values(state.globalRoiCustomizations.entities).find(gr => gr?.coveredRois.includes(target!.id));
                if (globalRoi && !storeErrors.find(e => e.id === globalRoi.id)) {
                    storeErrors.push({ ...validationError, id: globalRoi.id });
                }
            }
        }
    });

    return storeErrors;
}

/** Helper function for convertBackendValidationErrorToViewModels that returns customization object type matching the row currently being parsed. */
const getTargetType = (loc: string | number): CustomizationObjectType => {
    if (!isString(loc)) {
        return CustomizationObjectType.None;
    }

    switch (loc) {
        case 'rois':
            return CustomizationObjectType.Roi;
        case 'metadata':
            return CustomizationObjectType.Metadata;
        case 'files':
            return CustomizationObjectType.CustomizationOutput;
        case 'customizations':
            return CustomizationObjectType.CustomizationBase;
        case 'triggers':
            return CustomizationObjectType.TriggerRule;
        case 'body':
            return CustomizationObjectType.Model;
        case 'fma_id':
            return CustomizationObjectType.CodingScheme;
        case 'physical_properties':
            return CustomizationObjectType.PhysicalProperties;
        default:
            return CustomizationObjectType.None;
    }
}

/** Helper function for convertBackendValidationErrorToViewModels for parsing a python-based backend error message iteratively. */
const getTargetObject = (state: ContouringSliceState, type: CustomizationObjectType, jsonCursor: any, target: { id: string, type: CustomizationObjectType } | undefined, cursorPath: string) => {
    const currentId = jsonCursor[UI_ID_ATTRIBUTE];
    switch (type) {
        case CustomizationObjectType.Model:
            {
                const model = state.models.entities[currentId];
                if (model === undefined) { throw new Error(`Could not find matching segmentation model for validation error json node ${cursorPath}`); }
                return { id: model.id, type };
            }

        case CustomizationObjectType.TriggerRule:
            {
                if (jsonCursor['action'] !== undefined) {
                    const aeTitleRule = state.aeTitleRules.entities[currentId];
                    if (aeTitleRule) {
                        return { id: aeTitleRule.id, type: CustomizationObjectType.AeTitleRule };
                    }
                } else if (jsonCursor['dicom_attributes'] !== undefined) {
                    const dicomRule = state.dicomRules.entities[currentId];
                    if (dicomRule) {
                        return { id: dicomRule.id, type: CustomizationObjectType.DicomRule };
                    }
                }

                throw new Error(`Could not retrieve either an AE title rule or DICOM rule for ${cursorPath}`);
            }

        case CustomizationObjectType.CustomizationBase:
            {
                const customizationBase = state.customizationBases.entities[currentId];
                if (customizationBase === undefined) { throw new Error(`Could not find matching customization base for validation error json node ${cursorPath}`); }
                return { id: customizationBase.id, type };
            }

        case CustomizationObjectType.CustomizationOutput:
            {
                const customizationOutput = state.customizationOutputs.entities[currentId];
                if (customizationOutput === undefined) { throw new Error(`Could not find matching customization output for validation error json node ${cursorPath}`); }
                return { id: customizationOutput.id, type };
            }

        case CustomizationObjectType.Metadata:
            {
                const metadata = state.modelCustomizationsMetadata.entities[currentId];
                if (metadata === undefined) { throw new Error(`Could not find matching metadata entry for validation error json node ${cursorPath}`); }
                return { id: metadata.id, type };
            }

        case CustomizationObjectType.Roi:
            {
                const roi = state.roiCustomizations.entities[currentId];
                if (roi === undefined) { throw new Error(`Could not find matching roi customization for validation error json node ${cursorPath}`); }
                return { id: roi.id, type };
            }

        case CustomizationObjectType.PhysicalProperties:
            {
                // return the previous roi but with the new PhysicalProperties type
                const roi = target && target.type === CustomizationObjectType.Roi ? state.roiCustomizations.entities[target.id] : undefined;
                if (roi === undefined) { throw new Error(`Could not find matching roi customization for validation error json node ${cursorPath}`); }
                return { id: roi.id, type };
            }

        default:
            throw new Error(`No valid type given for getTargetObject (${type})`);
    }
}


/// unit test helper export functions
// these functions just export existing functions that are otherwise internal to this file
// these should NOT be used outside of unit tests

export const _unitTest_calculateFormValidationErrorsForCustomizationOutput = (state: ContouringSliceState, outputId: string): FormValidationErrorCalculationResults => {
    return calculateFormValidationErrorsForCustomizationOutput(state, outputId);
}

export const _unitTest_validateDicomRuleHasAtLeastOneAttribute = (dicomRule: DicomRule): DicomAttributeValidationResults => {
    return validateDicomRuleHasAtLeastOneAttribute(dicomRule);
}

export const _unitTest_validateDicomAttributeNamesAreNotEmpty = (dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults => {
    return validateDicomAttributeNamesAreNotEmpty(dicomAttributeRules);
}

export const _unitTest_validataAllDicomAttributeNamesHaveNoDuplicates = (dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults => {
    return validateAllDicomAttributeNamesHaveNoDuplicates(dicomAttributeRules);
}
