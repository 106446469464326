import { Button, Menu, MenuContent, MenuList, MenuItem } from '@patternfly/react-core'
import React, { ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownIcon, CaretUpIcon } from '@patternfly/react-icons'
import { useTranslation } from 'react-i18next';
import { deprecatedModelsHidden, deprecatedModelsShown, nonLicensedModelsHidden, nonLicensedModelsShown, userHiddenModelsHidden, userHiddenModelsShown, userSelectors } from '../../store/user/userSlice';
import { Model, ModelType } from '../../store/global-types/customization-types';
import { contouringSelectors } from '../../store/contouring/contouringSlice';
import { doseSelectors } from '../../store/dose/doseSlice';

interface CustomizationFiltersProps {
    modelType: ModelType.Contouring | ModelType.Dose,
}

const CustomizationFilters = (props: CustomizationFiltersProps) => {
    const { modelType } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let deprecatedModels: Model[];
    let nonLicensedModels: Model[];
    let hiddenModels: Model[];

    switch (modelType) {
        case ModelType.Contouring:
            deprecatedModels = useSelector(contouringSelectors.selectDeprecatedModels);
            nonLicensedModels = useSelector(contouringSelectors.selectNonLicensedModels);
            hiddenModels = useSelector(contouringSelectors.selectUserHiddenModels);
            break;
        case ModelType.Dose:
            deprecatedModels = useSelector(doseSelectors.selectDeprecatedModels);
            nonLicensedModels = useSelector(doseSelectors.selectNonLicensedModels);
            hiddenModels = useSelector(doseSelectors.selectUserHiddenModels);
            break;

        default:
            throw new Error('Unsupported model type');
    }

    const areDeprecatedModelsShown = useSelector(userSelectors.selectAreDeprecatedModelsShown);
    const areNonLicensedModelsShown = useSelector(userSelectors.selectAreNonLicensedModelsShown);
    const areUserHiddenModelsShown = useSelector(userSelectors.selectAreUserHiddenModelsShown);

    const [isModelFiltersSelectOpen, toggleModelFiltersSelect] = React.useState(false);

    const handleAllModelsVisibilityIcon = (): ReactNode => {
        return (isModelFiltersSelectOpen) ? <CaretUpIcon className='models-visibility-icon' /> : <CaretDownIcon className='models-visibility-icon' />;
    }


    const handleModelsVisibilityChange = (type: 'deprecated' | 'non-licensed' | 'user-hidden') => {
        switch (type) {
            case "non-licensed":
                if (areNonLicensedModelsShown === false) {
                    dispatch(nonLicensedModelsShown());
                } else {
                    dispatch(nonLicensedModelsHidden());
                }
                break;
            case "deprecated":
                if (areDeprecatedModelsShown === false) {
                    dispatch(deprecatedModelsShown());
                } else {
                    dispatch(deprecatedModelsHidden());
                }
                break;
            case "user-hidden":
                if (areUserHiddenModelsShown === false) {
                    dispatch(userHiddenModelsShown());
                } else {
                    dispatch(userHiddenModelsHidden());
                }
                break;
            default:
                throw new Error('Unsupported visibility change type');
        }
    };

    return (
        <>
            <Button variant="secondary" className='deprecated-models-toggle' onClick={() => toggleModelFiltersSelect(!isModelFiltersSelectOpen)}> {t('filterModels.title')}{handleAllModelsVisibilityIcon()}
            </Button >
            {isModelFiltersSelectOpen &&
                <Menu className="customization-filter-menu">
                    <MenuContent>
                        <MenuList>
                            <MenuItem className='models-filter-menu-item' itemId={1} onClick={() => handleModelsVisibilityChange('deprecated')} hasCheck isSelected={areDeprecatedModelsShown} title={t('filterModels.showDeprecated')}>{t('filterModels.showDeprecated')}{deprecatedModels.length > 0 && ` (${deprecatedModels.length})`}</MenuItem>
                            <MenuItem className='models-filter-menu-item' itemId={2} onClick={() => handleModelsVisibilityChange('non-licensed')} hasCheck isSelected={areNonLicensedModelsShown} title={t('filterModels.showUnavailable')}>{t('filterModels.showUnavailable')}{nonLicensedModels.length > 0 && ` (${nonLicensedModels.length})`}</MenuItem>
                            <MenuItem className='models-filter-menu-item' itemId={3} onClick={() => handleModelsVisibilityChange('user-hidden')} hasCheck isSelected={areUserHiddenModelsShown} title={t('filterModels.showHidden')}>{t('filterModels.showHidden')}{hiddenModels.length > 0 && ` (${hiddenModels.length})`}</MenuItem>
                        </MenuList>
                    </MenuContent>
                </Menu>
            }
        </>
    )
}

export default CustomizationFilters