import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SplashPage from "../splash/SplashPage";
import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import { appInitializationStarted } from "../../store/appStatus/appStatusSlice";
import { getBackendApi } from "../../app-config";

/** Component for wrapping backend config & auth related app settings. */
const AppWrapper = (props: React.PropsWithChildren<{}>) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    // ONE-TIME initialization start -- we can't even finish rendering this component until app init has been done!
    useEffect(() => {
        dispatch(appInitializationStarted());
    }, [dispatch]);

    if (!appConfig) {
        return (<SplashPage loadingText={t('splash.initializing')} />);
    }

    const AppWrapperComponent = getBackendApi(appConfig).getAppWrapperComponent();

    return (<AppWrapperComponent>{props.children}</AppWrapperComponent>);
}

export default AppWrapper;
