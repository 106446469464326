import React from 'react';
import { TextContent, Text, Level, LevelItem, FormGroup, TextInput } from '@patternfly/react-core';
import { ArrowRightIcon } from '@patternfly/react-icons'

import './customization-page.css';
import InputLabel from '../../components/input-label';
import { useTranslation } from 'react-i18next';

interface OutputFilenameFieldProps {
    segmentationModelName: string,
    sourceCustomizationName: string,
    sourceFilename: string,
    onOutputFilenameChange: (newFilename: string) => void,
    duplicatedFilename: string,
}

const OutputFilenameField = (props: OutputFilenameFieldProps) => {
    const { segmentationModelName, sourceCustomizationName, duplicatedFilename, onOutputFilenameChange, sourceFilename } = props;
    const { t } = useTranslation();

    return (
        <>
            <TextContent className="model-name">
                <Text component="h6">{t('customizationPage.targetFileNameField.filenameForNewTargetFile')}</Text>
            </TextContent>

            <Level className="ae-title-row duplicated-model-name-fields">
                <LevelItem>
                    <FormGroup
                        label={t('customizationPage.targetFileNameField.duplicatingFrom')}
                        fieldId="duplication-source-filename"
                        title={undefined}
                    >
                        <div className="duplication-source">
                            <span className="duplication-model-name">{segmentationModelName}</span>
                            <span className="duplication-separator">.</span>
                            <span className="duplication-customization-name">{sourceCustomizationName}</span>
                            <span className="duplication-separator">:</span>
                            <span className="duplication-filename">{sourceFilename}</span>
                        </div>
                    </FormGroup>
                </LevelItem>
                <LevelItem className="duplication-arrow">
                    <ArrowRightIcon />
                </LevelItem>
                <LevelItem className="ae-title-aetitle-column">
                    <FormGroup
                        label={t('customizationPage.targetFileNameField.newTargetFile')}
                        fieldId="duplicated-model-filename"
                        title={undefined}
                    >
                        <InputLabel label={t('customizationPage.targetFileNameField.filename.pointer')} useRef className="duplicate-model-name-field">
                            <TextInput
                                type="text"
                                id="duplicated-model-filename"
                                onChange={onOutputFilenameChange}
                                value={duplicatedFilename}
                            />
                        </InputLabel>
                    </FormGroup>
                </LevelItem>
            </Level>
        </>
    );
}

export default OutputFilenameField;
