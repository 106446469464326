import { removeTrailingForwardSlash } from "../../util/string-convert";

/** AppAuthState models the state of user flow of logging into a specific AppAuth. */
export type AppAuthState = {
    appAuthName: string;
    isRequired: boolean;
    logInProcessState: LogInProcessState;
}

/** Current login state. */
export enum LogInProcessState {
    NotLoggedIn,
    LoggingInProgress,
    LoggedIn,
    Error,
}

/** Tier of a backend. This defines which Azure app registration we need to use
 * to authenticate with a backend.
 */
export enum CloudBackendTier {
    Default = 'Default',
}

/** A supported backend. */
export type CloudBackend = {
    tier: CloudBackendTier;
    name: string;
    url: string;
}

/** Creates a new AppAuthState that is set to be required. */
export const createRequiredAppAuthState = (appAuthName: string): AppAuthState => {
    return {
        appAuthName: appAuthName,
        isRequired: true,
        logInProcessState: LogInProcessState.NotLoggedIn
    };
}

/** Creates a new backend object. */
export function createBackend(name: string, url: string, tier: CloudBackendTier): CloudBackend {
    return {
        name: name,
        url: removeTrailingForwardSlash(url),
        tier: tier
    };
}

