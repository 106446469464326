import { call, fork, put, take, takeEvery } from "typed-redux-saga";
import { licenseStatusFetched, licenseStatusSet, updateLicenseFinished, updateLicenseStarted } from "./licenseSlice";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import ConfigurationClient from "../../web-apis/configuration-client";
import { LicenseStatus } from "./license-status";
import { get } from "lodash-es";
import { Action } from "@reduxjs/toolkit";

function* fetchLicenseStatusSaga(appClient: MVisionAppClient | undefined) {
    const client = new ConfigurationClient();
    let licenseStatus: LicenseStatus[] | null = null;
    let error = null;

    try {
        licenseStatus = appClient === undefined ? [] : yield* call(async () => client.fetchLicenseStatusAsync(appClient));
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error === null) {
        yield* put(licenseStatusSet({ licenseStatus: licenseStatus }));
    } else {
        // error case
        console.error('Was not able to fetch license status');
        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        yield* put(licenseStatusSet({ licenseStatus: null, error: errorMessage }));
    }
}


function* updateLicenseSaga(action: Action) {
    if (updateLicenseStarted.match(action)) {
        const { newLicense, appClient } = action.payload;
        const client = new ConfigurationClient();
        let error = null;
        let errorMessage = undefined;
        let success = false;

        try {
            success = yield* call(async () => client.saveLicenseAsync(appClient, newLicense));
        }
        catch (ex) {
            console.error(ex);
            error = ex;
        }

        if (error !== null) {
            console.error('Was not able to update license');
            errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            console.error(errorMessage);
        } else if (!success) {
            errorMessage = 'Unable to update license. The given license key might not be valid, or there might be a problem with the server. Please check Cockpit logs.';
        }

        yield* put(updateLicenseFinished({ success: success, error: errorMessage }));

        if (success) {
            // immediately fetch the new license
            yield* fork(fetchLicenseStatusSaga, appClient);
        }
    }
}

function* watchFetchLicenseStatusSaga() {
    while (true) {
        const action = yield* take(licenseStatusFetched);
        if (licenseStatusFetched.match(action)) {
            yield* fork(fetchLicenseStatusSaga, action.payload);
        }
    }
}

function* watchUpdateLicenseSaga() {
    yield* takeEvery(updateLicenseStarted, updateLicenseSaga);
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForLicenseSagas() {
    return [
        watchFetchLicenseStatusSaga(),
        watchUpdateLicenseSaga(),
    ];
}
