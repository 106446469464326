/**
 * Generic customization types can't have selectors as such, but this file contains
 * helper functions that can be called from slice-specific selectors.
 * 
 */

import { assertArrayIsDefined } from "../../util/assert";
import { naturalSortInPlace } from "../../util/sort";
import { DEFAULT_FILENAME } from "../contouring/contouring-types";
import { CustomizationBase, CustomizationObjectType, CustomizationOutput, CustomizationTreeViewDataItem, METADATA_FILENAME_ATTRIBUTE, Model, ModelVisibility, OutputMetadataItem } from "./customization-types";
import { BackendValidationErrorViewModel } from "./store-errors";

export const generateCustomizationTreeViewItems = (
    models: Model[],
    customizationBases: CustomizationBase[],
    outputEntities: Record<string, CustomizationOutput>,
    metadataEntities: Record<string, OutputMetadataItem>,
    validationEntities: Record<string, BackendValidationErrorViewModel>,
    areOutputChildrenValid: (output: CustomizationOutput, validationEntities: Record<string, BackendValidationErrorViewModel>) => boolean,
) => {
    const bodyPartNodes: CustomizationTreeViewDataItem[] = [];

    for (const model of models) {

        // create body part parent node if it doesn't exist yet
        let bodyPartNode = bodyPartNodes.find(n => n.label === model.bodyPart);
        if (bodyPartNode === undefined) {
            bodyPartNode = {
                label: model.bodyPart,
                children: [],
                id: model.bodyPart,
                isValid: true,
                parentId: undefined,
                type: CustomizationObjectType.None,
                isModified: false,
                isAvailable: true,
                isDeprecated: false,
                modelVisibility: ModelVisibility.AlwaysShown,
            };
            bodyPartNodes.push(bodyPartNode);
        }

        const modelNode: CustomizationTreeViewDataItem = {
            label: model.label,
            id: model.id,
            children: [],
            type: CustomizationObjectType.Model,
            isModified: model.isModified,
            parentId: bodyPartNode.id,
            isValid: !validationEntities[model.id] || validationEntities[model.id]!.type !== CustomizationObjectType.Model,
            isAvailable: model.isAvailable,
            isDeprecated: model.isDeprecated,
            modelVisibility: model.visibility,
        };

        bodyPartNode.children.push(modelNode);

        for (const customizationBase of customizationBases.filter(b => model.customizations.includes(b.id))) {
            const baseNode: CustomizationTreeViewDataItem = {
                label: customizationBase.customizationName,
                id: customizationBase.id,
                children: [],
                type: CustomizationObjectType.CustomizationBase,
                isModified: customizationBase.isModified,
                parentId: modelNode.id,
                isValid: (!validationEntities[customizationBase.id] || validationEntities[customizationBase.id]!.type !== CustomizationObjectType.CustomizationBase)
                    && customizationBase.aeTitleRules.every(aeId => !validationEntities[aeId] || validationEntities[aeId]!.type !== CustomizationObjectType.AeTitleRule)
                    && customizationBase.dicomRules.every(dId => !validationEntities[dId] || validationEntities[dId]!.type !== CustomizationObjectType.DicomRule),
                isAvailable: model.isAvailable,
                isDeprecated: model.isDeprecated,
                modelVisibility: model.visibility,
            };
            modelNode.children.push(baseNode);

            if (customizationBase.outputs.length > 0) {
                baseNode.children = [];
                const outputs = customizationBase.outputs.map(oId => outputEntities[oId]);
                assertArrayIsDefined(outputs);
                for (const output of outputs) {
                    const metadata = output.metadata.map(mId => metadataEntities[mId]);
                    const filename = metadata.find(m => m?.attribute === METADATA_FILENAME_ATTRIBUTE)?.value || DEFAULT_FILENAME;
                    const outputNode: CustomizationTreeViewDataItem = {
                        label: filename,
                        id: output.id,
                        children: [],
                        type: CustomizationObjectType.CustomizationOutput,
                        isModified: output.isModified,
                        parentId: baseNode.id,
                        isValid: (!validationEntities[output.id] || validationEntities[output.id]!.type !== CustomizationObjectType.CustomizationOutput)
                            && output.metadata.every(mId => !validationEntities[mId] || validationEntities[mId]!.type !== CustomizationObjectType.Metadata)
                            && areOutputChildrenValid(output, validationEntities),
                        isAvailable: model.isAvailable,
                        isDeprecated: model.isDeprecated,
                        modelVisibility: model.visibility,
                    };
                    baseNode.children.push(outputNode);
                    baseNode.isValid = !outputNode.isValid ? false : baseNode.isValid;
                }
            }

            modelNode.isValid = !baseNode.isValid ? false : modelNode.isValid;
        }
    }

    // set modified & valid settings for top-level body-part nodes
    for (const bodyPartNode of bodyPartNodes) {
        bodyPartNode.isValid = bodyPartNode.children.every(m => m.isValid);
        bodyPartNode.isModified = bodyPartNode.children.some(m => m.isModified);
    }

    return naturalSortInPlace(bodyPartNodes, 'label');
}
