export function randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

/** Returns a new random GUID. Don't use these in DICOM!
 * TODO: unsure if this returns actually valid GUIDs in case that's relevant for the use case.
 */
 export function newGuid(): string {
    function S4(): string {
        return (((1 + Math.random()) * 0x10000) | 0).toString(10).substring(1);
    }

    return S4() + S4() + "." + S4() + ".4" + S4().substring(0, 3) + "." + S4() + "." + S4() + S4() + S4();
}

/** Converts a string into a number, or NaN if not a valid number. */
export function getNumber(value?: string): number {
    if (value && value.trim() !== '') {
        return Number(value);
    }

    else return NaN;
}
