import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './i18n';

// import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';

import store from './store/store';

const container = document.getElementById('root');
const root = createRoot(container!); // ! is required with typescript

root.render(<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
</React.StrictMode>);
