/** Returns true if s is enum of type T. NOTE: works only with string enums, not number-based enums!
 * Usage: isSomeEnum(MyEnumType)(enumObject)
 * Example: isSomeEnum(ModelVisibility)(visibility)
 * 
 * Source: https://stackoverflow.com/a/58278753
 */
export const isSomeEnum = <T extends { [s: string]: unknown }>(e: T) => (token: unknown): token is T[keyof T] => Object.values(e).includes(token as T[keyof T]);

/** Attempts to parse a string as a specific enum. Returns undefined if parsing fails.
 * 
 * Source: https://stackoverflow.com/a/41548441
 */
export const enumFromStringValue = <T>(enm: { [s: string]: T }, stringValue: string): T | undefined => {
    return (Object.values(enm) as unknown as string[]).includes(stringValue)
        ? stringValue as unknown as T
        : undefined;
}

/** Attemps to parse an enum from a string. Returns that enum, or a specified default enum if parsing failed. */
export const enumFromStringOrDefault = <T>(enm: { [s: string]: T }, stringValue: string, defaultValue: T): T => {
    const parsedValue = enumFromStringValue(enm, stringValue);
    if (parsedValue) {
        return parsedValue;
    }

    return defaultValue;
} 
