// NOTE: This file contains an interface for user settings that can be received from backend. However as of release 1.2.7 (2024-04-08)
// these are not being used for anything as of yet, but most likely will in future. Some of the relevant redux, saga, and API code
// has been left in elsewhere in the codebase but is not currently being called.

import { has, isArray, isString } from "lodash-es";

const HIDDEN_MODEL_NAMES_JSON_KEY = 'hidden_model_names';
const SHOWN_MODEL_NAMES_JSON_KEY = 'shown_model_names';

export enum UserSettingField {
    HiddenModelNames = 'hidden_model_names',
    ShownModelNames = 'shown_model_names',
    AggregateWaitTime = 't_aggregate_wait_s',
}

type UserSettings = {
    /** Names of models that are always shown for this user.
     * NOTE: As of v1.2.7 this setting is NOT USED -- hidden models
     * are read directly from contouring customization. This prop is
     * left here as an example of user settings for now and can in
     * future be either removed or taken into actual use.
     */
    hiddenModelNames: string[],
    /** Names of models that are always hidden for this user.
     * NOTE: As of v1.2.7 this setting is NOT USED -- shown models
     * are read directly from contouring customization. This prop is
     * left here as an example of user settings for now and can in
     * future be either removed or taken into actual use.
     */
    shownModelNames: string[],
}

export default UserSettings;

export function getInitialUserSettings(): UserSettings {
    return {
        hiddenModelNames: [],
        shownModelNames: [],
    };
}

export function convertJsonObjectToUserSettings(json: any): UserSettings {
    const userSettings = getInitialUserSettings();

    if (has(json, HIDDEN_MODEL_NAMES_JSON_KEY) && isArray(json[HIDDEN_MODEL_NAMES_JSON_KEY]) && json[HIDDEN_MODEL_NAMES_JSON_KEY].every((h: any) => isString(h))) {
        userSettings.hiddenModelNames = json[HIDDEN_MODEL_NAMES_JSON_KEY];
    } else {
        throw new Error('Unexpected user settings format');
    }

    if (has(json, SHOWN_MODEL_NAMES_JSON_KEY) && isArray(json[SHOWN_MODEL_NAMES_JSON_KEY]) && json[SHOWN_MODEL_NAMES_JSON_KEY].every((h: any) => isString(h))) {
        userSettings.shownModelNames = json[SHOWN_MODEL_NAMES_JSON_KEY];
    } else {
        throw new Error('Unexpected user settings format');
    }

    return userSettings;
}

export function getUserSettingJsonFieldName(setting: UserSettingField): string {
    switch (setting) {
        case UserSettingField.HiddenModelNames:
            return 'hidden_model_names';
        case UserSettingField.ShownModelNames:
            return 'shown_model_names';
        case UserSettingField.AggregateWaitTime:
            return 't_aggregate_wait_s';
        default:
            throw new Error('Unsupported user setting enum');
    }
}
