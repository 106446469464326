import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@patternfly/react-core';
import SplashPage from '../splash/SplashPage';
import { useTranslation } from 'react-i18next';
import { ACCESS_RIGHTS_VALID, isInitialAccessRights } from '../../store/user/access-rights';
import { StoreState } from '../../store/store';
import { authSelectors } from '../../store/auth/authSlice';
import { userSelectors } from '../../store/user/userSlice';

/** Wrapper component to use when the app requires cloud authentication. Otherwise use NoAuthPage. */
const AuthenticatedPage = (props: React.PropsWithChildren<{}>) => {
    const { t } = useTranslation();

    // const auths = useSelector((state: StoreState) => state.appAuthStates);
    const loginError = useSelector((state: StoreState) => state.auth.loginError);
    const accessRights = useSelector(userSelectors.selectAccessRights);
    const accessRightsError = useSelector(userSelectors.selectAccessRightsError);

    const isWaitingForLogin = !useSelector(authSelectors.selectIsLoggedIntoAtLeastOneAppAuth);
    const wasLoginSuccessful = loginError === null && accessRightsError === null;
    const hasAccessRights = wasLoginSuccessful && accessRights && accessRights.configui.includes(ACCESS_RIGHTS_VALID);
    const isLoadingAccessRights = wasLoginSuccessful && isInitialAccessRights(accessRights);
    const loginErrorMessage = accessRightsError !== null ? accessRightsError : !wasLoginSuccessful && loginError && loginError.message;

    return (
        <>
            {!wasLoginSuccessful && loginErrorMessage && (<Alert variant="danger" title={t('error.loginError.title', { loginErrorMessage: loginErrorMessage })}>
                <p>{t('error.loginError.paragraph1')}</p>
                <p>{loginErrorMessage}</p>
                <p>{t('error.loginError.paragraph2')}</p>
            </Alert>)}
            {(isWaitingForLogin || isLoadingAccessRights) && (<SplashPage loadingText={t('splash.waitingForLogin')} />)}
            {!isWaitingForLogin && !hasAccessRights && !isInitialAccessRights(accessRights) && !isLoadingAccessRights && <SplashPage loadingText={t('error.accessRightsError')} />}
            {!isWaitingForLogin && !isLoadingAccessRights && !isInitialAccessRights(accessRights) && hasAccessRights && props.children}
        </>
    );
}

export default AuthenticatedPage;
