import { ResourceKey } from "i18next";
import React from "react";

/** Ids for different types of customization table columns. */
export const columnIds = {
    name: 'Name',
    color: 'Color',
    operation: 'Operation',
    isIncluded: 'Enabled',
    includedModels: 'Covered in models',
    excludedModels: 'Different in models',
    customization: 'Customization',
    targetFile: 'Target file',
    interpretedType: 'Interpreted type',
    fmaCodeValue: 'Code Value',
    fmaCodeMeaning: 'Code Meaning',
    fmaCodeSchemeDesignator: 'Code Scheme Designator',
};

/** Localization keys for customization table column headers. */
export const columnLangKeys: { [columnId: string]: ResourceKey } = {
    [columnIds.name]: 'column.name',
    [columnIds.color]: 'column.color',
    [columnIds.operation]: 'column.operation',
    [columnIds.isIncluded]: 'column.isIncluded',
    [columnIds.includedModels]: 'column.includedModels',
    [columnIds.excludedModels]: 'column.excludedModels',
    [columnIds.customization]: 'column.customization',
    [columnIds.targetFile]: 'column.targetFile',
    [columnIds.interpretedType]: 'column.interpretedType',
    [columnIds.fmaCodeValue]: 'column.fmaCodeValue',
    [columnIds.fmaCodeMeaning]: 'column.fmaCodeMeaning',
    [columnIds.fmaCodeSchemeDesignator]: 'column.fmaCodeSchemeDesignator',
}

/** Styling names for customization table columns. */
export const columnCssNames = {
    modified: 'column-modified',
    name: 'column-name',
    color: 'column-color',
    operation: 'column-operation',
    isIncluded: 'column-is-enabled',
    interpretedType: 'column-interpreted-type',
    fmaCodeValue: 'column-code-value',
    fmaCodeMeaning: 'column-code-meaning',
    fmaCodeSchemeDesignator: 'column-code-scheme-designator',
    includedModels: 'column-included-models',
    excludedModels: 'column-excluded-models',
}

export const columnTooltips = {
    [columnIds.operation]: (url?: string) => (<span>The operation or original structure name that this structure will be generated from{url && (<span className="tooltip-url">. For instructions, see <a href={url} rel="noopener noreferrer" target="_blank">structure operation instructions</a></span>)}</span>),
    [columnIds.isIncluded]: () => (<span>Sets whether this structure is enabled or disabled in the generated structure set</span>),
    [columnIds.includedModels]: () => (<span>The structure has an identical customization in these models</span>),
    [columnIds.excludedModels]: () => (<span>The structure has a different customization in these models</span>),
}

export type RoiTableColumnKey = keyof typeof columnIds;
