import React, { useCallback } from 'react';

import { getDefaultAppName } from '../environments';
import { Alert, AlertActionLink } from '@patternfly/react-core';
import { useDispatch, useSelector } from 'react-redux';
import { newVersionAlertSnoozed } from '../store/appVersion/appVersionSlice';
import { NEW_VERSION_SNOOZE_TIME_IN_HOURS } from '../store/appVersion/appVersionTypes';
import { StoreState } from '../store/store';



const NewVersionAlert = () => {
    const dispatch = useDispatch();

    const handleSnoozeClick = useCallback(() => {
        dispatch(newVersionAlertSnoozed());
    }, [dispatch]);

    const isNewAppVersionAvailable = useSelector((state: StoreState) => state.appVersion.isNewAppVersionAvailable);
    const isSnoozed = useSelector((state: StoreState) => state.appVersion.isNewVersionAlertSnoozed);

    const snoozeMessage = `Snooze for ${NEW_VERSION_SNOOZE_TIME_IN_HOURS} hours.`;

    if (isNewAppVersionAvailable && !isSnoozed) {
        return (
            <Alert
                title="A new version is available"
                actionLinks={<AlertActionLink onClick={handleSnoozeClick}>{snoozeMessage}</AlertActionLink>}
            >
                <span>A new version of {getDefaultAppName()} is available. Please save your work and refresh this page to change to the latest version as soon as possible.</span>
            </Alert>
        );
    }
    else {
        return null;
    }
}

export default NewVersionAlert;
