import { call, fork, put, select, takeEvery } from "typed-redux-saga";
import { fetchTextEditorFileContentsStarted, foundTextEditorFilePathsSet, saveTextEditorFileContentsFinished, saveTextEditorFileContentsStarted, textEditorFileContentsFetched, textEditorFileContentsSet, textEditorFilesFetched, textEditorSelectors } from "./textEditorSlice";
import { TextEditorFile } from "./text-editor-file";
import TextEditorFilesApi from "../../web-apis/text-editor-files-api";
import { get } from "lodash-es";


function* fetchTextEditorFilesSaga() {
    let files: TextEditorFile[] = [];
    let error = null;

    try {
        files = yield* call(TextEditorFilesApi.searchFiles);
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error === null) {
        yield* put(foundTextEditorFilePathsSet(files));
        for (let file of files) {
            // after putting text editor files to store the file objects we have here may already
            // be stale, so immediately re-fetch latest version of this file from the store so we
            // don't have to duplicate store logic here
            //
            // TODO: test that this still works correctly after the RTK refactor!
            //
            const latestFile = yield* select(textEditorSelectors.selectLatestTextEditorFile, file);
            yield* fork(tryFetchTextEditorFileContentsSaga, { payload: { file: latestFile } });
        }
    }
}

function* tryFetchTextEditorFileContentsSaga(action: any) {
    const file: TextEditorFile = get(action, 'payload.file', null);
    if (file !== null) {
        // allow file to load (or re-load) if it's not already loading, or doesn't have modifications
        if (!file.isLoadingFile && !file.isModified) {
            yield* put(textEditorFileContentsFetched(file.filePath));

            let contents: string = '';
            let error = null;

            try {
                contents = yield* call(TextEditorFilesApi.loadFile, file.filePath);
            }
            catch (ex) {
                console.error(ex);
                error = ex;
            }

            if (error === null) {
                // TODO: show errors in UI
                yield* put(textEditorFileContentsSet({ filePath: file.filePath, contents }));
            }
        }
    }
}

function* saveTextEditorFileContentsSaga(action: any) {
    const { filePath, newContents } = action.payload;

    let error = null;

    try {
        yield* call(TextEditorFilesApi.saveFile, filePath, newContents);
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error === null) {
        yield* put(saveTextEditorFileContentsFinished({ filePath: filePath, newContents: newContents, error: undefined }));
    } else {
        // error case
        console.error(`Was not able to save changes to file ${filePath}`);
        const errorMessage = get(error, 'message', 'Unknown error');
        yield* put(saveTextEditorFileContentsFinished({ filePath: filePath, newContents: null, error: errorMessage }));
    }
}



function* watchFetchTextEditorFilesSaga() {
    yield* takeEvery(textEditorFilesFetched, fetchTextEditorFilesSaga);
}

function* watchFetchTextEditorFileContentsStartedSaga() {
    yield* takeEvery(fetchTextEditorFileContentsStarted, tryFetchTextEditorFileContentsSaga);
}

function* watchSaveTextEditorFileContentsSaga() {
    yield* takeEvery(saveTextEditorFileContentsStarted, saveTextEditorFileContentsSaga);
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForTextEditorSagas() {
    return [
        watchFetchTextEditorFilesSaga(),
        watchFetchTextEditorFileContentsStartedSaga(),
        watchSaveTextEditorFileContentsSaga(),
    ];
}
