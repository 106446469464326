import { call, all, fork, take } from 'typed-redux-saga';
import { Dispatch } from '@reduxjs/toolkit';
import { getWatchesForAppVersionSagas } from './appVersion/appVersionSagas';
import { getWatchesForAppDeploymentInfoSagas } from './appConfig/appConfigSagas';
import { initializeAppSaga } from './appStatus/appStatusSagas';
import { getWatchesForAuthSagas } from './auth/authSagas';
import { appInitializationStarted } from './appStatus/appStatusSlice';
import { getWatchesForConfigurationTargetSagas } from './configurationTarget/configurationTargetSagas';
import { getWatchesForContouringConfigSagas } from './contouring/contouringSagas';
import { getWatchesForTextEditorSagas } from './textEditor/textEditorSagas';
import { getWatchesForLicenseSagas } from './license/licenseSagas';
import { getWatchesForDaemonSagas } from './daemon/daemonSagas';
import { getWatchesForUserSagas } from './user/userSagas';


/**
 * Collect all rtk slice watch functions in one place.
 */
function* startWatchesSaga() {
    yield* all([
        ...getWatchesForAppDeploymentInfoSagas(),
        ...getWatchesForAppVersionSagas(),
        ...getWatchesForAuthSagas(),
        ...getWatchesForConfigurationTargetSagas(),
        ...getWatchesForContouringConfigSagas(),
        ...getWatchesForDaemonSagas(),
        ...getWatchesForLicenseSagas(),
        ...getWatchesForTextEditorSagas(),
        ...getWatchesForUserSagas(),
    ]);
}

/** Initialize app and launch all watch sagas. Dispatch is needed to initialize certain backend client configurations. */
export default function* rootSaga(dispatch: Dispatch) {
    // wait for permission to start initializing app (and logging in the user)
    yield* take(appInitializationStarted);

    yield* fork(startWatchesSaga);
    yield* call(initializeAppSaga, dispatch);
}
