import React from 'react';
import { FileIcon, FolderIcon, FolderOpenIcon } from '@patternfly/react-icons';

import iconGears from '../img/gears-solid.png';


export enum TreeViewIconType {
    Folder,
    FolderOpen,
    File,
    Gear,
}

export const getIconByType = (type: TreeViewIconType): React.ReactNode => {
    switch (type) {
        case TreeViewIconType.Folder:
            return <FolderIcon width={20} />;
        case TreeViewIconType.FolderOpen:
            return <FolderOpenIcon width={20} />;
        case TreeViewIconType.File:
            return <FileIcon width={20} />;
        case TreeViewIconType.Gear:
            return <img src={iconGears} alt="" width={20} />;
        default:
            return null; 
    }
};
