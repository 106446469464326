import { isArray, isBoolean } from "lodash-es";
import { CloudBackendTier } from "../auth/auth";

export const DEPLOYMENT_CONFIGURATION_FILE_NAME = '/config.json';

/** Deployment mode of this entire Configuration UI environment -- either cloud or local. */
export enum DeploymentMode {
    NotSet = 'NotSet',
    Local = 'local',
    Cloud = 'cloud',
}

export type DocumentLink = {
    label: string;
    url: string;
}

export enum DisabledFeature {
    AccessRights = 'AccessRights',
}

/**
 * Deployment configuration file. Is placed into a Config UI deployment and is used to configure particulars of that deployment.
 * File is read upon app initialization and is used to set up some fundamental app configurations.
 */
export type DeploymentConfigInfo = {
    /** Deployment mode of this app deployment/installation. */
    deploymentMode: DeploymentMode,
    /** The URL in which a cloud backend is located. Not used in local mode. */
    cloudBackendUrl?: string;
    /** The tier of the cloud backend.  Not used in local mode. */
    cloudBackendTier?: CloudBackendTier;
    /** URL linking to a dashboard deployment. */
    dashboardUrl?: string;
    /** URL linking to a regex website. */
    regexHelpUrl?: string;
    /** File for help with regex writing */
    regexHelpFile?: string;
    /** Optional name for the backend. Not used in UI as the app doesn't currently support multiple backends. Not used in local mode. */
    backendName?: string;
    /** Link to a ROI customization operations manual. */
    operationsManualUrl?: string;
    /** Omit any localizations from UI that match these lang keys (e.g.: ['en-US', 'fr-FR', 'pseudo']) */
    omitLocalizationsFromUi?: string[];
    /** Daemon config page can optionally be disabled altogether. */
    disableDaemonConfigPage: boolean;
    /** List of document links to be shown in the UI. */
    documentLinks?: DocumentLink[];
    /** List of features that are disabled in this deployment. */
    disabledFeatures: DisabledFeature[];
}


// Function to validate if an object is a valid DocumentLink
function isValidDocumentLink(link: any): link is DocumentLink {
    return (
        typeof link === 'object' &&
        'label' in link && typeof link.label === 'string' &&
        'url' in link && typeof link.url === 'string'
    );
}

/** Returns true if queried feature is DISABLED in current deployment, false if it is ENABLED. */
export const isFeatureDisabled = (config: DeploymentConfigInfo, feature: DisabledFeature): boolean => {
    return config.disabledFeatures.includes(feature);
}

export const generateDeploymentConfigInfo = (jsonObject: any): DeploymentConfigInfo | undefined => {
    if ('deploymentMode' in jsonObject && Object.values(DeploymentMode).includes(jsonObject['deploymentMode'])) {

        const deploymentConfigInfo: DeploymentConfigInfo = {
            deploymentMode: jsonObject['deploymentMode'],
            disableDaemonConfigPage: false,
            disabledFeatures: [],
        }

        if ('cloudBackendUrl' in jsonObject) {
            deploymentConfigInfo.cloudBackendUrl = jsonObject['cloudBackendUrl'];
        }
        if ('cloudBackendTier' in jsonObject && Object.values(CloudBackendTier).includes(jsonObject['cloudBackendTier'])) {
            deploymentConfigInfo.cloudBackendTier = jsonObject['cloudBackendTier'];
        }
        if ('dashboardUrl' in jsonObject) {
            deploymentConfigInfo.dashboardUrl = jsonObject['dashboardUrl'];
        }
        if ('backendName' in jsonObject) {
            deploymentConfigInfo.backendName = jsonObject['backendName'];
        }
        if ('regexHelpUrl' in jsonObject) {
            deploymentConfigInfo.regexHelpUrl = jsonObject['regexHelpUrl'];
        }
        if ('regexHelpFile' in jsonObject) {
            deploymentConfigInfo.regexHelpFile = jsonObject['regexHelpFile'];
        }
        if ('operationsManualUrl' in jsonObject) {
            deploymentConfigInfo.operationsManualUrl = jsonObject['operationsManualUrl'];
        }
        if ('omitLocalizationsFromUi' in jsonObject) {
            deploymentConfigInfo.omitLocalizationsFromUi = jsonObject['omitLocalizationsFromUi'];
        }
        if ('disableDaemonConfigPage' in jsonObject) {
            // although daemon config page is NOT disabled by default, in an unclear situation it will get disabled
            deploymentConfigInfo.disableDaemonConfigPage = isBoolean(jsonObject['disableDaemonConfigPage']) ? jsonObject['disableDaemonConfigPage'] : true;
        }
        // check for features that have been disabled in config file
        if ('disabledFeatures' in jsonObject && isArray(jsonObject['disabledFeatures'])) {
            const disabledFeatures: DisabledFeature[] = [];
            for (const disabledFeature of jsonObject['disabledFeatures']) {
                if (Object.values(DisabledFeature).includes(disabledFeature)) {
                    disabledFeatures.push(disabledFeature);
                }
            }
            deploymentConfigInfo.disabledFeatures = disabledFeatures;
        }
        if ('documentLinks' in jsonObject && Array.isArray(jsonObject['documentLinks'])) {
            const validLinks = [];

            for (const link of jsonObject['documentLinks']) {
                if (isValidDocumentLink(link)) {
                    validLinks.push(link);
                }
            }
            // Assign only valid links to deploymentConfigInfo.documentLinks
            deploymentConfigInfo.documentLinks = validLinks;
        }

        return deploymentConfigInfo;
    }

    return undefined;
}
