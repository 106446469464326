import { createRef, RefObject } from "react";

// see https://stackoverflow.com/a/57135211 & https://stackoverflow.com/a/68206649
/**
 * A hook for creating and executing a viewport scroll to an element.
 * @param onlyScrollWhenOutOfViewPort if true, only do scroll if the ref element is outside of current viewport. Default: false.
 * @param offset the pixel offset used for scroll to compensate for sticky headers etc. Default: 400.
 * @param options optional ScrollOptions options. Default: undefined.
 * @returns a function to execute the scroll, and a <T extends HTMLElement> RefObject to use with a React or HTML component to
 * tie the scroll to a component with a ref prop.
 * @example <caption>Example usage of useScroll</caption>
 * const [executeScroll, scrollRef] = useScroll<HTMLDivElement>(true);
 * useEffect(executeScroll, [executeScroll]);
 * return (<div ref={scrollRef}>Scroll to this element!</div>);
 */
export const useScroll = <T extends HTMLElement>(onlyScrollWhenOutOfViewPort?: boolean, offset?: number, options?: ScrollOptions): [() => void, RefObject<T>] => {
    const scrollElementRef = createRef<T>();
    const scrollOffset = offset || 400;
    const executeScroll = (): void => {
        if (scrollElementRef && scrollElementRef.current && (!onlyScrollWhenOutOfViewPort || !isElementInViewport(scrollElementRef.current))) {
            const elementPosition = scrollElementRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - scrollOffset;
            if (scrollElementRef.current instanceof HTMLDivElement) {
                scrollElementRef.current.scrollIntoView();
            } else {
                window.scrollTo({ top: offsetPosition, ...options });
            }
        }
    };

    return [executeScroll, scrollElementRef];
};

const isElementInViewport = (element: HTMLElement): boolean => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
