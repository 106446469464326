import { isArray, isString } from "lodash-es";
import { BackendValidationError } from "../../util/errors";
import { DaemonValidationError } from "../global-types/store-errors";

export const convertDaemonValidationErrorsToStoreObjects = (daemonSessionId: string, error: BackendValidationError): DaemonValidationError[] => {
    const storeErrors = error.validationErrors.map((error, index) => ({
        id: `${daemonSessionId}-${index}`,
        field: error.loc !== undefined && isArray(error.loc) ? isString(error.loc[error.loc.length - 1]) ? error.loc[error.loc.length - 1] as string : undefined : undefined,
        message: error.msg,
        detail: error.type,
        ctx: JSON.stringify(error.ctx)
    }));

    return storeErrors;
}
