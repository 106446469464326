import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TextEditorFile } from './text-editor-file';
import { sortBy } from 'lodash-es';

export type TextEditorSliceState = {

    /** Files available in the text editor */
    textEditorFiles: TextEditorFile[],

    /** True if files for text editor are currently being loaded, false otherwise. */
    isLoadingTextEditorFilePaths: boolean,

};

export const initialState: TextEditorSliceState = {
    textEditorFiles: [],
    isLoadingTextEditorFilePaths: false,
};

/** Text editor-related redux store slice. (Only relevant in local Config UI.)  */
const textEditorSlice = createSlice({
    name: 'textEditor',
    initialState,
    reducers: {
        /** Mark text editor files as currently being loaded in. */
        textEditorFilesFetched(state) {
            state.isLoadingTextEditorFilePaths = true;
        },

        /**
         * Sets text editor paths once they have been loaded.
         * @param action Array of files that were found from local system.
         */
        foundTextEditorFilePathsSet(state, action: PayloadAction<TextEditorFile[]>) {
            const foundFiles = action.payload;
            state.isLoadingTextEditorFilePaths = false;
            if (state.textEditorFiles.length > 0) {
                // do not allow overwriting of files with unsaved changes
                foundFiles.forEach(ff => {
                    const matchingOldFile = state.textEditorFiles.find(f => f.filePath === ff.filePath);
                    if (matchingOldFile !== undefined && !matchingOldFile.isModified) {
                        // if an old file is found from the store and it is NOT modified, replace it with the new file
                        const index = state.textEditorFiles.indexOf(matchingOldFile);
                        state.textEditorFiles[index] = ff;
                    } else if (matchingOldFile === undefined) {
                        // if an old file is not found, append the new file to the array
                        state.textEditorFiles.push(ff);
                    }
                });
            } else {
                state.textEditorFiles = foundFiles;
            }

            // finally, make sure the files are sorted in alphabetical order
            state.textEditorFiles = sortBy(state.textEditorFiles, f => f.filePath);
        },

        /**
         * Marks a single file from a specific path as currently being loaded in.
         * @param action The full path of the file that is being loaded.
         */
        textEditorFileContentsFetched(state, action: PayloadAction<string>) {
            const filePath = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                file.isLoadingFile = true;
            }
        },

        /**
         * Sets contents of a text editor file once it's been initially loaded in.
         * @param action.filePath The full path of the file that was just loaded in.
         * @param action.contents The text contents of the file that was just loaded in.
         */
        textEditorFileContentsSet(state, action: PayloadAction<{ filePath: string, contents: string }>) {
            const { filePath, contents } = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                file.isLoadingFile = false;
                file.fileContents = contents;
                file.originalFileContents = contents;
                file.isLoaded = true;
                file.isModified = false;
            }
        },

        /**
         * Modifies the contents of a text editor file that has been loaded in.
         * @param action.filePath The full path of the file that was modified.
         * @param action.newContents The new contents of the file.
         */
        textEditorFileModified(state, action: PayloadAction<{ filePath: string, newContents: string }>) {
            const { filePath, newContents } = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                file.fileContents = newContents;
                file.isModified = true;
            }
        },

        /**
         * Opens a text editor file so that its contents are available for modifications.
         * (Text editor files cannot be edited (modified) by default, they must be 
         * opened for editing first.)
         * @param action The full path of the file that was opened for modifications.
         */
        textEditorFileOpenedForEditing(state, action: PayloadAction<string>) {
            const filePath = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                // not the most elegant way to handle this, but it keeps the complexity low
                file.isModified = true;
            }
        },

        /**
         * Closes a file from being edited and marks it as not having been modified.
         * NOTE: this reducer does NOT perform any kind of revert actions for the
         * file contents -- these must be handled in another reducer before calling this
         * one as needed.
         * @param action The full path of the file that was closed from modifications.
         */
        textEditorFileClosedForEditing(state, action: PayloadAction<string>) {
            const filePath = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                // not the most elegant way to handle this, but it keeps the complexity low
                file.isModified = false;
                file.errorSavingFile = null;
            }
        },

        /**
         * Reverts contents of a text editor file to original state.
         * NOTE: does NOT adjust the isModified prop -- consider also
         * calling the textEditorFileClosedForEditing reducer as needed.
         * @param action The full path of the file that was reverted back to original state.
         */
        textEditorFileChangesReverted(state, action: PayloadAction<string>) {
            const filePath = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                file.fileContents = file.originalFileContents;
                file.errorSavingFile = null;
                // note that isModified prop is left intact
            }
        },

        /**
         * Starts saving of modifications to a local text editor file.
         * @param action.filePath  The full path of the file that is being saved.
         * @param action.newContents  The contents that are being saved to the file. Note that this
         * argument is not used in this selector but is needed for the matching saga function.
         */
        saveTextEditorFileContentsStarted(state, action: PayloadAction<{ filePath: string, newContents: string }>) {
            const { filePath } = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                file.errorSavingFile = null;
            }
        },

        /**
         * Finishes the save operation to a local text editor file.
         * @param action.filePath  The full path of the file that was just saved.
         * @param action.newContents  The contents that were just saved to the file.
         * @param action.error An error message or undefined if there were no errors during save.
         */
        saveTextEditorFileContentsFinished(state, action: PayloadAction<{ filePath: string, newContents: string | null, error: string | undefined }>) {
            const { filePath, newContents, error } = action.payload;
            const file = state.textEditorFiles.find(f => f.filePath === filePath);
            if (file) {
                if (newContents !== null) {
                    // saving the file was successful
                    file.originalFileContents = newContents;
                    if (file.fileContents === newContents) { file.isModified = false; }
                } else {
                    file.errorSavingFile = error !== undefined ? error : `An error occurred when trying to save file ${file.filePath}`;
                }
            }
        },

        /**
         * Signals that an attempt is made to read contents of local file for the text editor.
         * @param action The file to read.
         */
        fetchTextEditorFileContentsStarted(state, action: PayloadAction<TextEditorFile>) {
            // this is an empty action and is only used for signalling in sagas
        },

    },

    selectors: {
        /** Selects the latest version of a potentially stale text editor file. */
        selectLatestTextEditorFile: (state, staleFile: TextEditorFile) => state.textEditorFiles.find(f => f.filePath === staleFile.filePath),
    }


});

export const {
    textEditorFilesFetched,
    foundTextEditorFilePathsSet,
    textEditorFileContentsFetched,
    textEditorFileContentsSet,
    textEditorFileModified,
    textEditorFileOpenedForEditing,
    textEditorFileClosedForEditing,
    textEditorFileChangesReverted,
    saveTextEditorFileContentsStarted,
    saveTextEditorFileContentsFinished,
    fetchTextEditorFileContentsStarted,
} = textEditorSlice.actions;

// uncomment if these are needed locally
// const localSelectors = textEditorSlice.getSelectors();

export const { getInitialState, selectors: textEditorSelectors } = textEditorSlice;

export default textEditorSlice.reducer;
