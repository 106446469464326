import React, { useState, useEffect, useCallback } from 'react';
import { SketchPicker } from 'react-color';
import { Button, TextInput } from '@patternfly/react-core';

import './color-picker.css';

interface ColorPickerProps {
    color: [number, number, number],
    onChange: (color: [number, number, number]) => void,
    textInputId: string,
    isDisabled?: boolean | undefined,
    label?: string,
}

// adjusting these will also require some minor tweaking to color-picker's css styles
const colorPreviewWidth = "48";
const colorPreviewHeight = "24";

const getTextValue = (color: [number, number, number]): string => `${color[0]}, ${color[1]}, ${color[2]}`;

const isTextValidColor = (value: string) => {
    const rgb = value.split(',');
    if (rgb.length === 3) {
        if (rgb.every(color => !isNaN(parseInt(color)) && parseInt(color) >= 0 && parseInt(color) <= 255)) {
            return true;
        }
    }

    return false;
}

// IMPORTANT: Any changes that propagate to react-color (i.e. changing or adjusting SketchPicker) may
// require updating the style-src content security policy hashes in public/manifest.json. Chrome will 
// generate these for you in an error message when you open dev tools.
//
// In other words:
// If you change this component, test that everything works correctly in a Cockpit environment!

const ColorPicker = (props: ColorPickerProps) => {
    const { color, onChange, textInputId, isDisabled, label } = props;

    const [isModalOpen, setModalOpen] = useState(false);
    const [textInputValue, setTextInputValue] = useState(getTextValue(color));
    useEffect(() => {
        setTextInputValue(getTextValue(color));
    }, [color]);

    const handleOpenModal = useCallback(() => { if (!isDisabled) { setModalOpen(true); } }, [isDisabled]);
    const handleCloseModal = useCallback(() => { setModalOpen(false); }, []);

    const handleChange = useCallback((color: any) => {
        if (isDisabled === true) { return; }

        const rgb = color.rgb;
        const colorTuple: [number, number, number] = [rgb.r, rgb.g, rgb.b];
        setTextInputValue(getTextValue(colorTuple));
        onChange(colorTuple);
    }, [isDisabled, onChange]);

    const handleBlur = useCallback(() => {
        if (isDisabled === true) { return; }

        // revert an invalid text field back to previous valid value when focus leaves
        if (!isTextValidColor(textInputValue)) {
            setTextInputValue(getTextValue(color));
            onChange(color);
        }
    }, [isDisabled, color, onChange, textInputValue]);

    const handleColorTextInputChange = useCallback((value: string) => {
        if (isDisabled === true) { return; }

        // set text input's internal value immediately
        setTextInputValue(value);

        // but apply the color value only if the input is a valid color
        if (isTextValidColor(value)) {
            const rgb = value.split(',');
            const color: [number, number, number] = [parseInt(rgb[0]), parseInt(rgb[1]), parseInt(rgb[2])];
            onChange(color);
        }
    }, [isDisabled, onChange]);

    // some of the code from: http://casesandberg.github.io/react-color/#examples
    return (
        <div className="color-picker">
            <div className="color-preview">
                <svg width={colorPreviewWidth} height={colorPreviewHeight} onClick={handleOpenModal}>
                    <rect x="0" y="0" width={colorPreviewWidth} height={colorPreviewHeight} fill={`rgb(${color[0]},${color[1]},${color[2]})`} />
                </svg>
            </div>

            {isModalOpen && (
                <div className="color-picker-popover">
                    <div className="color-picker-cover" onClick={handleCloseModal} />
                    <div className="color-picker-frame">
                        <div>
                            <div className="rgb-text-box">
                                <TextInput
                                    type="text"
                                    className="color-picker-input"
                                    id={textInputId}
                                    value={textInputValue}
                                    onChange={handleColorTextInputChange}
                                    onBlur={handleBlur}
                                    isDisabled={isDisabled === true} />
                            </div>
                            <SketchPicker color={{ r: color[0], g: color[1], b: color[2] }} onChange={handleChange} disableAlpha />
                            <div className="close-button">
                                <Button
                                    variant="primary"
                                    style={{ backgroundColor: `rgb(${color[0]},${color[1]},${color[2]})` }}
                                    onClick={handleCloseModal}>Close color picker</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ColorPicker;