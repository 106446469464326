import { z } from 'zod';

export const APP_VERSION_FILE_NAME = '/version.json';

export const NEW_VERSION_SNOOZE_TIME_IN_HOURS = 2;

/** Schema for app version info objects.
 * Expected zod parse input ({ commit, date, buildEnv }) is
 * parsed and transformed so that the 'date' property is renamed
 * to the more accurate 'timestamp'
 */
export const AppVersionInfoSchema = z.object({
    commit: z.string(),
    /** Input date of this version, transformed to a timestamp. */
    date: z.string().transform((val) => new Date(val).valueOf()),
    buildEnv: z.string(),
})
// transform schema so that 'date' is renamed to the more accurate 'timestamp'
.transform((input) => ({
    /** Commit hash of this version. */
    commit: input.commit,
    /** Timestamp (milliseconds after 1 January 1970 00:00:00 UTC) when this version was built. */
    timestamp: input.date,
    /** Build environment of this version. */
    buildEnv: input.buildEnv
}));

/** Models a current or a potential app version */
export type AppVersionInfo = z.infer<typeof AppVersionInfoSchema>;

/** Returns true if app version info looks valid. */
export function isVersionValid(version: AppVersionInfo): boolean {
    return !!(version.commit.length > 4
        && !isNaN(version.timestamp) && version.timestamp > 0
        && version.buildEnv);
}

/** Returns true if both app versions are equal, false otherwise. */
export function areVersionsEqual(a: AppVersionInfo, b: AppVersionInfo) {
    // don't compare dates (which may be NaN) if both versions are invalid
    return !isVersionValid(a) && !isVersionValid(b) ?
        a.commit === b.commit
        && a.buildEnv === b.buildEnv
        :
        a.commit === b.commit
        && a.timestamp === b.timestamp
        && a.buildEnv === b.buildEnv;
}

/** Returns a human-readable summary of an app version object. */
export function appVersionToString(appVersion: AppVersionInfo) {
    return `commit: ${appVersion.commit}, build date: ${new Date(appVersion.timestamp).toString()}, buildEnv: ${appVersion.buildEnv}`;
}
