
// Keys for window.localStorage. Stores data locally with no expiration date

const LatestActiveMsalAccount = "LATEST_ACTIVE_MSAL_ACCOUNT";

export function setLatestActiveMsalAccount(accountId: string | undefined) {
    if (accountId) {
        localStorage.setItem(LatestActiveMsalAccount, accountId);
    } else {
        localStorage.removeItem(LatestActiveMsalAccount);
    }
}

export function getLatestActiveMsalAccount(): string | undefined {
    const value = localStorage.getItem(LatestActiveMsalAccount) || undefined;
    return value;
}
