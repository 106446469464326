import { Dropdown, DropdownItem, DropdownToggle } from '@patternfly/react-core';
import React, { memo, useCallback } from 'react';
import { PhysicalProperty, PHYSICAL_PROPERTY_REL_ELEC_DENSITY, PHYSICAL_PROPERTY_REL_MASS_DENSITY } from '../../../store/contouring/contouring-types';

interface PhysicalPropertiesDropdownProps {
    onSelect: (physicalProperty: PhysicalProperty) => void,
    value: string,
    id?: string;
}

const supportedPhysicalProperties: { [x: string]: string } = {
    [PhysicalProperty.NotSet]: ' ',
    [PhysicalProperty.RelElecDensity]: PHYSICAL_PROPERTY_REL_ELEC_DENSITY,
    [PhysicalProperty.RelMassDensity]: PHYSICAL_PROPERTY_REL_MASS_DENSITY,
};

const dropdownItems = [
    <DropdownItem key={PhysicalProperty.NotSet}>&nbsp;</DropdownItem>,
    <DropdownItem key={PhysicalProperty.RelElecDensity}>{PHYSICAL_PROPERTY_REL_ELEC_DENSITY}</DropdownItem>,
    <DropdownItem key={PhysicalProperty.RelMassDensity}>{PHYSICAL_PROPERTY_REL_MASS_DENSITY}</DropdownItem>,
];

const getDropdownEnum = (str: string): PhysicalProperty => {
    switch (str) {
        case '':
            return PhysicalProperty.NotSet;
        case PHYSICAL_PROPERTY_REL_ELEC_DENSITY:
            return PhysicalProperty.RelElecDensity;
        case PHYSICAL_PROPERTY_REL_MASS_DENSITY:
            return PhysicalProperty.RelMassDensity;
        default:
            return PhysicalProperty.NotSet;
    }
}

const PhysicalPropertiesDropdown = (props: PhysicalPropertiesDropdownProps) => {
    const { value, onSelect, id } = props;

    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggle = useCallback((isOpen: boolean) => {
        setIsOpen(isOpen);
    }, []);

    const handleSelect = useCallback((value: any) => {
        setIsOpen(false);
        const physicalProp = getDropdownEnum(value.target.innerText);
        onSelect(physicalProp);
    }, [onSelect]);

    return (
        <Dropdown
            id={id}
            className="physical-properties-dropdown"
            dropdownItems={dropdownItems}
            toggle={(
                <DropdownToggle onToggle={handleToggle}>
                    {supportedPhysicalProperties[value]}
                </DropdownToggle>
            )}
            isOpen={isOpen}
            onSelect={handleSelect}
        />
    );
}

export default memo(PhysicalPropertiesDropdown);
