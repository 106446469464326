import React, { ReactNode } from 'react';
import { AngleRightIcon, AngleDownIcon } from '@patternfly/react-icons'

import './small-accordion-button.css';

interface SmallAccordionButtonProps {
    label?: string | ReactNode,
    className?: string,
    onClick?: () => void,
    isActive: boolean,
}

const SMALL_ACCORDION_BUTTON_BASE = 'mv-small-accordion-button';
const SMALL_ACCORDION_BUTTON_ACTIVE = 'mv-small-accordion-button-active';
const SMALL_ACCORDION_BUTTON_INACTIVE = 'mv-small-accordion-button-inactive';

const SmallAccordionButton = (props: SmallAccordionButtonProps) => {
    const { label, className, onClick, isActive } = props;

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    }

    const styles = `${className || ''} ${SMALL_ACCORDION_BUTTON_BASE} ${isActive ? SMALL_ACCORDION_BUTTON_ACTIVE : SMALL_ACCORDION_BUTTON_INACTIVE}`;

    return (
        <div onClick={handleOnClick} className={styles}><span className="label">{label}</span><span className="mv-expansion-icon">{isActive ? <AngleDownIcon /> : <AngleRightIcon />}</span></div>
    );
}

export default SmallAccordionButton;
