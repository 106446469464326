import React from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { getCurrentLangKey } from "../../i18n";
import { StoreState } from "../../store/store";
import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";

const HelpScreen = () => {

    const currentLangKey = getCurrentLangKey();
    const helpDocument = useSelector((state: StoreState) => appConfigSelectors.selectHelpDocument(state, currentLangKey));

    if (!helpDocument) {
        return null;
    }

    return (<div className="markdown-styles"><ReactMarkdown children={helpDocument} remarkPlugins={[remarkGfm]} /></div>)
}

export default HelpScreen;
