import React, { memo } from "react";

import './customization-label.css'

interface CustomizationLabelProps {
    friendlyName: string;
    modelName: string;
    customizationName: string;
    /** If true, show an asterisk after the name. Nothing is displayed after the name if false or not defined.  */
    isModified?: boolean;
}

const CustomizationLabel = (props: CustomizationLabelProps) => {
    const { friendlyName, modelName, customizationName, isModified } = props;

    return (
        <span className="customization-label">
            <span className="human-readable-label">{friendlyName}</span>
            <span className="label-parenthesis">(</span>
            <span className="model-name">{modelName}</span>
            <span className="model-customization-separator">.</span>
            <span className="customization-name">{customizationName}</span>
            <span className="label-parenthesis">)</span>

            {isModified && (<span className="is-modified">*</span>)}
        </span>
    );
}

export default memo(CustomizationLabel);
