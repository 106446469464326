import { has, isArray, isString, isNumber, isObject, isBoolean } from "lodash-es";


export default class ExpectedProp {
    public jsonProp: string;
    public modelProp: string;
    public type: string;

    constructor(jsonProp: string, modelProp: string, type: string) {
        this.jsonProp = jsonProp;
        this.modelProp = modelProp;
        this.type = type;
    }
}

export type SupportedProp = { propName: string, propType: 'string' | 'number' | 'array' | 'object' | 'boolean' | 'rgbArray' };

export function isExpectedProp(obj: any, propName: string, propType: 'string' | 'number' | 'array' | 'object' | 'boolean' | 'rgbArray'): boolean {
    if (has(obj, propName)) {
        const prop = obj[propName];
        switch (propType) {
            case 'string':
                return isString(prop);
            case 'number':
                return isNumber(prop);
            case 'array':
                return isArray(prop);
            case 'object':
                return isObject(prop);
            case 'boolean':
                return isBoolean(prop);
            case 'rgbArray':
                return isArray(prop) && prop.length === 3 && isNumber(prop[0]) && isNumber(prop[1]) && isNumber(prop[2]);
            default:
                break;
        }
    }

    return false;
}

export type AssertResult = { passed: boolean; validationError?: string; };

export function hasExpectedProps(obj: any, expectedProps: SupportedProp[]): { allPassed: boolean, results: AssertResult[] } {
    const results: AssertResult[] = expectedProps.map(expectedProp => isExpectedProp(obj, expectedProp.propName, expectedProp.propType) ?
        { passed: true } :
        { passed: false, validationError: `For '${expectedProp.propName}': expected '${expectedProp.propType}', got '${typeof obj['propName']}'` });

    return { allPassed: results.every(r => r.passed), results };
}

/**
 * Helper function for asserting that an object (e.g. JSON DTO) has expected properties.
 * @param obj The object to assert. Usually a JSON data-transfer object directly from a backend API.
 * @param expectedProps An array of properties and their types that must be found on the object.
 * @param assertMessage The error message that will be thrown if any of these assertions fail. Optional,
 * a default will be used if omitted.
 */
export function assertExpectedProps(obj: any, expectedProps: SupportedProp[], assertMessage?: string) {
    const assertResults = hasExpectedProps(obj, expectedProps);
    if (!assertResults.allPassed) {
        const message = assertMessage || 'Unexpected prop type(s) encountered.';
        console.error(message);
        console.log(assertResults.results.filter(r => !r.passed).map(r => r.validationError).join('\n'));
        console.log(obj);
        throw new Error(message);
    }
}
