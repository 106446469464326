const assertArrayIsDefinedDefaultAssertMessage = 'Array contains one or more undefined values!';
const assertObjectIsDefinedDefaultAssertMessage = 'Object is undefined!';

/** Asserts that array contains only defined values. Useful for e.g. making typescript realise that selectors from EntityState
 * redux collections do not return undefined objects.
 */
export function assertArrayIsDefined<T>(arr: Array<T | undefined>, assertMessage?: string): asserts arr is Array<NonNullable<T>> {
    if (arr.some(a => a === undefined)) { throw new Error(assertMessage || assertArrayIsDefinedDefaultAssertMessage); }
};

/** Asserts that given object is not undefined. */
export function assertIsDefined<T>(obj: T | undefined, assertMessage?: string): asserts obj is NonNullable<T> {
    if (obj === undefined || obj === null) { throw new Error(assertMessage || assertObjectIsDefinedDefaultAssertMessage); }
}
