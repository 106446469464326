import { PageSection, PageSectionVariants, TextContent, Text, Alert, Form, FormGroup, TextInput, TextArea } from '@patternfly/react-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './about-page.css';
import { DISPLAY_VERSION, getDefaultAppName } from '../../environments';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { labelingFetched } from '../../store/appVersion/appVersionSlice';
import { appVersionToString } from '../../store/appVersion/appVersionTypes';


const AboutPage = () => {

    // reload labeling whenever the page is reloaded
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(labelingFetched());
    }, [dispatch]);

    const productLabel = useSelector((state: StoreState) => state.appVersion.productLabel);
    const productLabelError = useSelector((state: StoreState) => state.appVersion.productLabelError);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const appVersion = useSelector((state: StoreState) => state.appVersion.version);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    // print version information to console when about page is opened
    useEffect(() => {
        if (productLabel) {
            console.log(productLabel);
        }
        if (appVersion) {
            console.log(appVersionToString(appVersion));
        }
    }, [appVersion, productLabel]);

    return (
        <>
            <PageSection variant={PageSectionVariants.default}>
                <TextContent>
                    <Text component="h1">{t('page.about.title')}</Text>
                </TextContent>
            </PageSection>

            {appConfig && appConfig.showErrorsInUi && productLabelError !== null && (
                <PageSection>
                    <Alert variant="danger" isInline title="An error occurred when trying to retrieve labeling information">
                        <p>An error occurred when trying to retrieve labeling information:</p>
                        <p>{productLabelError}</p>
                    </Alert>
                </PageSection>
            )}

            <PageSection variant={PageSectionVariants.light}>
                {productLabel !== null && (
                    <PageSection variant={PageSectionVariants.light} className="limit-width">
                        <div className="labeling">
                            <TextContent className="main-info">
                                <Text component="h2">{productLabel.manufacturerModelName}</Text>
                                <Text component="p">{productLabel.manufacturer}</Text>
                                {productLabel.description && (<Text component="p" className="description">{productLabel.description}</Text>)}
                            </TextContent>

                            <Form isHorizontal>
                                <FormGroup
                                    label="UDI"
                                    fieldId="udi-readonly" >
                                    <TextInput value={productLabel.udi || ''} type="text" isReadOnly id="udi-readonly" />
                                </FormGroup>
                                <FormGroup
                                    label={t('page.about.labelingContainer')}
                                    fieldId="labeling-readonly" >
                                    <TextArea
                                        readOnly
                                        id="labeling-readonly"
                                        value={productLabel.labelingAsString}
                                        resizeOrientation='vertical'
                                        className="text-editor-area"
                                        spellCheck="false"
                                        rows={8}
                                    />
                                </FormGroup>
                                {appVersion && (
                                    <FormGroup
                                        label={t('appTitle')}
                                        fieldId="app-version-readonly" >
                                        <TextInput value={`${DISPLAY_VERSION} (${appVersion.commit})`} type="text" isReadOnly id="app-version-readonly" />
                                    </FormGroup>
                                )}
                                {productLabel.backendVersionAsString && (
                                    <FormGroup
                                        label={t('page.about.backendAndWorkersAppName')}
                                        fieldId="backend-version-readonly" >
                                        <TextArea
                                            readOnly
                                            id="backend-version-readonly"
                                            value={productLabel.backendVersionAsString}
                                            resizeOrientation='vertical'
                                            className="text-editor-area"
                                            spellCheck="false"
                                            rows={7}
                                        />
                                    </FormGroup>
                                )}
                                {deploymentInfo && deploymentInfo.documentLinks && deploymentInfo.documentLinks.length > 1 &&
                                    <FormGroup
                                        label={t('settings.documents')}
                                        fieldId="terms-and-conditions"
                                        className='terms-conditions-list'>
                                        {deploymentInfo.documentLinks.map((link, index) => (
                                            <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="terms-document-link">
                                                {link.label}
                                            </a>
                                        ))
                                        }
                                    </FormGroup>
                                }
                            </Form>
                        </div>
                    </PageSection>
                )}
            </PageSection>
        </>
    );
}

export default AboutPage;