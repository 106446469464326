import { call, put } from "typed-redux-saga";
import ConfigurationClient from "../../web-apis/configuration-client";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { DoseCustomizationEntities } from "./dose-types";
import { isInstanceOfError } from "../../util/errors";
import { modelCustomizationsSet } from "./doseSlice";


export function* fetchDoseCustomizationsSaga(appClient: MVisionAppClient | undefined) {
    const client = new ConfigurationClient();
    let doseCustomizations: DoseCustomizationEntities | null = null;
    let error = null;

    try {
        doseCustomizations = appClient === undefined ? null : yield* call(async () => client.fetchDoseCustomizationsAsync(appClient));
        if (doseCustomizations !== null) {
            console.log('TODO_2: check which models are available in license')
            // const fetchModels = yield* call(async () => client.fetchAvailableModelsAsync(appClient));
            // if (fetchModels !== undefined) {
            //     // if the available models collection is in use, mark which segmentation models are unavailable
            //     for (const model of doseCustomizations.models) {
            //         model.isAvailable = fetchModels.availableModels.includes(model.modelName);
            //     }
            // }
        }
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (isInstanceOfError(error)) {
        yield* put(modelCustomizationsSet({ customizations: null, errorMessage: error.message || 'Unspecified error.' }));
        return false;
    } else {
        yield* put(modelCustomizationsSet({ customizations: doseCustomizations }));
        return true;
    }
}