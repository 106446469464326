import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessRights, getInitialUserAccessRights } from './access-rights';
import UserSettings, { getInitialUserSettings } from './user-settings';

export type UserConfigSliceState = {
    /** Listing of applications that user has access to. */
    accessRights: AccessRights,

    /** Latest access Rights error, if any */
    accessRightsError: string | null,

    /** True if deprecated models are shown, false otherwise. */
    areDeprecatedModelsShown: boolean,

    /** True if models not within current license are shown, false otherwise. */
    areNonLicensedModelsShown: boolean,

    /** True if models hidden by current user are shown, false otherwise. */
    areUserHiddenModelsShown: boolean,

    /** Global, persistent settings for this user.
     * NOTE: these are not yet used for anything as of v1.2.7. */
    userSettings: UserSettings,

    /** Error received when trying to fetch user settings, or null. TODO: Consider a more simplified overall error messaging state handling. */
    userSettingsError: string | null,


};

export const initialState: UserConfigSliceState = {
    accessRights: getInitialUserAccessRights(),
    accessRightsError: null,
    areDeprecatedModelsShown: false,
    areNonLicensedModelsShown: false,
    areUserHiddenModelsShown: false,
    userSettings: getInitialUserSettings(),
    userSettingsError: null,
};

/** User-related redux store slice.  */
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        /** 
         * Sets user access rights to given access rights object.
         * @param action payload.accessRights: AccessRights object to use.
         * @param action payload.errorMessage: optional error message.
         */
        accessRightsSet(state, action: PayloadAction<{ accessRights: AccessRights | null, errorMessage?: string }>) {
            const { accessRights, errorMessage } = action.payload;

            if (accessRights !== null) {
                state.accessRights = accessRights;
            }

            state.accessRightsError = errorMessage !== undefined ? errorMessage : null;
        },

        /**
         * Show deprecated models in UI.
         */
        deprecatedModelsShown(state) {
            state.areDeprecatedModelsShown = true;
        },

        /**
         * Hide deprecated models from UI.
         */
        deprecatedModelsHidden(state) {
            state.areDeprecatedModelsShown = false;
        },

        /**
         * Show user-hidden models in UI.
         */
        userHiddenModelsShown(state) {
            state.areUserHiddenModelsShown = true;
        },

        /**
         * Hide user-hidden models from UI.
         */
        userHiddenModelsHidden(state) {
            state.areUserHiddenModelsShown = false;
        },

        /**
         * Show models user does not have license to in UI.
         */
        nonLicensedModelsShown(state) {
            state.areNonLicensedModelsShown = true;
        },

        /**
         * Hide models user does not have license to from UI.
         */
        nonLicensedModelsHidden(state) {
            state.areNonLicensedModelsShown = false;
        },

        /**
         * Signals that user settings should be fetched from backend.
         */
        userSettingsFetched() {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Stores fetched user settings.
         * @param action.userSettings Settings for current user to store.
         * @param action.errorMessage Optional error message.
         */
        userSettingsSet(state, action: PayloadAction<{ userSettings: UserSettings | null, errorMessage?: string }>) {
            const { userSettings, errorMessage } = action.payload;

            if (userSettings !== null) {
                state.userSettings = userSettings;
            }
    
            state.userSettingsError = errorMessage !== undefined ? errorMessage : null;
        },

        /**
         * Updates user settings in the store.
         * @param action A partial UserSettings object. Anything in this object is used to override
         * any values in the currently-stored user settings object.
         */
        userSettingsUpdated(state, action: PayloadAction<Partial<UserSettings>>) {
            const settingsToUpdate = action.payload;
            state.userSettings = Object.assign({}, state.userSettings, settingsToUpdate);
        },
    },
    selectors: {
        selectAccessRights: (state) => state.accessRights,
        selectAccessRightsError: (state) => state.accessRightsError,
        selectAreDeprecatedModelsShown: (state) => state.areDeprecatedModelsShown,
        selectAreNonLicensedModelsShown: (state) => state.areNonLicensedModelsShown,
        selectAreUserHiddenModelsShown: (state) => state.areUserHiddenModelsShown,
    }

});

export const {
    accessRightsSet,
    deprecatedModelsShown,
    deprecatedModelsHidden,
    userHiddenModelsShown,
    userHiddenModelsHidden,
    nonLicensedModelsShown,
    nonLicensedModelsHidden,
    userSettingsFetched,
    userSettingsSet,
    userSettingsUpdated,
} = userSlice.actions;

// uncomment if these are needed locally
// const localSelectors = userSlice.getSelectors();

export const { getInitialState, selectors: userSelectors } = userSlice;

export default userSlice.reducer;
