import React, { useCallback, useState } from 'react';
import { FormGroup, TextInput, Form, TextArea, InputGroup, Label, Button } from '@patternfly/react-core';
import CopyIcon from '@patternfly/react-icons/dist/js/icons/copy-icon';
import { format, formatISO } from 'date-fns';

import { LicenseStatus } from '../../store/license/license-status';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { DeploymentMode } from '../../store/appConfig/appDeploymentInfoTypes';
import { unixTimestampToDate } from '../../util/date-convert';

interface CurrentLicenseProps {
    licenseStatus: LicenseStatus,
}

const CurrentLicense = (props: CurrentLicenseProps) => {
    const { licenseStatus } = props;

    const { t } = useTranslation();
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const installationIdFieldRef = React.useRef(null);
    const [showCopiedToClipboardLabelStyle, setCopiedToClipboardLabelStyle] = useState('hide');

    const handleCopyToClipboardClick = useCallback((event: any) => {
        if (!installationIdFieldRef || !installationIdFieldRef.current) {
            return;
        }

        (installationIdFieldRef!.current as any).select();
        document.execCommand('copy');
        event.target.focus();
        setCopiedToClipboardLabelStyle('hide');
        setTimeout(() => setCopiedToClipboardLabelStyle('fade-in-out'), 1);
    }, []);

    const licensedModels = !licenseStatus ? '' : (licenseStatus.modelNames?.length > 0 ? licenseStatus.modelNames.join(', ') : t('licensePage.allFeaturesAvailable'));
    const hasUnlimitedUse = licenseStatus.unlimitedUseExpiryTime.valueOf() !== 0;
    // const unlimitedUseExpiryTime = hasUnlimitedUse ?  formatISO(licenseStatus.unlimitedUseExpiryTime, { representation: 'date' }) : 'No unlimited use';
    // const unlimitedUseExpiryTimePretty = hasUnlimitedUse ? `Unlimited use until ${format(licenseStatus.unlimitedUseExpiryTime, 'iiii MMMM do y')}` : 'No unlimited use';

    const { id } = licenseStatus;

    // convert timestamp to Date objects
    const creationTime = unixTimestampToDate(licenseStatus.creationTime);
    const expiryTime = unixTimestampToDate(licenseStatus.expiryTime);

    return (
        <Form isHorizontal>
            <FormGroup
                label={t('licensePage.licenseCreatedOn')}
                fieldId={`created-readonly-${id}`}
                title={`This license was created on ${format(creationTime, 'iiii MMMM do y')}.`} >
                <TextInput
                    value={formatISO(creationTime, { representation: 'date' })}
                    type="text" isReadOnly id={`created-readonly-${id}`} />
            </FormGroup>
            <FormGroup
                label={t('licensePage.licenseValidUntil')}
                fieldId={`expires-readonly-${id}`}
                title={`This license is valid until ${format(expiryTime, 'iiii MMMM do y')}.`} >
                <TextInput
                    value={formatISO(expiryTime, { representation: 'date' })}
                    type="text" isReadOnly id={`expires-readonly-${id}`} />
            </FormGroup>
            <FormGroup
                label={t('licensePage.quotaRemaining')}
                fieldId={`quota-readonly-${id}`}
                title={hasUnlimitedUse ? 'This license has unlimited use.' : `Quota remaining in this license: ${licenseStatus.quotaLeft} / total: ${licenseStatus.quota}`} >
                <TextInput value={hasUnlimitedUse ? t('licensePage.unlimitedUse') : `${licenseStatus.quotaLeft} / ${licenseStatus.quota}`} type="text" isReadOnly id={`quota-readonly-${id}`} />
            </FormGroup>
            <FormGroup
                label={t('licensePage.licensedFeatures')}
                fieldId={`licensed-features-readonly-${id}`}
                title={`Licensed features: ${licensedModels}`} >
                <TextArea
                    value={licensedModels}
                    type="text"
                    onChange={() => { }}
                    id={`licensed-features-readonly-${id}`}
                    spellCheck="false"
                    resizeOrientation='vertical'
                    className="disabled-text-area" />
            </FormGroup>
            {/* <FormGroup
                label="Unlimited use until"
                fieldId="valid-until-readonly"
                title={unlimitedUseExpiryTimePretty} >
                <TextInput
                    value={unlimitedUseExpiryTime}
                    type="text" isReadOnly id="valid-until-readonly" />
            </FormGroup> */}
            <FormGroup
                label={t('licensePage.contouringsDone')}
                fieldId={`contourings-done-readonly-${id}`}
                title={`Contourings done with this license: ${licenseStatus.contouringsDone}`} >
                <TextInput value={licenseStatus.contouringsDone} type="text" isReadOnly id={`contourings-done-readonly-${id}`} />
            </FormGroup>
            {deploymentInfo && deploymentInfo.deploymentMode === DeploymentMode.Local && (
                <FormGroup
                    label="Installation ID"
                    fieldId={`installation-id-readonly-${id}`} >
                    <InputGroup>
                        <TextInput
                            ref={installationIdFieldRef}
                            value={licenseStatus.installationId}
                            type="text"
                            isReadOnly
                            id={`installation-id-readonly-${id}`}
                            onFocus={() => installationIdFieldRef && installationIdFieldRef.current && (installationIdFieldRef!.current as any).select()}
                        />

                        <span className="copied-to-clipboard-label-container">
                            <span
                                className={`copied-to-clipboard-label ${showCopiedToClipboardLabelStyle}`}>
                                <Label
                                    color="green"
                                    onClose={() => setCopiedToClipboardLabelStyle('quick-fade-out')}
                                >Copied to clipboard</Label>
                            </span>
                        </span>

                        <Button variant="control" title="Copy Installation ID to clipboard" onClick={handleCopyToClipboardClick}>
                            <CopyIcon />
                        </Button>
                    </InputGroup>
                </FormGroup>
            )}
        </Form>
    );
}

export default CurrentLicense;
