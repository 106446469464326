import { isArray, has } from "lodash-es";

export const UNAUTHENTICATED_APP = 'unauthenticated';

/** Configuration target aka an MVision app client (i.e. daemon/RTViewer user, etc.) that is owned/managed by an Azure User/App. */
export type MVisionAppClient = {
    /** App ID of the client. */
    userId: string;
    /** Extra details for the client. Not relevant in ConfigUI. */
    userInfo: string;
    /** Name of the client. */
    userName: string;
    /** True if this client is in local mode, false otherwise. */
    localMode: boolean;
}

export const getUserName = (appClient: MVisionAppClient): string => appClient.localMode ? 'Local' : appClient.userName;

/** Returns true if a user name is available for this configuration target -- it is both defined and not a local configuration target. */
export const hasUserName = (appClient: MVisionAppClient | undefined): boolean => appClient !== undefined && !appClient.localMode;

// /** Type guard function for MVisionAppClient type. */
// export function isMVisionAppClient(obj: any): obj is MVisionAppClient {
//     return (
//         obj &&
//         typeof obj === 'object' &&
//         typeof obj['userId'] === 'string' &&
//         typeof obj['userInfo'] === 'string' &&
//         typeof obj['userName'] === 'string'
//     );
// }

/** Converts a valid JSON object into a client list, or throws if format is not valid. */
export const convertJsonObjectToMVisionClientList = (clientListJsonArray: any): MVisionAppClient[] => {
    if (!clientListJsonArray || !isArray(clientListJsonArray)) {
        throw new Error('Invalid JSON object provided for Client List conversion.');
    }

    const clientList: MVisionAppClient[] = [];
    for (const clientEntry of clientListJsonArray) {
        if (has(clientEntry, 'user_id') && has(clientEntry, 'user_info') && has(clientEntry, 'user_name')) {
            const userId = clientEntry['user_id'];
            const userInfo = clientEntry['user_info'];
            const userName = clientEntry['user_name'];
            const isLocalEntry = clientListJsonArray.length === 1 && userId === UNAUTHENTICATED_APP && userName === UNAUTHENTICATED_APP;
            const client = { userId, userInfo, userName, localMode: isLocalEntry };
            clientList.push(client);
        } else {
            console.error('Expected a client list entry item, got:')
            console.error(clientEntry);
            throw new Error('Invalid client list entry item');
        }
    }

    return clientList;
}
