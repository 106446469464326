import { DataListItem, DataListItemRow, DataListItemCells, DataListCell, Stack, StackItem, Toolbar, ToolbarContent, ToolbarItem, Button, Form } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlusIcon } from '@patternfly/react-icons';
import ModelSelectionAeTitleRule from './AeTitleRule';
import ModelSelectionDicomRule from './DicomRule';
import { ModelSelectionUndoState } from './SelectionRulePage';
import { literalTextToRegex } from '../../util/string-convert';
import CustomizationLabel from '../../components/customization-label';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../store/store';
import { aeTitleRuleAdded, contouringSelectors, dicomAttributeRuleAdded, dicomRuleAdded } from '../../store/contouring/contouringSlice';
import { createNewAeTitleRule, generateNewId, createNewDicomAttributeRule, createNewDicomRule } from '../../store/global-types/customization-helpers';

interface SelectionRulesProps {
    customizationBaseId: string,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    heading: string,
    hideCustomizationName?: boolean,
    showMultipleOutputsWarning: boolean,
    /** If true, don't show undo UI options. */
    disableUndo?: boolean,
}

const SelectionRules = (props: SelectionRulesProps) => {
    const { customizationBaseId, setUndoState, heading, hideCustomizationName, showMultipleOutputsWarning, disableUndo } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const customizationBase = useSelector((state: StoreState) => contouringSelectors.selectCustomizationBaseById(state, customizationBaseId));
    const segmentationModel = useSelector((state: StoreState) => customizationBase && contouringSelectors.selectModelById(state, customizationBase.modelId));

    const handleAddAeTitleRuleClick = useCallback(() => {
        const newAeTitleRule = createNewAeTitleRule(undefined, null, undefined, undefined,
            undefined, customizationBaseId, true);
        dispatch(aeTitleRuleAdded(newAeTitleRule));
    }, [dispatch, customizationBaseId]);

    const handleAddDicomRuleClick = useCallback(() => {
        const dicomRuleId = generateNewId();
        const newDicomAttribute = createNewDicomAttributeRule('', literalTextToRegex(''), dicomRuleId, true);
        const newDicomRule = createNewDicomRule([newDicomAttribute.id], dicomRuleId,
            customizationBaseId, null, undefined, true);
        dispatch(dicomRuleAdded(newDicomRule));
        dispatch(dicomAttributeRuleAdded(newDicomAttribute));
    }, [dispatch, customizationBaseId]);

    if (customizationBase === undefined || segmentationModel === undefined) {
        return null;
    }

    const dataListCells = [
        <DataListCell key="modelRules" width={4}>
            <div>
                <Form>
                    <Stack>
                        {showMultipleOutputsWarning && (
                            <div className="multiple-outputs-warning">
                                Note: This customization contains multiple target files. Adjusting the selection triggers here will affect the entire
                                customization <span className="multiple-outputs-base">"{segmentationModel ? `${segmentationModel.modelName}.` : ''}{customizationBase.customizationName}"</span>, not just this target file.
                            </div>
                        )}
                        <div>
                            {customizationBase.aeTitleRules.map((aeTitleRuleId, i) => (
                                <ModelSelectionAeTitleRule aeTitleRuleId={aeTitleRuleId} key={aeTitleRuleId} runningNumber={i + 1} setUndoState={setUndoState} disableUndo={disableUndo} />
                            ))}
                        </div>
                        <div>
                            {customizationBase.dicomRules.map((dicomRuleId, i) => (
                                <ModelSelectionDicomRule dicomRuleId={dicomRuleId} key={dicomRuleId} runningNumber={i + 1} setUndoState={setUndoState} disableUndo={disableUndo} />
                            ))}
                        </div>
                        <StackItem className="new-rule-toolbar model-selection-toolbar">
                            <Toolbar>
                                <ToolbarContent>
                                    <ToolbarItem>
                                        <Button
                                            isSmall
                                            variant="secondary"
                                            icon={<PlusIcon />}
                                            // isDisabled={isToolbarDisabled}
                                            onClick={handleAddDicomRuleClick}>{t('selectionPage.addDicomRule')}</Button>
                                    </ToolbarItem>
                                    <ToolbarItem>
                                        <Button
                                            isSmall
                                            variant="tertiary"
                                            icon={<PlusIcon />}
                                            // isDisabled={isToolbarDisabled}
                                            className="save-changes-button"
                                            onClick={handleAddAeTitleRuleClick}>{t('selectionPage.addAeTitleRule')}</Button>
                                    </ToolbarItem>
                                </ToolbarContent>
                            </Toolbar>
                        </StackItem>
                    </Stack>
                </Form>
            </div>
        </DataListCell>
    ];

    if (!hideCustomizationName) {
        dataListCells.unshift(
            <DataListCell key="modelName" width={1}>
                <div className="selection-customization-label">
                    <CustomizationLabel friendlyName={segmentationModel.label} modelName={segmentationModel.modelName} customizationName={customizationBase.customizationName} isModified={customizationBase.isModified} />
                </div>
            </DataListCell>
        );
    }

    return (
        <>
            <div id={heading} className="customization-rule-jump-heading"></div>
            <DataListItem aria-labelledby={`Model selection rules for customization ${customizationBase.customizationName}`}>
                <DataListItemRow className="model-selection-model-rules">
                    <DataListItemCells dataListCells={dataListCells} />
                </DataListItemRow>
            </DataListItem>
        </>
    );
}

export default SelectionRules;
