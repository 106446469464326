import { ReactNode } from "react";
import { ModelVisibility, Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DEFAULT_SUBSCRIPTION, DicomRule, DicomAttributeRule, ModelType, CustomizationTreeViewDataItem, DEFAULT_MODEL_CUSTOMIZATION_NAME } from "./customization-types";
import { v4 as uuidv4 } from 'uuid';
import { TreeViewDataItem } from "@patternfly/react-core";
import { getIconByType, TreeViewIconType } from "../../components/icon-helper";

/** JSON attribute that is inserted into outgoing customization JSON to make help identifying error message target components easier. */
export const UI_ID_ATTRIBUTE = 'ui_id';

const MODEL_VISIBILITY_JSON_VALUE_DEFAULT = 'default';
const MODEL_VISIBILITY_JSON_VALUE_ALWAYS_SHOW = 'always_show';
const MODEL_VISIBILITY_JSON_VALUE_ALWAYS_HIDE = 'always_hide';

export const createNewModel = (modelName: string,
    modelType: ModelType,
    label: string,
    bodyPart: string,
    description: string,
    visibility: ModelVisibility,
    tags: string[],
    customizationIds: string[],
    isDeprecated: boolean = false,
    id?: string,): Model => {
    return {
        id: id || generateNewId(),
        modelType: modelType,
        modelName: modelName,
        label: label,
        bodyPart: bodyPart,
        description: description,
        visibility: visibility,
        tags: tags,
        customizations: customizationIds,
        // TODO: add model_type support
        isModified: false,
        isAvailable: true,
        isDeprecated: isDeprecated,
    };
};

export const createNewModelCustomizationBase = (customizationName: string, description: string, outputIds: string[], aeTitleRuleIds: string[], dicomRuleIds: string[], modelId: string, id?: string, isModified?: boolean): CustomizationBase => ({
    id: id || generateNewId(),
    customizationName: customizationName,
    description: description,
    outputs: outputIds,
    aeTitleRules: aeTitleRuleIds,
    dicomRules: dicomRuleIds,
    modelId: modelId,
    isModified: isModified || false,
});

export const createNewMetadataItem = (attribute: string, value: string, modelCustomizationOutputId?: string): OutputMetadataItem => ({
    id: generateNewId(),
    attribute: attribute,
    value: value,
    isModified: false,
    modelCustomizationOutputId: modelCustomizationOutputId,
    scrollToView: false,
});

export const createNewAeTitleRule = (action?: string, aggregation?: string | null, subscription?: string,
    isEditable?: boolean, id?: string, modelCustomizationBaseId?: string, isNew?: boolean, isModified?: boolean,): AeTitleRule => ({
        id: id || generateNewId(),
        action: action || '',
        modelCustomizationBaseId: modelCustomizationBaseId || undefined,
        aggregation: aggregation || null,
        subscription: subscription || DEFAULT_SUBSCRIPTION,
        isEditable: isEditable || true,
        isModified: isModified || false,
        isNew: isNew || false,
        isValid: true,
        validationMessage: undefined,
    });

export const createNewDicomRule = (dicomAttributeIds: string[], id?: string, modelCustomizationBaseId?: string,
    aggregation?: string | null, subscription?: string, isNew?: boolean, isModified?: boolean): DicomRule => ({
        id: id || generateNewId(),
        modelCustomizationBaseId: modelCustomizationBaseId || undefined,
        dicomAttributes: dicomAttributeIds,
        aggregation: aggregation || null,
        subscription: subscription || DEFAULT_SUBSCRIPTION,
        isEditable: true,
        isModified: isModified || false,
        isNew: isNew || false,
        isValid: true,
        validationMessage: undefined,
    });

export const createNewDicomAttributeRule = (attribute: string, value: string, parentDicomRuleId: string, isNew?: boolean, id?: string): DicomAttributeRule => ({
    id: id || generateNewId(),
    attribute: attribute,
    value: value,
    parentDicomRuleId: parentDicomRuleId,
    isNew: isNew || false,
    isModified: false,
    isValid: true,
});

/** Parses model visibility enum for view models from a JSON value. */
export const parseModelVisibility = (modelVisibilityJson: string | undefined): ModelVisibility => {
    if (!!modelVisibilityJson) {
        if (modelVisibilityJson === MODEL_VISIBILITY_JSON_VALUE_ALWAYS_SHOW) { return ModelVisibility.AlwaysShown; }
        else if (modelVisibilityJson === MODEL_VISIBILITY_JSON_VALUE_ALWAYS_HIDE) { return ModelVisibility.AlwaysHidden; }
        else if (modelVisibilityJson === MODEL_VISIBILITY_JSON_VALUE_DEFAULT) { return ModelVisibility.Default; }
        else { throw new Error(`Unsupported model visibility value: ${modelVisibilityJson}`); }
    }

    return ModelVisibility.Default;
}

/** Converts view model visibility enum into matching JSON value for backend. */
export const getModelVisibilityForJson = (modelVisibility: ModelVisibility): string => {
    switch (modelVisibility) {
        case ModelVisibility.Default:
            return MODEL_VISIBILITY_JSON_VALUE_DEFAULT;
        case ModelVisibility.AlwaysShown:
            return MODEL_VISIBILITY_JSON_VALUE_ALWAYS_SHOW;
        case ModelVisibility.AlwaysHidden:
            return MODEL_VISIBILITY_JSON_VALUE_ALWAYS_HIDE;
        default:
            throw new Error('Unsupported model visibility value');
    }
}

export const generateNewId = (): string => {
    return uuidv4();
}

export const duplicateModelCustomizationMetadataItem = (metadata: OutputMetadataItem, outputId?: string): OutputMetadataItem => {
    return {
        id: generateNewId(),
        attribute: metadata.attribute,
        value: metadata.value,
        isModified: true,
        modelCustomizationOutputId: outputId,
        scrollToView: false,
    };
}

/** Enables (or disables) customization tree view behaviour where selecting a branch item (such as a model name, e.g. 'Breast + Abdomen CT' or
 * a customization name, e.g. 'default') select the leaf node (e.g. 'mv-rt.dcm'). This will also have an effect on certain corner cases of what
 * the customization sidebar drawer remembers from the tree view selection when the drawer is collapsed and re-expanded.
 */
export const ENABLE_TREE_VIEW_BRANCHES_SELECT_LEAF_ITEM_BEHAVIOUR = false;

/** Helper function for convertTreeViewDataIntoItemHierarchy. Returns true if the item specified here
 * should be rendered and false if it (and its children) should be hidden.
 */
export const showCustomizationTreeViewDataItem = (
    item: CustomizationTreeViewDataItem,
    includeNonLicensedModels: boolean,
    includeDeprecatedModels: boolean,
    includeUserHiddenModels: boolean,
    ignoreValidity: boolean = false): boolean => {

    // always show items that are set as 'always shown'
    if (item.modelVisibility === ModelVisibility.AlwaysShown) { return true; }

    // always show items with errors so user can identify and fix them
    // 'ignoreValidity' is used when double-checking if the item would've been hidden
    // if it had been valid
    if (!ignoreValidity && !item.isValid) { return true; }

    // only show items set as 'always hide' if user has opted to show hidden items
    if (item.modelVisibility === ModelVisibility.AlwaysHidden) { return includeUserHiddenModels; }

    // don't show items that aren't available unless specific filtering setting is on
    if (!item.isAvailable && !includeNonLicensedModels) { return false; }

    // don't show deprecated items unless specific filtering setting is on
    if (item.isDeprecated && !includeDeprecatedModels) { return false; }

    return true;
}

/** Helper function for converting hierarchical customization data (as generated in a separate selectors in
 * model type slices, such as selectCustomizationTreeViewItems in contouringSelectors.ts) into an object 
 * hierarchy that can be read by a Patternfly TreeView component.
 */
export const convertTreeViewDataIntoItemHierarchy = (
    customizationHierarchy: CustomizationTreeViewDataItem[],
    getTreeViewItemName: (item: CustomizationTreeViewDataItem, noLicenseWarning: string) => ReactNode,
    noLicenseWarning: string,
    headerItems: TreeViewDataItem[],
    includeNonLicensedModels: boolean,
    includeDeprecatedModels: boolean,
    includeUserHiddenModels: boolean
): TreeViewDataItem[] => {

    // change headerItems name when a file is modified using getTreeViewItemName function
    for (const headerItem of headerItems) {
        const customizationItem = customizationHierarchy.find(c => c.id === headerItem.id);
        if (customizationItem && customizationItem.isModified) {
            headerItem.name = getTreeViewItemName(customizationItem, noLicenseWarning);
        }
    }

    // generate treeview data from memoized customization item hierarchy
    const data: TreeViewDataItem[] = [...headerItems];

    for (const bodyPartItem of customizationHierarchy) {
        if (!showCustomizationTreeViewDataItem(bodyPartItem, includeNonLicensedModels, includeDeprecatedModels, includeUserHiddenModels)) {
            continue;
        }

        let bodyPartItemData = data.find(p => p.id === bodyPartItem.id);
        if (!bodyPartItemData) {
            bodyPartItemData = {
                name: getTreeViewItemName(bodyPartItem, noLicenseWarning),
                id: bodyPartItem.id,
                children: [],

            };
            data.push(bodyPartItemData);
        } else if (!bodyPartItemData.children) {
            bodyPartItemData.children = [];
        }

        for (const modelItem of bodyPartItem.children) {
            if (!showCustomizationTreeViewDataItem(modelItem, includeNonLicensedModels, includeDeprecatedModels, includeUserHiddenModels)) {
                continue;
            }

            const modelItemData: TreeViewDataItem = {
                name: getTreeViewItemName(modelItem, noLicenseWarning),
                id: modelItem.id,
                children: modelItem.children.length > 0 ? [] : undefined,
                icon: getIconByType(TreeViewIconType.Folder),
                expandedIcon: getIconByType(TreeViewIconType.Folder),
            };

            if (ENABLE_TREE_VIEW_BRANCHES_SELECT_LEAF_ITEM_BEHAVIOUR && modelItem.children.length > 0) {
                // put either the 'default' or first customization base as the open id for the segmentation model
                const defaultCustomization = modelItem.children.find(c => c.label === DEFAULT_MODEL_CUSTOMIZATION_NAME);
                modelItemData.id = defaultCustomization ? defaultCustomization.id : modelItem.children[0].id;
            }

            for (const baseItem of modelItem.children) {
                if (!showCustomizationTreeViewDataItem(baseItem, includeNonLicensedModels, includeDeprecatedModels, includeUserHiddenModels)) {
                    continue;
                }

                let baseItemData: TreeViewDataItem = {
                    name: getTreeViewItemName(baseItem, noLicenseWarning),
                    id: baseItem.id,
                    children: baseItem.children.length > 0 ? [] : undefined,
                    // always open file-level items
                    defaultExpanded: true,
                    icon: getIconByType(TreeViewIconType.Gear)
                };

                if (ENABLE_TREE_VIEW_BRANCHES_SELECT_LEAF_ITEM_BEHAVIOUR && baseItem.children.length > 0) {
                    // put first file in the list as the open id for the customization base
                    baseItemData.id = baseItem.children[0].id;

                    // also finish up setting the correct id for the segmentation model
                    if (modelItemData.id === baseItem.id) {
                        modelItemData.id = baseItem.children[0].id;
                    }
                }

                for (const outputItem of baseItem.children) {
                    if (!showCustomizationTreeViewDataItem(outputItem, includeNonLicensedModels, includeDeprecatedModels, includeUserHiddenModels)) {
                        continue;
                    }

                    const outputItemData: TreeViewDataItem = {
                        name: baseItem.children.length > 1 ? getTreeViewItemName(outputItem, noLicenseWarning) : baseItemData.name,
                        id: outputItem.id,
                        icon: baseItem.children.length > 1 ? getIconByType(TreeViewIconType.File) : getIconByType(TreeViewIconType.Gear),
                    };

                    // If the children is just 1 skip rendering the output item and instead assign the output item to the base Item
                    if (baseItem.children.length > 1) {
                        baseItemData.children!.push(outputItemData);
                    }
                    else {
                        baseItemData = outputItemData;
                    }
                }

                modelItemData.children!.push(baseItemData);
            }

            bodyPartItemData.children!.push(modelItemData);
        }
    }

    return data;
}
