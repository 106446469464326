//// This file contains 'errors' that we can safely store in the redux store (regular errors are classes and should NOT be stored in redux store)

import { generateNewId } from "./customization-helpers";
import { FormValidationErrorType } from "./form-errors";
import { DoseCustomizationObjectType } from "../dose/dose-types";
import { CustomizationObjectType } from "./customization-types";

/** A model customization validation error from backend with information to identify it in UI. Uses the ViewModel suffix
 * to disambiguate it from the BackendValidationError type.
 */
export type BackendValidationErrorViewModel = {
    /** The Redux store object ID for the object that this error is about (e.g. roiId for CustomizationRois) */
    id: string;

    /** Type of the errored object. */
    type: CustomizationObjectType;

    /** A short message about the error. */
    message: string;

    /** A longer, more detailed message about the error. */
    detail: string;

    /** The field of the object that this error is about. */
    field?: string;

    /** More optional context for the error. */
    ctx?: string;
}

/** Base object for UI validation errors. 
 * 
 * TODO: this type doesn't derive from Error so it probably shouldn't have the Error suffix.
 */
type FormValidationError = {
    /** The Redux store object ID for the object that this error is about (e.g. roiId for CustomizationRois) */
    id: string;

    /** The Redux store object ID for the object that this error is about (e.g. roiId for CustomizationRois). A business item may have
     * multiple warnings or errors -- they are represented as individual FormValidationError objects.
     */
    itemId: string;

    /** Type of this error, such as duplicate names in same customization.*/
    errorType: FormValidationErrorType;

    /** A short message about the error. */
    message?: string;

    /** A longer, more detailed message about the error. */
    detail?: string;

    /** The field of the object that this error is about. */
    field?: string;

    /** More optional context for the error. */
    ctx?: string;
}

/** A validation error or warning for a contouring customization object that's directly from the UI and does not block saving (the backend still might). 
 * Technically multiple object types are supported but currently only CustomizationObjectType.Roi support has been implemented.
 * 
 * TODO: this type doesn't derive from Error so it probably shouldn't have the Error suffix.
*/
export type ContouringFormValidationError = FormValidationError & {
    /** Type of the errored contouring object. */
    itemType: CustomizationObjectType;
};

/** A validation error or warning for a dose customization object that's directly from the UI and does not block saving (the backend still might). 
 * 
 * TODO: this type doesn't derive from Error so it probably shouldn't have the Error suffix.
*/
export type DoseFormValidationError = FormValidationError & {
    /** Type of the errored contouring object. */
    itemType: DoseCustomizationObjectType;
};

/** A daemon validation error from backend with information to identify it in UI.
 * 
 * TODO: this type doesn't derive from Error so it probably shouldn't have the Error suffix.
 */
export type DaemonValidationError = {
    /** The ID of this error, usually matching the daemon configuration item this errors is for. */
    id: string;

    /** A short message about the error. */
    message: string;

    /** A longer, more detailed message about the error. */
    detail: string;

    /** The field of the object that this error is about. */
    field?: string;

    /** More optional context for the error. */
    ctx?: string;
}

/** Generates a new form validation error instance according to input. Undefined fields are not included in the object at all. */
export const generateNewFormValidationError = (errorType: FormValidationErrorType,
    itemId: string,
    itemType: CustomizationObjectType,
    message?: string,
    detail?: string,
    field?: string,
    ctx?: string): ContouringFormValidationError => {
    const newError: ContouringFormValidationError = {
        id: generateNewId(),
        itemId: itemId,
        itemType: itemType,
        errorType: errorType,
    }

    if (message) { newError.message = message; }
    if (detail) { newError.detail = detail; }
    if (field) { newError.field = field; }
    if (ctx) { newError.ctx = ctx; }

    return newError;
}

/** Duplicates given form validation error object with a new id. */
export const duplicateFormValidationError = (input: ContouringFormValidationError, duplicateTargetId: string): ContouringFormValidationError => {
    return {
        id: generateNewId(),
        itemId: duplicateTargetId,
        itemType: input.itemType,
        errorType: input.errorType,
        message: input.message,
        detail: input.detail,
        field: input.field,
        ctx: input.ctx,
    }
}
