import { formatISO } from "date-fns";

export function toSafeHtmlAttribute(value: string): string {
    // from https://stackoverflow.com/a/55008188

    // We must be careful not to replace into an invalid string,
    // thus adding 'a' in some cases and doing a second replace.
    return value.replace(/(^-\d-|^\d|^-\d|^--)/, 'a$1').replace(/[\W]/g, '-');
}

export function getFilenameSafeTimestamp(): string {
    return (formatISO(new Date(), { format: 'basic' })).replace(':', '').replace('+', '_');
}

/** Removes a SINGLE trailing forward slash */
export function removeTrailingForwardSlash(str: string): string {
    if (str.length === 0) { return str; }

    if (str.charAt(str.length - 1) === '/') {
        return str.slice(0, str.length - 1);
    }

    return str;
}

const regexSpecialCharacters = [
    "\\", ".", "+", "*", "?",
    "[", "^", "]", "$", "(",
    ")", "{", "}", "=", "!",
    "<", ">", "|", ":", "-"
];

/** Converts regex to safe plain text, or null if not possible because of complicated regex rules.
 * NOTE: Python re-flavoured regex.
 */
export function regexToLiteralText(regex: string): string | null {
    if (regex.startsWith('^') && regex.endsWith('$')) {
        let literal = '';
        let previousBackslash = -1;
        for (let i = 1; i < regex.length - 1; i++) {
            const char = regex[i];

            // special handling if previous character was a backslash -- only accept a special character
            if (previousBackslash === i - 1) {
                if (regexSpecialCharacters.includes(char)) {
                    literal = literal.concat(char);
                } else {
                    return null;
                }
                previousBackslash = -1;
            } else {
                // previous character was NOT a backslash
                if (char === '\\') {
                    previousBackslash = i;
                    // return null if this is the last character
                    if (i === regex.length - 2) { return null; }
                } else if (regexSpecialCharacters.includes(char)) {
                    return null;
                } else {
                    literal = literal.concat(char);
                }
            }
        }
        return literal;
    }

    return null;
}

export function literalTextToRegex(text: string): string {
    let regex = '^';

    for (const char of text) {
        if (regexSpecialCharacters.includes(char)) {
            regex = regex.concat('\\', char);
        } else {
            regex = regex.concat(char);
        }
    }

    regex = regex.concat('$');

    return regex;
}

export function JSONstringifyOrder(obj: any, space?: any): string {
    const allKeys: any = [];
    const seen: any = {};
    JSON.stringify(obj, function (key, value) {
        if (!(key in seen)) {
            allKeys.push(key);
            seen[key] = null;
        }
        return value;
    });
    allKeys.sort();
    return JSON.stringify(obj, allKeys, space);
}
