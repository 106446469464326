import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MVisionAppClient } from '../store/configurationTarget/mvision-client-list';
import Select, { createFilter } from 'react-select';
import { StoreState } from '../store/store';

import './client-selector.css';
import { configurationTargetSelectors, currentConfigurationTargetSet } from '../store/configurationTarget/configurationTargetSlice';
import { contouringSelectors } from '../store/contouring/contouringSlice';

const ConfigurationTargetSelector = (props: { targetList: MVisionAppClient[] }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const anyUnsavedChanges = useSelector(contouringSelectors.selectAnyUnsavedContouringChangesInApp);
    const isClientDataOperationInProgress = useSelector(
        (state: StoreState) => state.configurationTarget.isFetchingConfigurationTargetData || state.contouring.isModelCustomizationResetInProgress);

    const changeConfigurationTarget = (newTargetId: string) => {
        if (newTargetId !== currentTarget?.userId) {
            const newValue = newTargetId === '' ? undefined : newTargetId;

            // prompt user to confirm if there are unsaved changes
            if (newValue !== currentTarget?.userId && anyUnsavedChanges && !window.confirm(t('confirm.changeConfigurationTarget') as string)) {
                return;
            }

            dispatch(currentConfigurationTargetSet(newValue));
        }
    }

    const dropdownValues: (MVisionAppClient | { userId: undefined, userName: string })[] = [];
    props.targetList.forEach(cl => dropdownValues.push(cl));

    const targetOptions = dropdownValues.map(dV => ({ value: dV.userId, label: dV.userName }));

    const selectedOption = currentTarget ? targetOptions.find(co => co.value === currentTarget.userId) : undefined;

    return (
        <div className="client-selector">
            <div className="client-selector-label">{t('topMenu.configurationTarget')}</div>
                { 
                    dropdownValues.length > 0 &&
                    <Select
                        id="app-client-selector-form"
                        name="app-client-selector-form"
                        className='client-selector-dropdown'
                        isDisabled={isClientDataOperationInProgress}
                        onChange={(newAppClient) => newAppClient && changeConfigurationTarget(newAppClient.value || '')}
                        value={selectedOption}
                        options={targetOptions}
                        filterOption={createFilter({ ignoreCase: true })}
                        placeholder={t('selectConfigurationTarget.placeholder')}
                        maxMenuHeight={700}
                    />
                }
        </div>
    );
}

export default ConfigurationTargetSelector;
