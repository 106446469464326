import { call, delay, fork, put, take } from "typed-redux-saga";
import { daemonConfigDeleted, daemonConfigRemove, daemonConfigReset, daemonConfigSaved, daemonConfigSet, daemonConfigsFetched, daemonConfigsSet, deleteDaemonConfigFinished, deleteDaemonConfigStarted, resetDaemonConfigFinished, resetDaemonConfigStarted, saveDaemonConfigFinished, saveDaemonConfigStarted } from "./daemonSlice";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import ConfigurationClient from "../../web-apis/configuration-client";
import { DaemonConfig } from "./daemon-config";
import { get } from "lodash-es";
import { isBackendValidationError, isInstanceOfError } from "../../util/errors";


export function* fetchDaemonConfigsSaga(appClient: MVisionAppClient | undefined) {
    const client = new ConfigurationClient();
    let daemonConfigs: DaemonConfig[] | null = null;
    let error = null;

    try {
        daemonConfigs = appClient === undefined ? null : yield* call(async () => client.fetchDaemonConfigsAsync(appClient));
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error === null) {
        yield* put(daemonConfigsSet({ daemonConfigs: daemonConfigs }));
    } else {
        // error case
        console.error('Was not able to fetch daemon configuration');
        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        yield* put(daemonConfigsSet({ daemonConfigs: null, error: errorMessage }));
    }
}

/** Fetches a single daemon configuration, not all of them. Generally you'll want to use **fetchDaemonConfigsSaga** to get all of them instead of just one. */
function* fetchSingleDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonClientId: string) {
    const client = new ConfigurationClient();
    let daemonConfig: DaemonConfig | null = null;
    let error = null;

    try {
        daemonConfig = appClient === undefined ? null : yield* call(async () => client.fetchDaemonConfigAsync(appClient, daemonClientId));
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (isInstanceOfError(error)) {
        yield* put(daemonConfigSet({ daemonConfig: daemonConfig, error: error.message || 'Unspecified error.' }));
        return false;
    } else {
        yield* put(daemonConfigSet({ daemonConfig: daemonConfig }));
        return true;
    }
}

function* saveDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig) {
    const client = new ConfigurationClient();
    let error = null;
    let errorMessage = null;
    let success = false;

    try {
        yield* put(saveDaemonConfigStarted({ appClient, daemonConfig }));
        success = yield* call(async () => client.saveDaemonConfigAsync(appClient, daemonConfig));
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error !== null) {
        console.error('Was not able to save daemon configuration');
        errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        console.error(errorMessage);
    } else if (!success) {
        errorMessage = 'Unable to save daemon configuration. There might be a problem with the server.';
    }

    yield* put(saveDaemonConfigFinished({
        appClient,
        daemonConfig,
        saveWasSuccessful: success,
        errorMessage: errorMessage || undefined,
        error: isBackendValidationError(error) ? error : undefined
    }));

    // store internal version of saved daemon config into redux collection if save succeeded
    if (success) {
        yield* put(daemonConfigSet({ daemonConfig, updateOnly: true }));
    }
}

function* resetDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig) {
    const client = new ConfigurationClient();
    let error = null;
    let errorMessage = null;
    let success = false;

    try {
        yield* put(resetDaemonConfigStarted({ appClient, daemonConfig }));
        success = yield* call(async () => client.resetDaemonConfigAsync(appClient, daemonConfig));
        if (success) {
            // wait a bit, then fetch updated (reset) daemon config
            yield* delay(2000);  // 2 seconds
            success = yield* call(fetchSingleDaemonConfigSaga, appClient, daemonConfig.sessionId);
        }
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error !== null) {
        console.error('Was not able to reset daemon configuration');
        errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        console.error(errorMessage);
    } else if (!success) {
        errorMessage = 'Unable to reset daemon configuration. There might be a problem with the server.';
    }

    yield* put(resetDaemonConfigFinished({
        appClient,
        daemonConfig,
        saveWasSuccessful: success,
        errorMessage: errorMessage || undefined,
        error: isBackendValidationError(error) ? error : undefined
    }));
}

function* deleteDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig) {
    const client = new ConfigurationClient();
    let error = null;
    let errorMessage = null;
    let success = false;

    try {
        yield* put(deleteDaemonConfigStarted({ appClient, daemonConfig }));
        success = yield* call(async () => client.deleteDaemonConfigAsync(appClient, daemonConfig));
        if (success) {
            // remove the just-removed entry from store
            yield* put(daemonConfigRemove(daemonConfig.sessionId));
        }
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (error !== null) {
        console.error('Was not able to remove daemon configuration');
        errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
        console.error(errorMessage);
    } else if (!success) {
        errorMessage = 'Unable to remove daemon configuration. There might be a problem with the server.';
    }

    yield* put(deleteDaemonConfigFinished({
        appClient,
        daemonConfig,
        saveWasSuccessful: success,
        errorMessage: errorMessage || undefined,
        error: isBackendValidationError(error) ? error : undefined
    }));
}



function* watchFetchDaemonConfigsSaga() {
    while (true) {
        const action = yield* take(daemonConfigsFetched);
        yield* fork(fetchDaemonConfigsSaga, action.payload);
    }
}

function* watchSaveDaemonConfigSaga() {
    while (true) {
        const action = yield* take(daemonConfigSaved);
        yield* fork(saveDaemonConfigSaga, action.payload.appClient, action.payload.daemonConfig);
    }
}

function* watchResetDaemonConfigSaga() {
    while (true) {
        const action = yield* take(daemonConfigReset);
        yield* fork(resetDaemonConfigSaga, action.payload.appClient, action.payload.daemonConfig);
    }
}

function* watchDeleteDaemonConfigSaga() {
    while (true) {
        const action = yield* take(daemonConfigDeleted);
        yield* fork(deleteDaemonConfigSaga, action.payload.appClient, action.payload.daemonConfig);
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForDaemonSagas() {
    return [
        watchFetchDaemonConfigsSaga(),
        watchSaveDaemonConfigSaga(),
        watchResetDaemonConfigSaga(),
        watchDeleteDaemonConfigSaga(),
    ];
}
