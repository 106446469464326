import { StackItem, FormGroup, Level, LevelItem, Dropdown, KebabToggle, DropdownItem, Modal, ModalVariant, Button, DropdownPosition, InputGroup, DropdownToggle, Divider, Stack, Checkbox, Popover, Split, SplitItem, ListItem, List, Text, Tooltip } from '@patternfly/react-core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleIcon, CogIcon, OutlinedQuestionCircleIcon } from '@patternfly/react-icons';
import DebouncedTextInput from '../../components/debounced-text-input';
import { ModelSelectionUndoState } from './SelectionRulePage';
import { literalTextToRegex, regexToLiteralText } from '../../util/string-convert';
import { getFormValidationErrorMessage } from '../../store/global-types/form-errors';
import { defaultValidation, FormValidationState } from '../../components/form-validation-state';

import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon, BanIcon, CheckIcon } from '@patternfly/react-icons'
import { RetainStatus } from '../../store/daemon/daemon-config';
import { mapRetainedDicomAttributes } from '../../store/retained-dicom-attributes';
import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { makeSelectFormValidationErrorsForDicomAttributeRule } from '../../store/contouring/contouringSelectors';
import { contouringSelectors, dicomAttributeRuleRemoved, dicomAttributeRuleUpdated } from '../../store/contouring/contouringSlice';
import { ModelSelectionScope } from '../../store/global-types/customization-types';



interface ModelSelectionRuleDicomAttributeProps {
    dicomAttributeRuleId: string,
    displayId: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    onRemoveDicomRule: () => void,
    disableUndo?: boolean,
}

const HasAcknowledgedRegexServiceDisclaimer = 'configUIRegexServiceDisclaimer';

const ModelSelectionRuleDicomAttribute = (props: ModelSelectionRuleDicomAttributeProps) => {
    const { dicomAttributeRuleId, displayId, setUndoState, onRemoveDicomRule, disableUndo } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const deploymentConfigInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const daemonConfigs = useSelector((state: StoreState) => state.daemon.daemonConfigs);


    const inputRef = useRef<HTMLInputElement>(null);

    const [isActionListOpen, setActionListOpenState] = useState(false);
    const [isRemoveAttributeModalOpen, setRemoveAttributeModalOpenState] = useState(false);
    // const [isAttributeHovered, setAttributeHovered] = useState(false);
    const [isPresetToggleOpen, setIsPresetToggleOpen] = useState(false);
    const [isRegexModeOn, setRegexModeOn] = useState(false);
    const [plainTextValue, setPlainTextValue] = useState<string | null>(null);
    const [isRegexUsageDisclaimerAcknowledged, setIsRegexUsageDisclaimerAcknowledged] = useState<boolean>(true);
    const [openRegexConfirmModal, setOpenRegexConfirmModal] = useState<boolean>(false);
    const [nameFieldValidation, setNameFieldValidation] = useState<FormValidationState>(defaultValidation);

    const dicomAttributeRule = useSelector((state: StoreState) => contouringSelectors.selectDicomAttributeRuleById(state, dicomAttributeRuleId));

    const selectFormValidationErrorsForRule = useMemo(makeSelectFormValidationErrorsForDicomAttributeRule, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRule(state, dicomAttributeRuleId));

    /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    useEffect(() => {
        if (formValidationErrors.length > 0) {
            const allErrorsAsMessage = formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ');
            setNameFieldValidation({ validated: 'error', helperTextInvalid: allErrorsAsMessage });
        } else {
            setNameFieldValidation(defaultValidation);
        }
    }, [formValidationErrors, dicomAttributeRule]);

    useEffect(() => {
        const plainText = dicomAttributeRule !== undefined ? regexToLiteralText(dicomAttributeRule.value) : null;
        setPlainTextValue(plainText);
        if (plainText === null) { setRegexModeOn(true); }
    }, [dicomAttributeRule, daemonConfigs, dispatch]);

    useEffect(() => {
        const acknowledgedRegexServiceDisclaimer = window.localStorage.getItem(HasAcknowledgedRegexServiceDisclaimer);
        if (!acknowledgedRegexServiceDisclaimer && isRegexModeOn) {
            setIsRegexUsageDisclaimerAcknowledged(false)
        } else {
            setIsRegexUsageDisclaimerAcknowledged(true)
        }
    }, [isRegexModeOn])

    useEffect(() => {
        if (!isRegexUsageDisclaimerAcknowledged) {
            setOpenRegexConfirmModal(true)
        } else {
            setOpenRegexConfirmModal(false)
            setIsRegexUsageDisclaimerAcknowledged(true)
        }
    }, [isRegexUsageDisclaimerAcknowledged])

    const handleRemoveAttributeButtonClick = useCallback(() => {
        setActionListOpenState(false);
        setRemoveAttributeModalOpenState(true);
    }, []);


    // Calculate retainedDicomAttributes when daemonConfigs changes
    const retainedDicomAttributes = useMemo(() => {
        if (!daemonConfigs) {
            // Return empty object when daemonConfigs is not initialized
            return {};
        }
        return mapRetainedDicomAttributes(daemonConfigs);
    }, [daemonConfigs]);


    const handleRemoveAttributeConfirmation = useCallback(() => {
        setRemoveAttributeModalOpenState(false);
        dispatch(dicomAttributeRuleRemoved(dicomAttributeRuleId));
    }, [dispatch, dicomAttributeRuleId]);

    // const handleRemoveAttributeHover = useCallback(() => {
    //     setAttributeHovered(true);
    // }, []);

    // const handleRemoveAttributeHoverLeave = useCallback(() => {
    //     setAttributeHovered(false);
    // }, []);

    const dispatchDicomAttributeNameChange = useCallback((name: string) => {
        if (dicomAttributeRule) {
            dispatch(dicomAttributeRuleUpdated({ id: dicomAttributeRuleId, dicomAttribute: name, dicomValue: dicomAttributeRule.value }));
        }
    }, [dispatch, dicomAttributeRule, dicomAttributeRuleId]);

    const dispatchDicomAttributeValueChange = useCallback((value: string) => {
        if (dicomAttributeRule) {
            const regexValue = isRegexModeOn ? value : literalTextToRegex(value);
            dispatch(dicomAttributeRuleUpdated({ id: dicomAttributeRuleId, dicomAttribute: dicomAttributeRule.attribute, dicomValue: regexValue }));
        }
    }, [dispatch, dicomAttributeRule, dicomAttributeRuleId, isRegexModeOn]);

    const handleUndo = useCallback(() => {
        setUndoState({ isModalOpen: true, scope: ModelSelectionScope.DicomAttributeRule, id: dicomAttributeRuleId });
        setActionListOpenState(false);
    }, [dicomAttributeRuleId, setUndoState]);

    const handleSelectPreset = useCallback((dicomAttributeName: string, key?: string) => {
        dispatchDicomAttributeNameChange(dicomAttributeName);
        if (key === 'FreeTextEntry') {
            // Set focus on the input for 'free text entry'
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
        setIsPresetToggleOpen(false);
    }, [dispatchDicomAttributeNameChange, inputRef]);

    const handleRegexUsageDisclaimerAcknowledgement = () => {
        window.localStorage.setItem(
            HasAcknowledgedRegexServiceDisclaimer,
            JSON.stringify({ signed: true })
        )
        setIsRegexUsageDisclaimerAcknowledged(true)
    }

    const handleRegexUsageDisclaimerCancel = () => {
        setRegexModeOn(false)
    }


    const getRetainedAttributeIcon = ((attribute: string): React.ReactNode | null => {

        let isRetainedInAllModels = false;
        let isRetainedInSomeModels = false;

        // check if the attribute name is present in the RetainedDicomAttributes object
        if (retainedDicomAttributes[attribute] !== undefined) {
            if (retainedDicomAttributes[attribute] === RetainStatus.Retained) {
                isRetainedInAllModels = true;
            } else if (retainedDicomAttributes[attribute] === RetainStatus.RetainedInSome) {
                isRetainedInSomeModels = true;
            }
        }


        if (isRetainedInAllModels) {
            return <Tooltip content={t("selectionPage.dicomAttributePresets.retainedInAll")}><CheckIcon className='dicom-attributes-helper-icons' /></Tooltip>;
        } else if (isRetainedInSomeModels) {
            return <Tooltip content={t("selectionPage.dicomAttributePresets.retainedInSome")}><ExclamationTriangleIcon className='dicom-attributes-helper-icons' /></Tooltip>;
        } else {
            return <Tooltip content={t("selectionPage.dicomAttributePresets.NotRetained")}><BanIcon className='dicom-attributes-helper-icons' /></Tooltip>;
        }
    });

    // don't render anything if we don't have a valid object
    if (dicomAttributeRule === undefined) {
        return null;
    }

    const dicomAttributePresets = [
        <DropdownItem key="title" isPlainText>{t('selectionPage.dicomAttributePresetsLabel')}</DropdownItem>,
        <DropdownItem key="divider" isPlainText><Divider /></DropdownItem>,
        <DropdownItem key="PatientSex" listItemClassName='dicom-attr-defaults' icon={getRetainedAttributeIcon('PatientSex')} onClick={() => handleSelectPreset('PatientSex')} title={''}>PatientSex</DropdownItem>,
        <DropdownItem key="ProtocolName" listItemClassName='dicom-attr-defaults' icon={getRetainedAttributeIcon('ProtocolName')} onClick={() => handleSelectPreset('ProtocolName')} title={''}>ProtocolName</DropdownItem>,
        <DropdownItem key="SeriesDescription" listItemClassName='dicom-attr-defaults' icon={getRetainedAttributeIcon('SeriesDescription')} onClick={() => handleSelectPreset('SeriesDescription')} title={''}>SeriesDescription</DropdownItem>,
        <DropdownItem key="BodyPartExamined" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('BodyPartExamined')} icon={getRetainedAttributeIcon('BodyPartExamined')}>BodyPartExamined</DropdownItem>,
        <DropdownItem key="ImageComments" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('ImageComments')} icon={getRetainedAttributeIcon('ImageComments')}>ImageComments</DropdownItem>,
        <DropdownItem key="Laterality" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('Laterality')} icon={getRetainedAttributeIcon('Laterality')}>Laterality</DropdownItem>,
        <DropdownItem key="ScanOptions" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('ScanOptions')} icon={getRetainedAttributeIcon('ScanOptions')}>ScanOptions</DropdownItem>,
        <DropdownItem key="StationName" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('StationName')} icon={getRetainedAttributeIcon('StationName')}>StationName</DropdownItem>,
        <DropdownItem key="StudyDescription" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('StudyDescription')} icon={getRetainedAttributeIcon('StudyDescription')}>StudyDescription</DropdownItem>,
        <DropdownItem key="FreeTextEntry" listItemClassName='dicom-attr-defaults' onClick={() => handleSelectPreset('', "FreeTextEntry")}>{t("selectionPage.dicomAttributePresets.freeTextEntry")}</DropdownItem>,
    ];

    // TODO: this can be used to display which action menu item (attribute or entire rule) is being targeted by an action but
    // it will require some extra ui work to clean the hover style in weird cases (like pressing esc when the action menu is
    // open, or if a modal is opened). Similar approach can be used to style the entire rule.
    const hoveredClass = '';// isAttributeHovered ? 'attribute-hovered' : '';

    const formValidationState = dicomAttributeRule.isValid ? 'default' : 'error';

    const dropdownItems = [
        <DropdownItem key="removeAttribute" /*onMouseEnter={handleRemoveAttributeHover} onMouseLeave={handleRemoveAttributeHoverLeave}*/ onClick={handleRemoveAttributeButtonClick}>{t('selectionPage.removeDicomAttributeRule')}</DropdownItem>,
        <DropdownItem key="removeDicomRule" onClick={onRemoveDicomRule}>{t('selectionPage.removeDicomRule')}</DropdownItem>
    ];
    if (!disableUndo) {
        dropdownItems.unshift(<DropdownItem key="undoDicomAttributeRuleChanges" isDisabled={!dicomAttributeRule.isModified || dicomAttributeRule.isNew}
            onClick={handleUndo}>{t('selectionPage.undoDicomAttributeRuleChanges')}</DropdownItem>);
    }

    return (
        <>
            <StackItem className={`rule-item dicom-rule-item ${hoveredClass}`}>
                <Split className="dicom-attribute-row" hasGutter>
                    <SplitItem className="dicom-attribute-column">
                        <FormGroup
                            label={t('selectionPage.dicomAttributeRuleNumber', { number: displayId })}
                            fieldId={`attribute-name-field-for-${dicomAttributeRule.id}`}
                            title="A DICOM attribute that is used in this rule."
                            validated={formValidationState}
                            helperTextInvalid={nameFieldValidation.helperTextInvalid}
                            helperTextInvalidIcon={<ExclamationCircleIcon />}
                        >
                            <InputGroup>
                                <DebouncedTextInput
                                    fieldId={`attribute-name-field-for-${dicomAttributeRule.id}`}
                                    onChange={dispatchDicomAttributeNameChange}
                                    className="input-field dicom-input-field"
                                    inputRef={inputRef}
                                    defaultValue={dicomAttributeRule.attribute}
                                    validated={formValidationState}
                                    placeholder={"Type or select attribute..."}
                                />
                                <Dropdown
                                    dropdownItems={dicomAttributePresets}
                                    isOpen={isPresetToggleOpen}
                                    title="Optional preset attribute names"
                                    toggle={<DropdownToggle toggleIndicator={null} onToggle={() => setIsPresetToggleOpen(!isPresetToggleOpen)}><CogIcon /></DropdownToggle>}
                                />
                            </InputGroup>
                        </FormGroup>
                    </SplitItem>
                    <SplitItem isFilled>
                        <Stack className={isRegexModeOn ? 'regex-input' : ''}>
                            <StackItem>
                                <FormGroup
                                    label={t('selectionPage.requiredValue')}
                                    fieldId={`attribute-value-field-for-${dicomAttributeRule.id}`}
                                    title="Value (or value range) used in this rule condition."
                                // validated={modelValidation.GetNameValidationState(structureCustomization.name)}
                                // helperTextInvalid={modelValidation.GetNameValidationText(structureCustomization.name)}
                                // helperTextInvalidIcon={<ExclamationCircleIcon />}
                                >
                                    <InputGroup>
                                        <DebouncedTextInput
                                            fieldId={`attribute-value-field-for-${dicomAttributeRule.id}`}
                                            onChange={dispatchDicomAttributeValueChange}
                                            className={`input-field ${isRegexModeOn ? 'regex-input' : ''}`}
                                            defaultValue={isRegexModeOn ? dicomAttributeRule.value : plainTextValue || ''}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </StackItem>
                            <StackItem>
                                <Level>
                                    <LevelItem>
                                        <Checkbox
                                            label={t('selectionPage.enableRegEx')}
                                            isChecked={isRegexModeOn}
                                            onChange={setRegexModeOn}
                                            id={`dicom-attribute-rule-regex-toggle-${dicomAttributeRule.id}`}
                                            isDisabled={plainTextValue === null}
                                        />
                                    </LevelItem>
                                    <LevelItem>
                                        {/* // KNOWN PATTERNFLY BUG: this will result in a deprecation warning in browser console. nothing to really worry about yet though.
                                            // See also: https://github.com/patternfly/patternfly-react/issues/6050 */}
                                        <Popover
                                            headerContent={<div>Advanced DICOM rule edit mode</div>}
                                            bodyContent={deploymentConfigInfo?.regexHelpFile && <div>Enables the use of <a target="_blank" rel="noreferrer" href={deploymentConfigInfo.regexHelpFile}>Regular Expressions</a> in DICOM attribute rule conditions.</div>}
                                            footerContent={deploymentConfigInfo?.regexHelpUrl && <div>Also this <a target="_blank" rel="noreferrer" href={deploymentConfigInfo.regexHelpUrl}>website</a> helps with writing the Regular Expressions.</div>}
                                        >
                                            <Button variant="plain"><OutlinedQuestionCircleIcon /></Button>
                                        </Popover>
                                    </LevelItem>
                                </Level>
                            </StackItem>
                        </Stack>
                    </SplitItem>
                    <SplitItem>
                        <Dropdown
                            toggle={<KebabToggle onToggle={() => setActionListOpenState(!isActionListOpen)} />}
                            isPlain
                            position={DropdownPosition.right}
                            dropdownItems={dropdownItems}
                            isOpen={isActionListOpen}
                        />
                    </SplitItem>
                </Split>
                {/* <Divider className="dicom-rule-divider" /> */}
            </StackItem>
            <Modal
                variant={ModalVariant.small}
                title={t('selectionPage.removeDicomAttributeRule.confirm')}
                isOpen={isRemoveAttributeModalOpen}
                onClose={() => setRemoveAttributeModalOpenState(false)}
                actions={[
                    <Button key="confirmRemoval" variant="danger" onClick={handleRemoveAttributeConfirmation}>{t('selectionPage.removeDicomAttributeRule.short')}</Button>,
                    <Button key="cancel" variant="link" onClick={() => setRemoveAttributeModalOpenState(false)}>{t('common.cancel')}</Button>
                ]}
            >
                Do you want to remove this DICOM attribute/value condition "{dicomAttributeRule.attribute}: {dicomAttributeRule.value}" from this model selection rule? Note that your changes won't be saved until you click on "Save".
            </Modal>
            <Modal
                variant={ModalVariant.small}
                title={t('selectionPage.regexDisclaimer')}
                isOpen={openRegexConfirmModal}
                showClose
                onClose={() => handleRegexUsageDisclaimerCancel()}
                actions={[
                    <Button key="confirmDisclaimer" variant="primary" onClick={() => handleRegexUsageDisclaimerAcknowledgement()}>{t('selectionPage.acknowledge')}</Button>,
                    <Button key="cancelDisclaimer" variant="danger" onClick={() => handleRegexUsageDisclaimerCancel()}>{t('common.cancel')}</Button>
                ]}
            >
                <Text className='regex-disclaimer-modal-upper-text'>{t('selectionPage.beforeUsingRegex')}:</Text>
                <List>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('selectionPage.regexModalIncorrectSyntax')}.</ListItem>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('selectionPage.regexModalContactSupport')}.</ListItem>
                    <ListItem className='regex-disclaimer-modal-list-item'>{t('selectionPage.regexModalValidationText')}.</ListItem>
                </List>
            </Modal>
        </>
    );
}

export default ModelSelectionRuleDicomAttribute;
