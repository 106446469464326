// cloud backend related code
import { get, has, set, find } from "lodash-es";

import { mvapiClinicalAppRegistrationApplicationClientId, MVAPI_DEFAULT } from "../environments";
import { CloudBackend, CloudBackendTier } from "../store/auth/auth";
import { removeTrailingForwardSlash } from "../util/string-convert";
import AppAuth from "./app-auth";


export type BackendFetchOptions = {
    /** Allow caching of request response. Default: false. */
    allowCache: boolean;

    /** Return quickFetch response as text instead of json. Only applicable to quickFetch. Default: false. */
    asText: boolean;

    /** If true sets HTTP headers correctly for POSTing JSON. Default: false. */
    postJson: boolean;

    /** Don't append client ID to URL query parameters. Default: false. */
    noClientId: boolean;

    /** The time to wait during a fetch before timing it out. Using undefined uses browser default. Default: undefined. */
    fetchTimeoutInMs: number | undefined;

    /** Maximum number of retries to perform if a call fails. Default: 20. */
    maxRetries: number;

    /** The time in milliseconds to wait before a retry. Default: 10 seconds. */
    retryWaitInMs: number;
}

export function defaultBackendFetchOptions(overrideValues: Partial<BackendFetchOptions>): BackendFetchOptions {
    return {
        allowCache: get(overrideValues, 'allowCache', false),
        asText: get(overrideValues, 'asText', false),
        postJson: get(overrideValues, 'postJson', false),
        noClientId: get(overrideValues, 'noClientId', false),
        fetchTimeoutInMs: get(overrideValues, 'fetchTimeoutInMs', undefined),
        maxRetries: get(overrideValues, 'maxRetries', 20),
        retryWaitInMs: get(overrideValues, 'retryWaitInMs', 10 * 1000),
    };
}

// export const createDefaultBackendFetchOptions = (): BackendFetchOptions => ({ allowCache: false, asText: false });

/** Class instance of a cloud backend client */
export class CloudAuthBackendClient {

    backend: CloudBackend;
    appAuth: AppAuth;
    name: string;
    url: string;

    constructor(backend: CloudBackend, appAuth: AppAuth) {
        this.backend = backend;
        this.name = backend.name;
        this.url = backend.url;
        this.appAuth = appAuth;
    }

    async fetch(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const fullUrl = this.backend.url + partialUrl;
        return await this.appAuth.fetch(fullUrl, httpRequestOptions, backendFetchOptions);
    }

    async get(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithGetMethod = set(httpRequestOptions || {}, 'method', 'get');
        return await this.fetch(partialUrl, optionsWithGetMethod, backendFetchOptions);
    }

    async post(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithPostMethod = set(httpRequestOptions || {}, 'method', 'post');
        return await this.fetch(partialUrl, optionsWithPostMethod, backendFetchOptions);
    }

    async quickFetch(partialUrl: string, httpRequestOptions: any, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<any> {
        return this.fetch(partialUrl, httpRequestOptions, backendFetchOptions)
            .then(async response => backendFetchOptions.asText ? await response.text() : await response.json())
            .catch(error => {
                const errorMessage = `Error calling quickFetch on ${partialUrl} (${this.name}):`;
                console.log(errorMessage);
                console.log(error);
                throw new Error(errorMessage);
            });
    }
}

/** A collection of cloud backends. Mainly a convenience collection. */
export class CloudBackends {
    backends: { [backendName: string]: CloudBackend };
    defaultBackend: string;

    constructor(backends: { [backendName: string]: CloudBackend }, defaultBackend: string) {
        this.backends = backends;
        this.defaultBackend = defaultBackend;

        if (!backends[defaultBackend]) {
            throw new Error(`Given default backend ${defaultBackend} does not exist in supplied backend collection`);
        }
    }

    addBackend(backend: CloudBackend) {
        if (this.backends[backend.name]) {
            throw new Error(`Backend ${backend.name} already exists in backend collection`);
        }

        this.backends[backend.name] = backend;
    }

    getBackend(name: string): CloudBackend {
        if (!this.backends[name]) {
            throw new Error(`No backend named ${name} in backend collection`);
        }

        return this.backends[name];
    }

    getBackendByUrl(url: string): CloudBackend {
        const cleanedUrl = removeTrailingForwardSlash(url.trim());
        const backend = Object.values(this.backends).find(b => b.url === cleanedUrl);
        if (!backend) {
            throw new Error(`No backend with URL ${cleanedUrl} in backend collection`);
        }

        return backend;
    }

    getDefaultBackend(): CloudBackend {
        return this.backends[this.defaultBackend];
    }
}

/** Map of supported backend tiers to azure app registrations.
 * This information is used to connect each backend into appropriate app auth.
 */
export const backendTierAppAuths: Record<CloudBackendTier, AppAuth> = {
    Default: new AppAuth(MVAPI_DEFAULT, mvapiClinicalAppRegistrationApplicationClientId),
};

/** Returns an app registration authentication object matching a specific backend tier */
export function getAppAuthByTier(backendTier: CloudBackendTier): AppAuth {
    if (has(backendTierAppAuths, backendTier)) {
        return backendTierAppAuths[backendTier];
    }
    else {
        throw new Error(`Unsupported backend tier (${backendTier})`);
    }
}

/** Returns an app auth matching given app auth name. */
export const getAppAuthByName = (appAuthName: string): AppAuth => {
    const match = find(backendTierAppAuths, a => a.appName === appAuthName);
    if (match) { return match; }
    else {
        throw new Error(`Could not find app auth ${appAuthName} among supported app auths.`);
    }
}

/** Returns a new backend client for a specific backend */
export function getCloudAuthBackendClient(backend: CloudBackend): CloudAuthBackendClient {
    const appAuth = getAppAuthByTier(backend.tier);
    return new CloudAuthBackendClient(backend, appAuth);
}
