import { Stack, StackItem, Level, LevelItem, Toolbar, ToolbarContent, ToolbarItem, Button, Dropdown, KebabToggle, DropdownItem, DropdownPosition, Modal, ModalVariant, FormGroup } from '@patternfly/react-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusIcon, ExclamationCircleIcon } from '@patternfly/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import ModelSelectionRuleDicomAttribute from './DicomAttributeRule';
import ModelSelectionRuleWrapper from './SelectionRuleWrapper';
import { ModelSelectionUndoState } from './SelectionRulePage';
import { literalTextToRegex } from '../../util/string-convert';
import { useTranslation } from 'react-i18next';
import { getFormValidationErrorMessage } from '../../store/global-types/form-errors';
import { defaultValidation, FormValidationState } from '../../components/form-validation-state';
import { StoreState } from '../../store/store';
import { makeSelectFormValidationErrorsForDicomRule } from '../../store/contouring/contouringSelectors';
import { contouringSelectors, dicomAttributeRuleAdded, dicomRuleRemoved } from '../../store/contouring/contouringSlice';
import { CustomizationObjectType, ModelSelectionScope } from '../../store/global-types/customization-types';
import { createNewDicomAttributeRule } from '../../store/global-types/customization-helpers';


interface ModelSelectionDicomRuleProps {
    dicomRuleId: string,
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    disableUndo?: boolean,
}

const ModelSelectionDicomRule = (props: ModelSelectionDicomRuleProps) => {
    const { dicomRuleId, runningNumber, setUndoState, disableUndo } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isActionListOpen, setActionListOpenState] = useState(false);
    const [isRemoveDicomRuleModalOpen, setRemoveDicomRuleModalOpenState] = useState(false);
    const [formValidation, setFormValidation] = useState<FormValidationState>(defaultValidation);

    const dicomRule = useSelector((state: StoreState) => contouringSelectors.selectDicomRuleById(state, dicomRuleId));
    const validationError = useSelector((state: StoreState) => contouringSelectors.selectCustomizationValidationError(state, dicomRuleId));

    const selectFormValidationErrorsForRule = useMemo(makeSelectFormValidationErrorsForDicomRule, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRule(state, dicomRuleId));

    /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    useEffect(() => {
        if (formValidationErrors.length > 0) {
            const allErrorsAsMessage = formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ');
            setFormValidation({ validated: 'error', helperTextInvalid: allErrorsAsMessage });
        } else {
            setFormValidation(defaultValidation);
        }
    }, [formValidationErrors, dicomRule]);

    const addDicomAttribute = useCallback(() => {
        const newAttribute = createNewDicomAttributeRule('', literalTextToRegex(''), dicomRuleId, true);
        dispatch(dicomAttributeRuleAdded(newAttribute));
    }, [dispatch, dicomRuleId]);

    const handleRemoveDicomRuleButtonClick = useCallback(() => {
        setActionListOpenState(false);
        setRemoveDicomRuleModalOpenState(true);
    }, []);

    const handleRemoveDicomRuleConfimation = useCallback(() => {
        setRemoveDicomRuleModalOpenState(false);
        dispatch(dicomRuleRemoved(dicomRuleId));
    }, [dispatch, dicomRuleId]);

    const handleUndo = useCallback(() => {
        setUndoState({ isModalOpen: true, scope: ModelSelectionScope.DicomRule, id: dicomRuleId });
        setActionListOpenState(false); // close the action list
    }, [dicomRuleId, setUndoState]);

    if (dicomRule === undefined) {
        return null;
    }

    const dropdownItems = [<DropdownItem key="removeDicomRule" onClick={handleRemoveDicomRuleButtonClick}>{t('selectionPage.removeDicomRule')}</DropdownItem>];
    if (!disableUndo) {
        dropdownItems.unshift(<DropdownItem key="undoDicomRuleChanges" isDisabled={!dicomRule.isModified || dicomRule.isNew}
            onClick={handleUndo}>{t('selectionPage.undoDicomRuleChanges')}</DropdownItem>);
    }

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.DicomRule;
    const formValidationState = dicomRule.isValid ? 'default' : 'error';

    // render either an ae title field or a dicom attribute field
    return (
        <ModelSelectionRuleWrapper
            key={dicomRule.id}
            title={t('selectionPage.dicomRuleNumber', { number: runningNumber })}
            isModified={dicomRule.isModified}
            className="dicom-rule"
            hasValidationError={hasValidationError}
            validationError={validationError}>

            <Stack>
                <FormGroup
                    validated={formValidationState}
                    helperTextInvalid={formValidation.helperTextInvalid}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                >
                    {dicomRule.dicomAttributes.map((dicomAttributeId, i) => (
                        <ModelSelectionRuleDicomAttribute
                            key={dicomAttributeId}
                            dicomAttributeRuleId={dicomAttributeId}
                            displayId={i + 1}
                            setUndoState={setUndoState}
                            onRemoveDicomRule={handleRemoveDicomRuleButtonClick}
                            disableUndo={disableUndo}
                        />)
                    )}
                    <StackItem className="model-selection-toolbar dicom-rule-toolbar">
                        <Level>
                            <LevelItem>
                                <Toolbar>
                                    <ToolbarContent>
                                        <ToolbarItem>
                                            <Button
                                                isSmall
                                                variant="secondary"
                                                icon={<PlusIcon />}
                                                // isDisabled={isToolbarDisabled}
                                                onClick={addDicomAttribute}>{t('selectionPage.addDicomAttribute')}</Button>
                                        </ToolbarItem>

                                    </ToolbarContent>
                                </Toolbar>
                            </LevelItem>
                            <LevelItem>
                                <Dropdown
                                    toggle={<KebabToggle onToggle={() => setActionListOpenState(!isActionListOpen)} />}
                                    isPlain
                                    position={DropdownPosition.right}
                                    dropdownItems={dropdownItems}
                                    isOpen={isActionListOpen}
                                />
                            </LevelItem>
                        </Level>
                    </StackItem>
                    <Modal
                        variant={ModalVariant.small}
                        title={t('selectionPage.removeDicomRule.confirm')}
                        isOpen={isRemoveDicomRuleModalOpen}
                        onClose={() => setRemoveDicomRuleModalOpenState(false)}
                        actions={[
                            <Button key="confirmRemoval" variant="danger" onClick={handleRemoveDicomRuleConfimation}>{t('selectionPage.removeDicomRule.short')}</Button>,
                            <Button key="cancel" variant="link" onClick={() => setRemoveDicomRuleModalOpenState(false)}>{t('common.cancel')}</Button>
                        ]}
                    >
                        Do you want to remove this entire DICOM rule? Note that your changes won't be saved until you click on "Save".
                    </Modal>
                </FormGroup>
            </Stack>
        </ModelSelectionRuleWrapper >
    );
}

export default ModelSelectionDicomRule;
