import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DISPLAY_VERSION } from "../../environments";
import { AppVersionInfo, appVersionToString, areVersionsEqual } from "./appVersionTypes";
import { Labeling } from "./labeling";


export type AppVersionSliceState = {
    /** Current version of the app, or null if not defined or detected. */
    version: AppVersionInfo | null;
    /** True if there's a new version of the app available on the server, false otherwise. */
    isNewAppVersionAvailable: boolean;
    /** True if new version alert dialog is snoozed (not shown), false otherwise. */
    isNewVersionAlertSnoozed: boolean;

    /** Labeling for this application. */
    productLabel: Labeling | null,

    /** An error that occurred when trying to retrieve labeling, or null.*/
    productLabelError: string | null,
};


export const initialState: AppVersionSliceState = {
    version: null,
    isNewAppVersionAvailable: false,
    isNewVersionAlertSnoozed: false,
    productLabel: null,
    productLabelError: null,
};

/** Redux store slice for handling with app version (which is downloaded from the server, not 
 * bundled in the source code). App version is used to track if there's a new version of the client
 * code available on the server. User can be shown a dialog to tell them to refresh their page
 * as needed. */
const appVersionSlice = createSlice({
    name: 'appVersion',
    initialState,
    reducers: {
        /** Sets current app version (from a data file downloaded from server). App version in state is not overwritten
         * if it has already been set once. In that case, check if the received app version is for a new version.
         * If so, mark that a new app version is available.
         * @param action payload: AppVersionInfo object matching the app version we're trying to set.
         */
        appVersionSet(state, action: PayloadAction<AppVersionInfo>) {
            const appVersion = action.payload;
            if (state.version === null) {
                /** Print current version to developer console on receive to make debugging easier. */
                console.log(`App version: ${appVersionToString(appVersion)}, display version: ${DISPLAY_VERSION}`);
                console.log(appVersion);
                state.version = appVersion;
            } else {
                if (!areVersionsEqual(state.version, appVersion)) {
                    state.isNewAppVersionAvailable = true;
                }
            }
        },

        /** Signals new version alert as snoozed */
        newVersionAlertSnoozed() {
            // this is an empty action and is only used for signalling in sagas -- see appVersionSagas.ts for implementation
            // TODO: this could probably also be done with a thunk instead of sagas
        },

        /** Sets new version alert snooze on (true) or off (false).
         * @param action payload: true if new version alert should be snoozed, false otherwise.
         */
        newVersionAlertSnoozeSet(state, action: PayloadAction<boolean>) {
            state.isNewVersionAlertSnoozed = action.payload;
        },

        /** Signals that product labeling should be fetched. */
        labelingFetched() {
            // this is an empty action and is only used for signalling in sagas -- see appVersionSagas.ts for implementation
            // TODO: this could probably also be done with a thunk instead of sagas
        },

        /**
         * Sets product labeling to supplied value.
         * @param action.productLabel The label to use.
         * @param action.error An optional error message.
         */
        labelingSet(state, action: PayloadAction<{ productLabel: Labeling | null, error?: string | undefined }>) {
            const { productLabel, error } = action.payload;

            if (productLabel !== null) {
                state.productLabel = productLabel;
            }

            state.productLabelError = error !== undefined ? error : null;
        },

    }
});

export const { appVersionSet, newVersionAlertSnoozed, newVersionAlertSnoozeSet, labelingFetched, labelingSet } = appVersionSlice.actions;

export const { getInitialState } = appVersionSlice;

export default appVersionSlice.reducer;
