import React from 'react';
import { Button, PageHeader, PageHeaderTools, PageHeaderToolsGroup, PageHeaderToolsItem } from '@patternfly/react-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ConfigurationTargetSelector from './components/client-selector';
import { DEFAULT_APP_NAME } from './environments';
import { CogIcon, QuestionCircleIcon } from '@patternfly/react-icons'
import TiltButton from './components/tilt-button';
import routes from './routes';

import logo32w from './img/mvision-logo-white-32.png';
import { getCurrentLangKey } from './i18n';
import { StoreState } from './store/store';
import { appConfigSelectors } from './store/appConfig/appConfigSlice';
import { DeploymentMode } from './store/appConfig/appDeploymentInfoTypes';
import { configurationTargetSelectors } from './store/configurationTarget/configurationTargetSlice';

interface MastheadAndHeaderProps {
    handleOpenUserSettings: () => void,
    handleOpenHelpDialog: () => void,
    isUserSettingsOpen: boolean,
}

const Masthead = (props: MastheadAndHeaderProps) => {

    const { t } = useTranslation();

    return (
        <PageHeader className="mv-masthead" logo={(<Link to={routes.homePage.slug}><span className="masthead-logo" title={t('appTitle')}><img src={logo32w} alt="MVision logo"/></span></Link>)} logoComponent="div" showNavToggle headerTools={(<HeaderTools {...props} />)} />
    );
};

const HeaderTools = (props: MastheadAndHeaderProps) => {

    const targetList = useSelector(configurationTargetSelectors.selectConfigurationTargetList);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const currentLangKey = getCurrentLangKey();
    const isHelpDocumentAvailable = useSelector((state: StoreState) => appConfigSelectors.selectIsHelpDocumentAvailable(state, currentLangKey));


    // TODO: support UI case where user has only one app client and has no reason to use a dropdown selector
    // const showSingleClient = targetList && targetList.length === 1;
    const isTargetSelectorShown = targetList && deploymentInfo && deploymentInfo.deploymentMode !== DeploymentMode.Local;
    const hiddenTargetSelector = /*config.showDebugInfoInUi && */currentTarget && currentTarget.localMode
        ? (<div title={`${DEFAULT_APP_NAME} is using local configuration API.`}>Local mode</div>) : null;

    return (
        <PageHeaderTools>
            <PageHeaderToolsGroup>
                <PageHeaderToolsItem>
                    {isTargetSelectorShown ? (<ConfigurationTargetSelector targetList={targetList} />) : hiddenTargetSelector}
                </PageHeaderToolsItem>
                <PageHeaderToolsItem>
                    {isHelpDocumentAvailable && <Button onClick={props.handleOpenHelpDialog} variant="plain"><QuestionCircleIcon size="md" /></Button>}
                    <TiltButton forceTilt={props.isUserSettingsOpen} tiltOrigin="12px 12px" onClick={props.handleOpenUserSettings}><CogIcon size="md" /></TiltButton>
                </PageHeaderToolsItem>
            </PageHeaderToolsGroup>
        </PageHeaderTools>
    );

}

export default Masthead;

