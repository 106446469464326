/** Returns intersection of two arrays (an array of values that are found in both arrays).
 * Does NOT look for repeat values so only useful for distinct arrays (e.g. EntityAdapter IDs).
 * Adapted from: https://stackoverflow.com/a/37041756
 */
export function intersection<T>(a: T[], b: T[]): T[] {
    const setA = new Set(a);
    const setB = new Set(b);
    const intersection = new Set([...setA].filter(x => setB.has(x)));
    return Array.from(intersection);
}

/** Returns the input collection, or undefined if the input collection is empty. */
export function getCollectionOrUndefinedIfEmpty<T>(t: T[]): T[] | undefined {
    if (t.length) { return t; }
    return undefined;
}
